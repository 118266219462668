<app-form-card titleIcon="category" cardTitle="WATER.SEWAGE_OPTIONS.HEADING">
  <h2 class="full-width">
    {{ 'WATER.SEWAGE_OPTIONS.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let sewageOption of sewageOptionsList"
      class="selection-card"
      [icon]="sewageOption.icon"
      [label]="sewageOption.label"
      [selected]="selectedOption === sewageOption.type"
      (click)="selectType(sewageOption.type)">
    </app-selection-card>
    <mat-error *ngIf="!selectedOption && allTouched">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <ng-container *ngIf="selectedOption">
    <div class="space"></div>
    <form [formGroup]="sewageOptionsForm">
      <ng-container [ngSwitch]="selectedOption">
        <app-hint *ngSwitchCase="sewageOptions.NEW">
          <div>
            {{ 'WATER.SEWAGE_OPTIONS.NEW.INFO' | translate }}
            <ul class="list-container">
              <li>
                {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
              </li>
              <li>
                {{ 'SHARED.DOCUMENT_TYPE.FLOOR_PLAN' | translate }}
              </li>
            </ul>
          </div>
        </app-hint>

        <div *ngSwitchCase="sewageOptions.DETACH">
          <app-hint>
            <div>
              {{ 'WATER.SEWAGE_OPTIONS.DETACH.INFO' | translate }}
              <ul class="list-container">
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.PROOF_OF_OWNERSHIP' | translate }}
                </li>
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
          <div class="space"></div>

          <h2>
            {{
              ('WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION'
                | translate) + ' *'
            }}
          </h2>
          <mat-radio-group
            formControlName="permanentDeconstruction"
            class="input-container">
            <div class="deconstruction-type-container">
              <mat-radio-button color="primary" [value]="false"
                >{{
                  'WATER.SEWAGE_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
                    | translate
                }}
              </mat-radio-button>
              <app-info-tooltip
                tooltipText="WATER.SEWAGE_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_TOOLTIP">
              </app-info-tooltip>
            </div>

            <div class="deconstruction-type-container">
              <mat-radio-button color="primary" [value]="true">
                {{
                  'WATER.SEWAGE_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL'
                    | translate
                }}
              </mat-radio-button>
              <app-info-tooltip
                tooltipText="WATER.SEWAGE_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_TOOLTIP">
              </app-info-tooltip>
            </div>
            <mat-error
              appErrorMessages="permanentDeconstruction"
              [hideError]="!allTouched">
            </mat-error>
          </mat-radio-group>

          <div
            *ngIf="
              (sewageOptionsForm | formLookup: 'permanentDeconstruction') ===
              false
            "
            class="input-container">
            <mat-form-field appearance="outline" class="input-field">
              <mat-label>{{
                'WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_UNTIL' | translate
              }}</mat-label>
              <input
                matInput
                [min]="dateForTomorrow"
                [matDatepicker]="picker"
                formControlName="deconstructionUntil" />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="
                  sewageOptionsForm
                    | hasError: 'deconstructionUntil' : 'required'
                "
                >{{ errorMessageForInvalidDateFormat }}
              </mat-error>

              <mat-error
                *ngIf="
                  sewageOptionsForm
                    | hasError: 'deconstructionUntil' : 'matDatepickerMin'
                "
                >{{ 'VALIDATION.DATE_PICKER_MIN' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <h2 class="tooltip-heading-label">
            {{ 'WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER' | translate }}
            <app-info-tooltip
              tooltipText="WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER_TOOLTIP">
            </app-info-tooltip>
          </h2>

          <div class="input-container">
            <mat-form-field appearance="outline" class="input-field">
              <mat-label>
                {{ 'WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER' | translate }}
              </mat-label>
              <input
                matInput
                type="text"
                name="meterNumber"
                formControlName="meterNumber" />
              <mat-error appErrorMessages="meterNumber"></mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: sewageOptionsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
