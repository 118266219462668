import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-summary-section-field',
  templateUrl: './summary-section-field.component.html',
  styleUrls: ['./summary-section-field.component.scss'],
})
export class SummarySectionFieldComponent {
  @Input() public label?: string;
  @Input() public innerHTMLLabel?: string;
  @Input() public value?: string | number;
}
