import { inject, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'filesize',
  standalone: true,
})
// based on: https://stackoverflow.com/a/18650828
export class FileSizePipe implements PipeTransform {
  #decimalPipe = inject(DecimalPipe);
  transform(fileSizeInB: number, locale: string): string {
    if (fileSizeInB === 0) {
      return '0 Bytes';
    }

    const k = 1000;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const formats = ['1.0-0', '1.0-0', '1.0-1', '1.0-1', '1.0-1'];

    const i = Math.floor(Math.log(fileSizeInB) / Math.log(k));
    const unit = sizes.length > i ? sizes[i] : '?';
    const numberFormat = formats.length > i ? formats[i] : '1.0-1';
    const decimals = 4;
    const convertedSize = parseFloat(
      (fileSizeInB / Math.pow(k, i)).toFixed(decimals)
    );

    const formattedSize = this.#decimalPipe.transform(
      convertedSize,
      numberFormat,
      locale
    );
    return `${formattedSize} ${unit}`;
  }
}
