import { Component, Input } from '@angular/core';
import { ROUTES } from '@app/models/routes.enum';
import { MainPowerSupplyPowerIncrease } from '@app/models/commissioning-mapped-data.interface';

@Component({
  selector: 'app-power-increase-summary',
  templateUrl: './power-increase-summary.component.html',
})
export class PowerIncreaseSummaryComponent {
  @Input({ required: true })
  public powerIncreaseDetails!: MainPowerSupplyPowerIncrease;
  public readonly powerIncreaseDetailsRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS;
}
