import { forkJoin, Observable } from 'rxjs';
import { WhiteLabelService } from '../services/white-label.service';
import { IconInitializerService } from '@app/services/icon-initializer.service';

export const initializeApp = (
  iconInitializerService: IconInitializerService,
  whiteLabelService: WhiteLabelService
) => {
  return (): Observable<[void, void]> =>
    forkJoin([
      iconInitializerService.configured$,
      whiteLabelService.configured$,
    ]);
};
