import { Pipe, PipeTransform } from '@angular/core';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { ResponsibilityCheckService } from '@app/services/responsibility-check.service';

@Pipe({
  name: 'nextButtonDisabledAddress',
})
export class NextButtonDisabledAddressPipe implements PipeTransform {
  constructor(private responsibilityCheckService: ResponsibilityCheckService) {}

  transform(
    allTouched: boolean,
    selectedModule: FLOW_MODULE | null,
    captchaTokenValid: boolean,
    addressFormValid: boolean,
    loading: boolean
  ): boolean {
    const captchaPassed =
      !this.responsibilityCheckService.hasResponsibilityCheck(selectedModule) ||
      captchaTokenValid;
    const formValid = addressFormValid && captchaPassed;
    return (allTouched && !formValid) || loading;
  }
}
