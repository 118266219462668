import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { filter, map, Subject, take } from 'rxjs';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';

@Component({
  selector: 'app-commissioning-electricity-simple-measurement-details',
  templateUrl:
    './commissioning-electricity-simple-measurement-details.component.html',
})
export class CommissioningElectricitySimpleMeasurementDetailsComponent
  implements OnInit, OnDestroy
{
  public form!: FormGroup;
  public meterNumbers!: FormArray;
  public allTouched = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.initFormData();
  }

  private createForm(): void {
    this.meterNumbers = this.formBuilder.array([], Validators.required);
    this.addMeterNumber();
    this.form = this.formBuilder.group({
      meterNumbers: this.meterNumbers,
    });
    this.form.updateValueAndValidity();
  }

  public getMeterNumbersFormControl(index: number): FormControl {
    return this.meterNumbers.controls[index] as FormControl;
  }

  public addMeterNumber(value: string | null = null) {
    const formControl = this.formBuilder.control(value, {
      updateOn: 'blur',
      validators: [
        CustomValidators.trimValidator,
        CustomValidators.shortText,
        Validators.required,
      ],
    });
    this.meterNumbers.push(formControl, { emitEvent: true });
    this.form?.updateValueAndValidity();
  }

  public deleteMeterNumber(index: number) {
    this.meterNumbers.removeAt(index);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public trackByMeterNumbers(index: number, _: unknown) {
    return index;
  }

  private initFormData(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(
          ({ formData }) =>
            formData.commissioningElectricitySimpleMeasurementDetails
        ),
        filter(Boolean),
        take(1)
      )
      .subscribe(details => {
        if (
          details.meterNumbers &&
          details.meterNumbers.length > 0 &&
          this.meterNumbers.length > 0
        ) {
          this.deleteMeterNumber(0);
        }

        details.meterNumbers?.forEach(meterNumber => {
          if (meterNumber) {
            this.addMeterNumber(meterNumber);
          }
        });
      });
  }

  private updateState(): void {
    const formStepData = {
      commissioningElectricitySimpleMeasurementDetails: {
        meterNumbers: (this.meterNumbers.value as string[]).filter(Boolean),
      },
    };
    this.appStateService.updateFormData(formStepData);
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.form.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
