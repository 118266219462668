import { Component, Input } from '@angular/core';
import { ROUTES } from '@app/models/routes.enum';
import { MeasurementDetails } from '@app/models/commissioning-mapped-data.interface';

@Component({
  selector: 'app-complex-measurement-details-summary',
  templateUrl: './complex-measurement-details-summary.component.html',
})
export class ComplexMeasurementDetailsSummaryComponent {
  @Input({ required: true })
  public measurementDetails!: MeasurementDetails[];
  public readonly measurementDetailsRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS;
}
