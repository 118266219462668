import { Component, Input, OnInit } from '@angular/core';
import {
  CommissioningElectricitySimpleMeasurementDetails,
  CommissioningElectricitySystems,
} from '@app/models/form-data.interface';
import { ROUTES } from '@app/models/routes.enum';
import {
  ElectricitySystem,
  MeasurementDetails,
} from '@app/models/commissioning-mapped-data.interface';
import { CommissioningFormMapperService } from '@app/services/commissioning-form-mapper.service';

@Component({
  selector: 'app-customer-facility-summary',
  templateUrl: './customer-facility-summary.component.html',
})
export class CustomerFacilitySummaryComponent implements OnInit {
  @Input({ required: true })
  public electricitySystems?: CommissioningElectricitySystems;
  @Input({ required: true })
  public meterChanges!: boolean;
  @Input({ required: true })
  public simpleMeasurementDetails?: CommissioningElectricitySimpleMeasurementDetails;
  @Input({ required: true })
  public complexMeasurementDetails?: MeasurementDetails[];
  public readonly electricitySystemsRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_SYSTEMS;
  public selectedSystems: ElectricitySystem[] = [];

  constructor(
    private commissioningFormMapperService: CommissioningFormMapperService
  ) {}

  ngOnInit() {
    if (!this.electricitySystems) {
      return;
    }
    this.selectedSystems =
      this.commissioningFormMapperService.mapElectricitySystems(
        this.electricitySystems
      )!;
  }
}
