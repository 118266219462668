import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { DialogComponent } from '@app/modules/shared/dialog/dialog.component';
import { FormCardComponent } from '@app/modules/shared/form-card/form-card.component';
import { ErrorMessagesDirective } from '@app/shared/directives/error-messages.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormNavigationComponent } from './form-navigation/form-navigation.component';
import { HintComponent } from './hint/hint.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MultiSelectionCardComponent } from './multi-selection-card/multi-selection-card.component';
import { SelectionCardComponent } from './selection-card/selection-card.component';
import { SelectionGroupComponent } from './selection-group/selection-group.component';
import { SummarySectionComponent } from './summary-section/summary-section.component';
import {} from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { SummarySectionFieldComponent } from './summary-section-field/summary-section-field.component';
import { SummaryBuildingTypeComponent } from './summary-building-type/summary-building-type.component';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import { HasErrorPipe } from '@app/shared/pipes/has-error.pipe';
import { IncludesPipe } from '@app/shared/pipes/includes.pipe';
import { FormLookupPipe } from '@app/shared/pipes/form-lookup.pipe';
import { IntroModuleCardComponent } from './intro-module-card/intro-module-card.component';
import { IsModuleOptionEnabledPipe } from '@app/shared/pipes/is-module-option-enabled.pipe';

const components = [
  DialogComponent,
  ErrorMessagesDirective,
  FormCardComponent,
  FormNavigationComponent,
  HintComponent,
  SelectionCardComponent,
  SelectionGroupComponent,
  MultiSelectionCardComponent,
  LoadingSpinnerComponent,
  SummarySectionComponent,
  InfoTooltipComponent,
  SummarySectionFieldComponent,
  SummaryBuildingTypeComponent,
  IntroModuleCardComponent,
];

const modules = [
  CommonModule,
  FormsModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatTooltipModule,
  ReactiveFormsModule,
  RouterModule,
  MatDatepickerModule,
  MatNativeDateModule,
];

const pipes = [
  NextButtonDisabledPipe,
  HasErrorPipe,
  IncludesPipe,
  FormLookupPipe,
  IsModuleOptionEnabledPipe,
];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
    ...pipes,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  exports: [...components, ...modules, ...pipes],
})
export class SharedModule {}
