<div class="summary-section-field-container">
  <p
    *ngIf="innerHTMLLabel; else standardLabel"
    class="label"
    [innerHTML]="innerHTMLLabel | translate"></p>

  <ng-template #standardLabel>
    <p class="label">
      {{ label ? (label | translate) : '-' }}
    </p>
  </ng-template>

  <p class="bold">{{ value ?? '-' }}</p>
</div>
