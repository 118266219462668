import { Pipe, PipeTransform } from '@angular/core';
import { DateHelperService } from '@app/services/date-helper.service';

@Pipe({
  name: 'nextDayDate',
  standalone: true,
})
export class NextDayDatePipe implements PipeTransform {
  constructor(private dateHelperService: DateHelperService) {}

  transform(selectedDate: Date | null): Date | null {
    if (selectedDate && this.isDateValid(selectedDate)) {
      return this.dateHelperService.getFutureDate(1, selectedDate);
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isDateValid(date: any): boolean {
    return !isNaN(date) && date instanceof Date;
  }
}
