<app-form-card
  titleIcon="electric_bolt"
  cardTitle="CONSTRUCTION_ELECTRICITY_INFORMATION.HEADING"
  data-testid="grid-connection-construction-heading">
  <form [formGroup]="constructionGridConnectionForm">
    <h2 appRequiredSuffix>
      {{ 'CONSTRUCTION_ELECTRICITY_INFORMATION.SYSTEM_POWER' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'CONSTRUCTION_ELECTRICITY_INFORMATION.SYSTEM_POWER_LABEL' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="systemPower"
          formControlName="systemPower"
          min="0"
          data-testid="system-power" />
        <mat-error appErrorMessages="systemPower"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      <span appRequiredSuffix>
        {{
          'CONSTRUCTION_ELECTRICITY_INFORMATION.DEVICES_REQUIRE_APPROVAL_QUESTION'
            | translate
        }}</span
      >
      <app-info-tooltip
        tooltipText="CONSTRUCTION_ELECTRICITY_INFORMATION.DEVICES_REQUIRE_APPROVAL_QUESTION_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <mat-radio-group
      color="primary"
      formControlName="devicesRequireApproval"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="devices-require-approval">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button
          [value]="false"
          data-testid="no-devices-require-approval">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        appErrorMessages="devicesRequireApproval"
        [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <ng-container
      *ngIf="
        constructionGridConnectionForm | formLookup: 'devicesRequireApproval'
      ">
      <h2 appRequiredSuffix>
        {{
          'CONSTRUCTION_ELECTRICITY_INFORMATION.REQUIRED_DEVICES' | translate
        }}
      </h2>

      <div class="input-container">
        <ng-container
          formArrayName="requiredDevices"
          *ngFor="
            let requiredDevice of requiredDevices.controls;
            let i = index
          ">
          <div
            class="required-devices-container input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>{{
                'CONSTRUCTION_ELECTRICITY_INFORMATION.REQUIRED_DEVICES_LABEL'
                  | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                [name]="'requiredDevice_' + i"
                [formControlName]="i"
                [attr.data-testid]="'required-device-' + i" />
              <mat-error [appErrorMessages]="'' + i"></mat-error>
            </mat-form-field>

            <button
              type="button"
              mat-icon-button
              class="input-field-postfix"
              [disabled]="i === 0"
              (click)="removeDevice(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
      <button
        type="button"
        mat-stroked-button
        (click)="addMoreDevice()"
        [disabled]="requiredDevices.length | exceedsLimit">
        <mat-icon>add</mat-icon>
        {{
          'CONSTRUCTION_ELECTRICITY_INFORMATION.ADD_MORE_DEVICE_BUTTON'
            | translate
        }}
      </button>

      <div class="space"></div>
    </ng-container>

    <div class="date-row-container">
      <div class="full-width-flex-element">
        <h2 appRequiredSuffix>
          {{
            'CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_INSTALLATION_DATE'
              | translate
          }}
        </h2>

        <mat-form-field
          class="full-width"
          appearance="outline"
          hideRequiredMarker="true">
          <mat-label>{{
            'CONSTRUCTION_ELECTRICITY_INFORMATION.DATE_LABEL' | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="desiredInstallationDate"
            formControlName="desiredInstallationDate"
            data-testid="desired-installation-date" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="desiredInstallationDate"></mat-datepicker-toggle>
          <mat-datepicker #desiredInstallationDate></mat-datepicker>
          <mat-error
            *ngIf="
              constructionGridConnectionForm
                | hasError: 'desiredInstallationDate' : 'required'
            "
            >{{ errorMessageForInvalidDateFormat }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="full-width-flex-element">
        <h2>
          {{
            'CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_DECONSTRUCTION_DATE'
              | translate
          }}
        </h2>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            'CONSTRUCTION_ELECTRICITY_INFORMATION.DATE_LABEL' | translate
          }}</mat-label>
          <input
            matInput
            [min]="
              constructionGridConnectionForm
                | formLookup: 'desiredInstallationDate'
            "
            [matDatepicker]="desiredDeconstructionDate"
            formControlName="desiredDeconstructionDate" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="desiredDeconstructionDate"></mat-datepicker-toggle>
          <mat-datepicker #desiredDeconstructionDate></mat-datepicker>
          <mat-error
            *ngIf="
              constructionGridConnectionForm
                | hasError: 'desiredDeconstructionDate' : 'matDatepickerParse'
            "
            >{{ errorMessageForInvalidDateFormat }}
          </mat-error>

          <mat-error
            *ngIf="
              constructionGridConnectionForm
                | hasError: 'desiredDeconstructionDate' : 'matDatepickerMin'
            "
            >{{
              'CONSTRUCTION_ELECTRICITY_INFORMATION.DESIRED_DECONSTRUCTION_DATE_MIN_DATE'
                | translate
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched | nextButtonDisabled: constructionGridConnectionForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
