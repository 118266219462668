<div class="module-card-container">
  <div *ngFor="let moduleDetails of moduleDetailsList" class="module-card">
    <div class="card-header">
      <mat-icon
        *ngIf="moduleDetails.icon || moduleDetails.customIcon"
        [fontIcon]="moduleDetails.icon || ''"
        [svgIcon]="moduleDetails.customIcon || ''"
        class="material-icons-outlined">
      </mat-icon>
      <h2>
        {{
          'INTRO.' + flowType + '.' + moduleDetails.moduleName + '.TITLE'
            | translate
        }}
      </h2>
    </div>
    <ul>
      <ng-container *ngFor="let flow of moduleDetails.flows">
        <li
          *ngIf="
            $any(flow) | isModuleOptionEnabled: moduleDetails.moduleName | async
          ">
          {{
            'INTRO.' + flowType + '.' + moduleDetails.moduleName + '.' + flow
              | translate
          }}
        </li>
      </ng-container>
    </ul>

    <div class="navigation">
      <ng-content select="[slot=navigation]"></ng-content>
    </div>
  </div>
</div>
