import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { filter, map, Subject, take } from 'rxjs';
import { FORM_TYPE } from '@app/models/registration-form';
import { BalconyPVForm } from '@app/models/form-data.interface';

@Component({
  selector: 'app-balcony-pv',
  templateUrl: './balcony-pv.component.html',
  styleUrl: 'balcony-pv.component.scss',
})
export class BalconyPvComponent implements OnInit, OnDestroy {
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public form!: FormGroup;
  public allTouched = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.initFormData();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      system: [
        null,
        [
          CustomValidators.trimValidator,
          CustomValidators.shortText,
          Validators.required,
        ],
      ],
      systemManufacturer: [
        null,
        [CustomValidators.trimValidator, CustomValidators.shortText],
      ],
      systemType: [
        null,
        [CustomValidators.trimValidator, CustomValidators.shortText],
      ],
      systemPower: [
        null,
        {
          updateOn: 'change',
          validators: [Validators.required, Validators.min(0)],
        },
      ],
      inverterPower: [
        null,
        {
          updateOn: 'change',
          validators: [Validators.required, Validators.min(0)],
        },
      ],
      freeFeedIn: [
        null,
        {
          updateOn: 'change',
          validators: [Validators.required],
        },
      ],
      meterNumber: [
        null,
        [CustomValidators.trimValidator, CustomValidators.shortText],
      ],
      notes: [
        null,
        [CustomValidators.trimValidator, CustomValidators.longText],
      ],
    });
  }

  private initFormData(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.balconyPV),
        filter(Boolean),
        take(1)
      )
      .subscribe(balconyPV => this.form.patchValue(balconyPV));
  }

  private updateState(): void {
    const balconyPV = this.form.value;
    this.appStateService.updateFormData({
      balconyPV: {
        ...balconyPV,
        type: FORM_TYPE.BALCONY_PV,
        registrationRequired: this.isRegistrationRequired(balconyPV),
      },
    });
  }

  private isRegistrationRequired(balconyPV: BalconyPVForm): boolean {
    return !(
      (balconyPV.freeFeedIn || false) &&
      (balconyPV.systemPower || 0) <= 2000 &&
      (balconyPV.inverterPower || 0) <= 800
    );
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.form.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
