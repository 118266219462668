import { Injectable } from '@angular/core';
import {
  AppState,
  FLOW,
  FormDataObject,
  OtherData,
} from '@app/models/form-data.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { PublicCustomerConfigurationDto } from '@app/services/white-label.service';
import { DOCUMENT_TYPE } from '@app/models/registration-form';

export type PartialFormData = Partial<FormDataObject>;
export type PartialOtherData = Partial<OtherData>;

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private initialFormData: FormDataObject = {};
  private initialOtherData: OtherData = {};
  private initialState: AppState = {
    formData: this.initialFormData,
    otherData: this.initialOtherData,
  };

  private state$ = new BehaviorSubject<AppState>(this.initialState);
  private readonly cacheableDocumentTypes = [
    DOCUMENT_TYPE.SITE_PLAN,
    DOCUMENT_TYPE.FLOOR_PLAN,
  ];

  // constructor() {
  //   this.initFromStorage();
  // }

  // private initFromStorage(): void {
  //   const formDataJson = localStorage.getItem('formData');
  //   const formData = formDataJson && JSON.parse(formDataJson);
  //
  //   if (formData) {
  //     this.updateFormData(formData as PartialFormData);
  //   }
  // }

  public getInitialFormData(): FormDataObject {
    return this.initialFormData;
  }

  public observeState(): Observable<AppState> {
    return this.state$.asObservable();
  }

  public updateFormData(formStepData: PartialFormData): void {
    const formData = { ...this.state$.value.formData, ...formStepData };
    this.state$.next({ formData, otherData: this.state$.value.otherData });
  }

  public updateOtherData(newOtherData: PartialOtherData): void {
    const otherData = { ...this.state$.value.otherData, ...newOtherData };

    this.state$.next({ formData: this.state$.value.formData, otherData });
  }

  public isModified(): boolean {
    return (
      JSON.stringify(this.state$.value.formData) !==
      JSON.stringify(this.initialFormData)
    );
  }

  public clearState(clearModule = true, clearFlow = true): void {
    const formData: FormDataObject = {
      address: this.state$.value.formData.address,
      contact: this.state$.value.formData.contact,
      commissioningElectricityContact:
        this.state$.value.formData.commissioningElectricityContact,
      selectedModule: clearModule
        ? undefined
        : this.state$.value.formData.selectedModule,
      selectedFlow: clearFlow
        ? undefined
        : this.state$.value.formData.selectedFlow,
      documentDetails: this.state$.value.formData.documentDetails?.documents
        ?.length
        ? {
            documents:
              this.state$.value.formData.documentDetails.documents.filter(
                document => this.cacheableDocumentTypes.includes(document.type)
              ),
          }
        : undefined,
    };

    this.state$.next({ formData, otherData: this.initialOtherData });
  }

  public areMultipleFlowsEnabled(
    customerConfigurationDto?: PublicCustomerConfigurationDto
  ): boolean {
    return (
      (customerConfigurationDto?.moduleSettings?.length ?? 0) > 0 &&
      (customerConfigurationDto?.commissioningModules?.length ?? 0) > 0
    );
  }

  public getAvailableModuleCount(
    flow: FLOW,
    customerConfigurationDto?: PublicCustomerConfigurationDto
  ): number {
    switch (flow) {
      case FLOW.COMMISSIONING:
        return customerConfigurationDto?.commissioningModules?.length ?? 0;
      case FLOW.REGISTRATION:
        return customerConfigurationDto?.moduleSettings.length ?? 0;
    }
  }

  public isFlowAvailable(
    flow: FLOW,
    customerConfigurationDto?: PublicCustomerConfigurationDto
  ): boolean {
    switch (flow) {
      case FLOW.COMMISSIONING:
        return (
          (customerConfigurationDto?.commissioningModules?.length ?? 0) > 0
        );
      case FLOW.REGISTRATION:
        return (customerConfigurationDto?.moduleSettings.length ?? 0) > 0;
      default:
        return false;
    }
  }

  public initialiseModule(
    customerConfigurationDto: PublicCustomerConfigurationDto
  ): void {
    // if only one module is available then set it as the selected module, as module selection page will be skipped
    if (!this.areMultipleFlowsEnabled(customerConfigurationDto)) {
      if (customerConfigurationDto.moduleSettings?.length === 1) {
        this.updateFormData({
          selectedModule: customerConfigurationDto.moduleSettings[0].module,
        });
      } else if (customerConfigurationDto.commissioningModules?.length === 1) {
        this.updateFormData({
          selectedModule: customerConfigurationDto.commissioningModules[0],
        });
      }
    }
  }

  public initialiseFlow(
    customerConfigurationDto?: PublicCustomerConfigurationDto
  ): FLOW | undefined {
    // if only one flow is available then set it as the selected flow, as flow selection page will be skipped
    if (!this.areMultipleFlowsEnabled(customerConfigurationDto)) {
      const flow = Object.values(FLOW).find(
        flow => this.getAvailableModuleCount(flow, customerConfigurationDto) > 0
      );
      if (flow) {
        this.updateFormData({ selectedFlow: flow });
        return flow;
      }
    }
    return undefined;
  }

  public initialiseModuleByFlow(
    customerConfigurationDto?: PublicCustomerConfigurationDto,
    flow?: FLOW
  ): void {
    // if only one module is available then set it as the selected module, as module selection page will be skipped
    let module;
    switch (flow) {
      case FLOW.REGISTRATION:
        module = customerConfigurationDto?.moduleSettings[0].module;
        break;
      case FLOW.COMMISSIONING:
        module = customerConfigurationDto?.commissioningModules[0];
        break;
    }
    this.updateFormData({ selectedModule: module });
  }
}
