<app-summary-section
  heading="COMMISSIONING_ELECTRICITY.SYSTEM.HEADING"
  [stepRoute]="electricitySystemsRoute">
  <app-summary-section-field
    label="COMMISSIONING_ELECTRICITY.SYSTEM.METER_CHANGES"
    [value]="(meterChanges ? 'COMMON.YES' : 'COMMON.NO') | translate">
  </app-summary-section-field>
  <ng-container *ngIf="electricitySystems">
    <ng-container *ngFor="let system of selectedSystems">
      <p class="section-heading">
        {{
          'COMMISSIONING_ELECTRICITY.SYSTEM.TYPES.' + system.type | translate
        }}
      </p>
      <div class="section-fields-container">
        <app-summary-section-field
          label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_POWER"
          [value]="system.systemPower"
          *ngIf="system.systemPower">
        </app-summary-section-field>
        <app-summary-section-field
          label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_COUNT"
          [value]="system.systemCount"
          *ngIf="system.systemCount">
        </app-summary-section-field>
        <app-summary-section-field
          label="COMMISSIONING_ELECTRICITY.SYSTEM.SYSTEM_TYPE"
          [value]="system.systemType"
          *ngIf="system.systemType">
        </app-summary-section-field>
        <app-summary-section-field
          label="COMMISSIONING_ELECTRICITY.SYSTEM.NOTES"
          [value]="system.notes"
          *ngIf="system.notes">
        </app-summary-section-field>
      </div>
    </ng-container>
  </ng-container>
</app-summary-section>

<ng-container *ngIf="meterChanges; else simpleDetails">
  <app-complex-measurement-details-summary
    [measurementDetails]="complexMeasurementDetails"
    *ngIf="complexMeasurementDetails"></app-complex-measurement-details-summary>
</ng-container>
<ng-template #simpleDetails>
  <app-simple-measurement-details-summary
    [simpleMeasurementDetails]="simpleMeasurementDetails"
    *ngIf="simpleMeasurementDetails"></app-simple-measurement-details-summary>
</ng-template>
