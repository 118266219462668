<app-summary-section-field
  label="CONTACT.FIRST_NAME"
  [value]="contactDetails.firstName">
</app-summary-section-field>

<app-summary-section-field
  label="CONTACT.LAST_NAME"
  [value]="contactDetails.lastName">
</app-summary-section-field>

<app-summary-section-field label="CONTACT.EMAIL" [value]="contactDetails.email">
</app-summary-section-field>

<app-summary-section-field label="CONTACT.PHONE" [value]="contactDetails.phone">
</app-summary-section-field>
