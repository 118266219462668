<app-form-card
  titleIcon="electric_bolt"
  cardTitle="CHANGE_PV_DETAILS.HEADING"
  description="CHANGE_PV_DETAILS.DESCRIPTION"
  dataTestId="pv-change-information-heading">
  <div class="space"></div>

  <form [formGroup]="changePVDetailsForm">
    <h2>
      {{ 'CHANGE_PV_DETAILS.OPERATING_MODE_TITLE' | translate }}
    </h2>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label
            appRequiredSuffix
            id="current-operating-mode-radio-group-label"
            >{{
              'CHANGE_PV_DETAILS.CURRENT_OPERATING_MODE_TITLE' | translate
            }}</label
          >
          <app-info-tooltip
            tooltipText="CHANGE_PV_DETAILS.CURRENT_OPERATING_MODE_TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="currentOperatingMode"
          aria-labelledby="current-operating-mode-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of operatingModeOptions"
            [value]="option"
            [attr.data-testid]="'current-operating-mode-' + option">
            {{ 'SHARED.PV.OPERATING_MODE.' + option | translate }}
          </mat-radio-button>
          <mat-error appErrorMessages="currentOperatingMode" *ngIf="allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label
            appRequiredSuffix
            id="planned-operating-mode-radio-group-label"
            >{{
              'CHANGE_PV_DETAILS.PLANNED_OPERATING_MODE_TITLE' | translate
            }}</label
          >
          <app-info-tooltip
            tooltipText="CHANGE_PV_DETAILS.PLANNED_OPERATING_MODE_TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="plannedOperatingMode"
          aria-labelledby="planned-operating-mode-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of operatingModeOptions"
            [value]="option"
            [attr.data-testid]="'planned-operating-mode-' + option">
            {{ 'SHARED.PV.OPERATING_MODE.' + option | translate }}
          </mat-radio-button>
          <mat-error appErrorMessages="plannedOperatingMode" *ngIf="allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <h2>
      {{ 'CHANGE_PV_DETAILS.FORM_OF_FEED_IN_SALE_TITLE' | translate }}
    </h2>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label
            appRequiredSuffix
            id="current-form-of-feed-in-sale-radio-group-label"
            >{{
              'CHANGE_PV_DETAILS.CURRENT_OPERATING_MODE_TITLE' | translate
            }}</label
          >
          <app-info-tooltip
            tooltipText="CHANGE_PV_DETAILS.CURRENT_OPERATING_MODE_TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="currentFormOfFeedInSale"
          aria-labelledby="current-form-of-feed-in-sale-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of formOfFeedInSaleOptions"
            [value]="option"
            [attr.data-testid]="'current-form-of-feed-in-sale-' + option">
            {{ 'SHARED.PV.FORM_OF_FEED_IN_SALE.' + option | translate }}
          </mat-radio-button>
          <mat-error
            appErrorMessages="currentFormOfFeedInSale"
            *ngIf="allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label
            appRequiredSuffix
            id="planned-form-of-feed-in-sale-radio-group-label"
            >{{
              'CHANGE_PV_DETAILS.PLANNED_OPERATING_MODE_TITLE' | translate
            }}</label
          >
          <app-info-tooltip
            tooltipText="CHANGE_PV_DETAILS.PLANNED_OPERATING_MODE_TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="plannedFormOfFeedInSale"
          aria-labelledby="planned-form-of-feed-in-sale-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of formOfFeedInSaleOptions"
            [value]="option"
            [attr.data-testid]="'planned-form-of-feed-in-sale-' + option">
            {{ 'SHARED.PV.FORM_OF_FEED_IN_SALE.' + option | translate }}
          </mat-radio-button>
          <mat-error
            appErrorMessages="plannedFormOfFeedInSale"
            *ngIf="allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <h2>
      {{ 'CHANGE_PV_DETAILS.OTHER_CHANGE' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <textarea
          #notes
          matInput
          [placeholder]="
            'CHANGE_PV_DETAILS.OTHER_CHANGE_PLACEHOLDER' | translate
          "
          cols="50"
          rows="2"
          [maxlength]="maxLength"
          formControlName="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: changePVDetailsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
