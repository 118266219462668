<app-form-card
  titleIcon="category"
  cardTitle="FLOW_SELECTION.HEADING"
  dataTestId="flow-selection-page">
  <h2>{{ 'FLOW_SELECTION.SUBHEADING' | translate }}</h2>
  <form [formGroup]="flowSelectionForm">
    <app-selection-group>
      <ng-container *ngFor="let flowDetails of flowDetailsList">
        <app-selection-card
          [svgIcon]="flowDetails.svgIcon"
          [icon]="flowDetails.icon"
          [label]="flowDetails.label"
          [selected]="selectedFlow === flowDetails.name"
          (click)="selectFlow(flowDetails.name)"
          class="selection-card"
          *ngIf="flowDetails.enabled"
          [attr.data-testid]="flowDetails.name">
        </app-selection-card>
      </ng-container>
      <mat-error
        *ngIf="allTouched && flowSelectionForm.get('selectedFlow')?.invalid"
        >{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}
      </mat-error>
    </app-selection-group>

    <ng-container *ngIf="selectedFlow">
      <div class="space"></div>
      <ng-container [ngSwitch]="selectedFlow">
        <app-hint *ngSwitchCase="flow.REGISTRATION">
          <span>{{ 'FLOW_SELECTION.REGISTRATION_INFO' | translate }}</span>
        </app-hint>
        <ng-container *ngSwitchCase="flow.COMMISSIONING">
          <app-hint [warning]="true">
            <span>{{
              'FLOW_SELECTION.COMMISSIONING_WARNING' | translate
            }}</span>
          </app-hint>
          <div class="space"></div>
          <app-hint>
            <span>{{ 'FLOW_SELECTION.COMMISSIONING_INFO' | translate }}</span>
          </app-hint>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: flowSelectionForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
