<app-form-card
  titleIcon="3p"
  cardTitle="CONTACT.CUSTOMER_DETAILS"
  description="CONTACT.DESCRIPTION"
  dataTestId="contact-heading">
  <form [formGroup]="form">
    <div class="space"></div>
    <h2>
      <label appRequiredSuffix id="connection-owner-radio-group-label">
        {{ 'CONTACT.REQUESTER' | translate }}
      </label>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="onBehalf"
      aria-labelledby="connection-owner-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="false" data-testid="not-on-behalf">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="true" data-testid="on-behalf">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        appErrorMessages="onBehalf"
        [hideError]="!allTouched"></mat-error>
    </mat-radio-group>

    <h2>{{ 'CONTACT.CONTACT_DETAILS' | translate }}</h2>
    <div class="input-container">
      <ng-container *ngIf="form | formLookup: 'onBehalf'">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.YOUR_FUNCTION' | translate }}</mat-label>
          <mat-select formControlName="onBehalfType">
            <mat-option *ngFor="let type of onBehalfTypes" [value]="type">
              {{ 'CONTACT.ON_BEHALF_TYPE.' + type | translate }}
            </mat-option>
          </mat-select>
          <mat-error appErrorMessages="onBehalfType"></mat-error>
        </mat-form-field>

        <div class="power-of-attorney-declaration">
          <mat-checkbox
            color="primary"
            formControlName="powerOfAttorney"
            class="input-field power-of-attorney-checkbox">
            <mat-label appRequiredSuffix>
              {{ 'CONTACT.POWER_OF_ATTORNEY' | translate }}
            </mat-label>
          </mat-checkbox>
          <mat-error
            appErrorMessages="powerOfAttorney"
            [hideError]="form.get('powerOfAttorney')!.untouched"></mat-error>
        </div>

        <app-document-upload-section
          *ngIf="form | formLookup: 'powerOfAttorney'"
          [allTouched]="allTouched"
          [documentDetails]="powerOfAttorneyDocument"
          documentControlName="powerOfAttorneyDocument"
          [documentControl]="powerOfAttorneyDocumentControl"
          documentName="CONTACT.POWER_OF_ATTORNEY_DOCUMENT"
          documentDescription="CONTACT.POWER_OF_ATTORNEY_DOCUMENT_DESCRIPTION"
          [multipleDocumentUpload]="false"
          class="power-of-attorney-document">
        </app-document-upload-section>
      </ng-container>

      <ng-container [formGroup]="contactForm">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.FIRST_NAME' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="firstName"
            formControlName="firstName"
            data-testid="first-name" />
          <mat-error appErrorMessages="firstName"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.LAST_NAME' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="lastName"
            formControlName="lastName"
            data-testid="last-name" />
          <mat-error appErrorMessages="lastName"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.EMAIL' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="email"
            formControlName="email"
            data-testid="email" />
          <mat-error appErrorMessages="email"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.PHONE' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="phone"
            formControlName="phone"
            data-testid="phone" />
          <mat-error appErrorMessages="phone"></mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <div class="space"></div>
    <h2>
      <label appRequiredSuffix id="connection-address-radio-group-label">
        {{ 'CONTACT.CONNECTION_ADDRESS_DESCRIPTION' | translate }}
      </label>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="differentAddress"
      aria-labelledby="connection-address-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="false" data-testid="no-different-address">{{
          'COMMON.YES' | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="true" data-testid="different-address">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        appErrorMessages="differentAddress"
        [hideError]="!allTouched"></mat-error>
    </mat-radio-group>

    <ng-container
      [formGroup]="differentContactForm"
      *ngIf="form | formLookup: 'onBehalf'">
      <h2>
        {{ 'CONTACT.REQUESTER_CONTACT' | translate }}
      </h2>
      <div class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.FIRST_NAME' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="firstName"
            formControlName="firstName" />
          <mat-error appErrorMessages="firstName"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.LAST_NAME' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="lastName"
            formControlName="lastName" />
          <mat-error appErrorMessages="lastName"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.EMAIL' | translate }}</mat-label>
          <input matInput type="text" name="email" formControlName="email" />
          <mat-error appErrorMessages="email"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'CONTACT.PHONE' | translate }}</mat-label>
          <input matInput type="text" name="phone" formControlName="phone" />
          <mat-error appErrorMessages="phone"></mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container
      [formGroup]="differentAddressForm"
      *ngIf="form | formLookup: 'differentAddress'">
      <h2>
        {{ 'CONTACT.REQUESTER_ADDRESS' | translate }}
      </h2>
      <div class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'ADDRESS.FORM.ZIP_CODE' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="zipCode"
            formControlName="zipCode" />
          <mat-error appErrorMessages="zipCode"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'ADDRESS.FORM.CITY' | translate }}</mat-label>
          <input matInput type="text" name="city" formControlName="city" />
          <mat-error appErrorMessages="city"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'ADDRESS.FORM.STREET' | translate }}</mat-label>
          <input matInput type="text" name="street" formControlName="street" />
          <mat-error appErrorMessages="street"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{ 'ADDRESS.FORM.STREET_NUMBER' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="streetNumber"
            formControlName="streetNumber" />
          <mat-error appErrorMessages="streetNumber"></mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
