<app-form-card
  titleIcon="category"
  cardTitle="PRODUCTS.HEADING"
  dataTestId="product-selection-heading">
  <form>
    <h2 class="full-width">{{ 'PRODUCTS.DESCRIPTION' | translate }}</h2>
    <app-selection-group>
      <ng-container *ngFor="let product of productDetailsList">
        <app-selection-card
          *ngIf="
            product.type | isModuleOptionEnabled: electricityModule | async
          "
          [icon]="product.icon"
          [svgIcon]="product.svgIcon"
          [label]="'PRODUCTS.' + product.type"
          [selected]="selectedProduct === product.type"
          (click)="selectProduct(product.type)"
          [attr.data-testid]="product.type">
        </app-selection-card>
      </ng-container>

      <mat-error *ngIf="allTouched && productTypeControl.invalid">{{
        'VALIDATION.REQUIRED_SELECTION' | translate
      }}</mat-error>
    </app-selection-group>

    <ng-container *ngIf="selectedProduct">
      <ng-container [ngSwitch]="selectedProduct">
        <div class="space"></div>
        <app-hint *ngSwitchCase="formType.PV">
          <div>
            {{ 'PRODUCTS.PV_SELECTED.INFO_1' | translate }}
            <ul class="list-container">
              <li>
                {{ 'PRODUCTS.PV_SELECTED.DOCUMENT_1' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.PV_SELECTED.DOCUMENT_2' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.PV_SELECTED.DOCUMENT_3' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.PV_SELECTED.DOCUMENT_4' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.PV_SELECTED.DOCUMENT_5' | translate }}
              </li>
            </ul>
          </div>
          <div class="space"></div>
          <p>
            {{ 'PRODUCTS.PV_SELECTED.INFO_2' | translate }}
          </p>
        </app-hint>

        <app-hint *ngSwitchCase="formType.BALCONY_PV">
          <p>
            {{ 'PRODUCTS.BALCONY_PV_SELECTED_1' | translate }}
            <span
              ><a
                class="bold"
                href="https://www.marktstammdatenregister.de/MaStR"
                target="_blank"
                >{{ 'PRODUCTS.BALCONY_PV_MASTR' | translate }}</a
              >{{ 'PRODUCTS.BALCONY_PV_SELECTED_2' | translate }}</span
            >
          </p>
          <p>
            {{ 'PRODUCTS.BALCONY_PV_SELECTED_3' | translate }}
          </p>
        </app-hint>

        <app-hint *ngSwitchCase="formType.CHARGING_DEVICE">
          {{ 'PRODUCTS.CHARGING_DEVICE_SELECTED' | translate }}</app-hint
        >

        <app-hint *ngSwitchCase="formType.GRID_CONNECTION">
          <div>
            {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.INFO_1' | translate }}
            <ul class="list-container">
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_1' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_2' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_3' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_4' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.OPTION_5' | translate }}
              </li>
            </ul>
          </div>

          <div class="space"></div>

          <div>
            {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.INFO_2' | translate }}
            <ul class="list-container">
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.DOCUMENT_1' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.DOCUMENT_2' | translate }}
              </li>
              <li>
                {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.DOCUMENT_3' | translate }}
              </li>
            </ul>
            <p>
              {{ 'PRODUCTS.GRID_CONNECTION_SELECTED.OTHER_INFO' | translate }}
            </p>
          </div>
        </app-hint>

        <app-hint *ngSwitchCase="formType.HEAT_PUMP">
          {{ 'PRODUCTS.HEAT_PUMP_SELECTED' | translate }}</app-hint
        >

        <app-hint *ngSwitchCase="formType.OTHER">
          {{ 'PRODUCTS.OTHER_SELECTED' | translate }}</app-hint
        >
      </ng-container>
    </ng-container>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: productTypeControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
