<div
  class="page-container"
  *ngIf="{ config: whiteLabelConfiguration$ | async } as data">
  <ng-container *ngIf="data.config as customerConfiguration">
    <img
      class="logo"
      [ngStyle]="
        customerConfiguration.whitelabelSettings.assetSettings.maxLogoSize
          ? {
              'max-width':
                customerConfiguration.whitelabelSettings.assetSettings
                  .maxLogoSize,
              'max-height':
                customerConfiguration.whitelabelSettings.assetSettings
                  .maxLogoSize,
            }
          : null
      "
      [alt]="customerConfiguration.customerId + ' Logo'"
      [src]="customerConfiguration.whitelabelSettings.assetSettings.logoUrl"
      *ngIf="
        customerConfiguration.whitelabelSettings.assetSettings.showLogo &&
        customerConfiguration.whitelabelSettings.assetSettings.logoUrl
      " />
  </ng-container>
  <div class="router-outlet-container">
    <router-outlet></router-outlet>
  </div>
  <div
    class="info-links"
    *ngIf="data.config?.whitelabelSettings?.legalSettings as legalSettings">
    <a
      [href]="legalSettings.termsConditionsURL"
      target="_blank"
      rel="noopener"
      *ngIf="legalSettings.termsConditionsURL"
      >{{ 'COMMON.GTC' | translate }}</a
    >
    <a
      [href]="legalSettings.dataProtectionURL"
      target="_blank"
      rel="noopener"
      *ngIf="legalSettings.dataProtectionURL"
      >{{ 'COMMON.DATA_PROTECTION' | translate }}</a
    >
    <a
      [href]="legalSettings.imprintURL"
      target="_blank"
      rel="noopener"
      *ngIf="legalSettings.imprintURL"
      >{{ 'COMMON.IMPRINT' | translate }}</a
    >
  </div>
</div>
