import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exceedsLimit',
  standalone: true,
})
export class ExceedsLimitPipe implements PipeTransform {
  public static readonly MAX_ELEMENTS = 100;
  transform(
    currentValue: number,
    limitValue: number = ExceedsLimitPipe.MAX_ELEMENTS
  ): boolean {
    return currentValue >= limitValue;
  }
}
