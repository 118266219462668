<app-form-card
  titleIcon="electric_bolt"
  cardTitle="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
  data-testid="commissioning-electricity-simple-meter-details-heading">
  <form [formGroup]="measurementForm">
    <ng-container formArrayName="measurementDetails" *ngIf="measurementDetails">
      <ng-container
        *ngFor="let control of measurementDetails.controls; let i = index"
        [formArrayName]="i">
        <section>
          <h2 appRequiredSuffix>
            {{
              'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.SUBHEADING'
                | translate
            }}
          </h2>
          <mat-radio-group
            color="primary"
            formControlName="changeType"
            class="input-container">
            <div>
              <mat-radio-button
                *ngFor="let changeType of changeTypes"
                [value]="changeType"
                [attr.data-testid]="i + '-' + changeType + '-radio-button'"
                >{{
                  'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CHANGE_TYPE.TYPES.' +
                    changeType | translate
                }}</mat-radio-button
              >
            </div>
            <mat-error
              appErrorMessages="changeType"
              [hideError]="!allTouched"></mat-error>
          </mat-radio-group>
        </section>

        <section
          *ngIf="
            (control | formLookup: 'changeType') &&
            (control | formLookup: 'changeType') !==
              measurementDeviceChangeType.INSTALLATION
          ">
          <h2 class="tooltip-heading-label">
            {{
              'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.SUBHEADING'
                | translate
            }}
            <app-info-tooltip
              tooltipText="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.TOOLTIP">
            </app-info-tooltip>
          </h2>
          <div class="input-container">
            <mat-form-field appearance="outline" class="input-field">
              <mat-label>{{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER'
                  | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                name="meterNumber"
                formControlName="meterNumber"
                [attr.data-testid]="i + '-meter-number'" />
              <mat-error appErrorMessages="meterNumber"></mat-error>
            </mat-form-field>
          </div>
          <div class="space"></div>
        </section>

        <div class="two-column-layout-container">
          <section class="two-column-layout-item">
            <h2 appRequiredSuffix>
              {{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.SUBHEADING'
                  | translate
              }}
            </h2>
            <ng-container formArrayName="measurementTypes">
              <p
                *ngFor="
                  let _ of getMeasurementTypesControl(i)?.controls;
                  let j = index
                ">
                <mat-checkbox
                  color="primary"
                  [formControlName]="j"
                  [attr.data-testid]="i + '-measurement-type-checkbox-' + j"
                  >{{ measurementTypes[j].label | translate }}
                </mat-checkbox>
              </p>
              <mat-error
                appErrorMessages="measurementTypes"
                *ngIf="allTouched"></mat-error>
            </ng-container>
          </section>

          <section class="two-column-layout-item">
            <h2 appRequiredSuffix>
              {{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.SUBHEADING'
                  | translate
              }}
            </h2>
            <mat-radio-group
              color="primary"
              formControlName="locationType"
              class="input-container">
              <mat-radio-button
                *ngFor="let locationType of locationTypes"
                [value]="locationType"
                [attr.data-testid]="i + '-' + locationType + '-radio-button'"
                >{{
                  'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.' +
                    locationType | translate
                }}</mat-radio-button
              >
              <mat-error
                appErrorMessages="locationType"
                [hideError]="!allTouched"></mat-error>
            </mat-radio-group>

            <mat-form-field
              *ngIf="
                (control | formLookup: 'locationType') === meterLocation.OTHER
              "
              appearance="outline"
              class="input-field">
              <mat-label>{{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.METER_LOCATION.TYPES.OTHER'
                  | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                name="otherLocation"
                formControlName="otherLocation"
                [attr.data-testid]="i + '-other-location'" />
              <mat-error appErrorMessages="otherLocation"></mat-error>
            </mat-form-field>
          </section>
        </div>

        <div class="two-column-layout-container">
          <section class="two-column-layout-item">
            <div class="space"></div>
            <h2 appRequiredSuffix>
              {{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.SUBHEADING'
                  | translate
              }}
            </h2>
            <mat-radio-group
              color="primary"
              formControlName="fasteningType"
              class="input-container">
              <mat-radio-button
                [value]="fasteningType.THREE_POINT"
                [attr.data-testid]="
                  i + '-' + fasteningType.THREE_POINT + '-radio-button'
                "
                >{{
                  'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.TYPES.THREE_POINT'
                    | translate
                }}</mat-radio-button
              >
              <mat-radio-button
                [value]="fasteningType.PLUG"
                [attr.data-testid]="
                  i + '-' + fasteningType.PLUG + '-radio-button'
                ">
                {{
                  'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.FASTENING_TYPE.TYPES.PLUG'
                    | translate
                }}
              </mat-radio-button>
              <mat-error
                appErrorMessages="fasteningType"
                [hideError]="!allTouched"></mat-error>
            </mat-radio-group>
          </section>

          <section class="two-column-layout-item">
            <div class="space"></div>
            <h2 appRequiredSuffix>
              {{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.SUBHEADING'
                  | translate
              }}
            </h2>
            <mat-checkbox
              color="primary"
              formControlName="basicResponsible"
              [attr.data-testid]="i + '-basic-responsible'"
              >{{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.BASIC_RESPONSIBLE'
                  | translate
              }}</mat-checkbox
            >
            <mat-form-field
              *ngIf="!(control | formLookup: 'basicResponsible')"
              appearance="outline"
              class="input-field other-operator">
              <mat-label>{{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASURING_POINT_OPERATOR.TYPES.OTHER_OPERATOR'
                  | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                name="otherOperator"
                formControlName="otherOperator"
                [attr.data-testid]="i + '-other-operator'" />
              <mat-error appErrorMessages="otherOperator"></mat-error>
            </mat-form-field>
          </section>

          <section class="two-column-layout-item">
            <h2>
              {{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CONVERTER_SIZE'
                  | translate
              }}
            </h2>

            <div class="input-container">
              <mat-form-field appearance="outline">
                <mat-label>{{
                  'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.CONVERTER_SIZE'
                    | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  name="converterSize"
                  min="0"
                  formControlName="converterSize"
                  [attr.data-testid]="i + '-' + 'converter-size'" />
                <mat-error appErrorMessages="converterSize"></mat-error>
              </mat-form-field>
            </div>
          </section>
        </div>

        <ng-container *ngIf="i > 0">
          <div class="space"></div>
          <button type="button" mat-stroked-button (click)="removeDetails(i)">
            <mat-icon>delete</mat-icon>
            {{
              'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.REMOVE_BUTTON'
                | translate
            }}
          </button>
        </ng-container>
        <ng-container *ngIf="i < measurementDetails.controls.length - 1">
          <div class="space"></div>
          <hr />
          <div class="space"></div>
        </ng-container>
      </ng-container>
      <div class="space"></div>
      <ng-container *ngIf="measurementDetails.controls.length > 1">
        <hr />
        <div class="space"></div>
      </ng-container>
      <button
        type="button"
        mat-stroked-button
        (click)="addDetails()"
        [disabled]="measurementDetails.controls.length | exceedsLimit"
        data-testid="add-button">
        <mat-icon>add</mat-icon>
        {{
          'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.ADD_MORE_BUTTON'
            | translate
        }}
      </button>
      <div class="space"></div>
    </ng-container>
  </form>
  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: measurementForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
