import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { FORM_TYPE, HEAT_OPTION } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { DateHelperService } from '@app/services/date-helper.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

interface HeatOption {
  icon: string;
  label: string;
  type: HEAT_OPTION;
  formType: FORM_TYPE;
}

@Component({
  selector: 'app-heat-options',
  templateUrl: './heat-options.component.html',
  styleUrls: ['./heat-options.component.scss'],
})
export class HeatOptionsComponent implements OnInit, OnDestroy {
  public readonly heatOptionsList: HeatOption[] = [
    {
      icon: 'add_circle',
      label: 'HEAT.HEAT_OPTIONS.HEAT_NEW.LABEL',
      type: HEAT_OPTION.NEW,
      formType: FORM_TYPE.HEAT_NEW,
    },
    {
      icon: 'settings',
      label: 'HEAT.HEAT_OPTIONS.HEAT_CHANGE.LABEL',
      type: HEAT_OPTION.CHANGE,
      formType: FORM_TYPE.HEAT_CHANGE,
    },
    {
      icon: 'cancel',
      label: 'HEAT.HEAT_OPTIONS.HEAT_DETACH.LABEL',
      type: HEAT_OPTION.DETACH,
      formType: FORM_TYPE.HEAT_DETACH,
    },
  ];
  public heatModule = FLOW_MODULE.HEAT;
  public heatOptions = HEAT_OPTION;
  public selectedOption?: HEAT_OPTION;
  public heatOptionsForm!: FormGroup;
  public heatOptionControl!: FormControl;
  public changeHeatPowerControl?: FormControl;
  public changeOtherControl?: FormControl;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public dateForTomorrow = this.dateHelperService.getFutureDate(1);
  public errorMessageForInvalidDateFormat =
    this.dateHelperService.getErrorMessageForInvalidDateFormat();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private routeService: RouteService,
    private dateHelperService: DateHelperService
  ) {}

  public ngOnInit(): void {
    this.createHeatOptionsForm();
    this.updateForm();
    this.watchForm();
  }

  private createHeatOptionsForm(): void {
    this.heatOptionsForm = this.formBuilder.group({
      heatOption: [null, Validators.required],
    });
    this.heatOptionControl = this.heatOptionsForm.get(
      'heatOption'
    ) as FormControl;
    this.watchHeatOption();
  }

  private watchHeatOption(): void {
    this.heatOptionControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((heatOption: HEAT_OPTION) => {
        this.removeControls();
        if (heatOption === HEAT_OPTION.CHANGE) {
          this.heatOptionsForm.addControl(
            'changeHeatPower',
            new FormControl(false, Validators.required)
          );
          this.heatOptionsForm.addControl(
            'changeOther',
            new FormControl(false, Validators.required)
          );
          this.watchHeatPowerAndOther();
        } else if (heatOption === HEAT_OPTION.DETACH) {
          this.heatOptionsForm.addControl(
            'permanentDeconstruction',
            new FormControl(null, Validators.required)
          );
          this.heatOptionsForm.addControl(
            'meterNumber',
            new FormControl(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
          this.watchPermanentDeconstruction();
        }
      });
    this.heatOptionsForm.updateValueAndValidity();
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.heatOptions),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(heatOptions => {
        this.heatOptionsForm.patchValue(heatOptions);
        this.selectedOption = heatOptions.heatOption;
      });
  }

  private watchForm(): void {
    this.heatOptionsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(heatOptions => {
        this.appStateService.updateFormData({
          heatOptions,
        });
      });
  }

  private mapHeatOptionToFormType(heatOption: HEAT_OPTION): FORM_TYPE {
    switch (heatOption) {
      case HEAT_OPTION.NEW:
        return FORM_TYPE.HEAT_NEW;
      case HEAT_OPTION.CHANGE:
        return FORM_TYPE.HEAT_CHANGE;
      case HEAT_OPTION.DETACH:
        return FORM_TYPE.HEAT_DETACH;
      default:
        return FORM_TYPE.HEAT_NEW;
    }
  }

  private watchHeatPowerAndOther(): void {
    this.changeHeatPowerControl = this.heatOptionsForm.get(
      'changeHeatPower'
    ) as FormControl;
    this.changeOtherControl = this.heatOptionsForm.get(
      'changeOther'
    ) as FormControl;

    this.changeHeatPowerControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(changeHeatPower => {
        if (changeHeatPower) {
          this.heatOptionsForm.addControl(
            'currentHeatPower',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
          this.heatOptionsForm.addControl(
            'plannedHeatPower',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
          this.heatOptionsForm.addControl(
            'additionalHeatDemand',
            new FormControl(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
              ],
            })
          );
        } else {
          this.heatOptionsForm.removeControl('heatPower');
          this.heatOptionsForm.removeControl('plannedHeatPower');
          this.heatOptionsForm.removeControl('additionalHeatDemand');
        }
      });

    this.changeOtherControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(changeOther => {
        if (changeOther) {
          this.heatOptionsForm.addControl(
            'otherChanges',
            new FormControl(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
        } else {
          this.heatOptionsForm.removeControl('otherChanges');
        }
      });
  }

  private watchPermanentDeconstruction(): void {
    this.heatOptionsForm
      .get('permanentDeconstruction')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(permanentDeconstruction => {
        if (permanentDeconstruction) {
          this.heatOptionsForm.removeControl('deconstructionUntil');
        } else {
          this.heatOptionsForm.addControl(
            'deconstructionUntil',
            new FormControl(null, [Validators.required])
          );
        }
      });
  }

  private removeControls(): void {
    this.heatOptionsForm.removeControl('changeHeatPower');
    this.heatOptionsForm.removeControl('changeOther');
    this.heatOptionsForm.removeControl('currentHeatPower');
    this.heatOptionsForm.removeControl('plannedHeatPower');
    this.heatOptionsForm.removeControl('additionalHeatDemand');
    this.heatOptionsForm.removeControl('otherChanges');
    this.heatOptionsForm.removeControl('permanentDeconstruction');
    this.heatOptionsForm.removeControl('meterNumber');
    this.heatOptionsForm.removeControl('deconstructionUntil');
  }

  public selectType(selectedOption: HEAT_OPTION): void {
    if (this.heatOptionsForm.get('heatOption')?.value === selectedOption) {
      return;
    }
    this.heatOptionsForm.get('heatOption')?.setValue(selectedOption);
    this.selectedOption = selectedOption;
    const formType = this.mapHeatOptionToFormType(selectedOption);
    this.appStateService.updateFormData({ formType });
    this.heatOptionsForm.markAsUntouched();
    this.allTouched = false;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.heatOptionsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.heatOptionsForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
