import { Component, Input } from '@angular/core';
import { FLOW } from '@app/models/form-data.interface';
import { ModuleDetails } from '@app/modules/common-steps/intro/intro.component';

@Component({
  selector: 'app-intro-module-card',
  templateUrl: './intro-module-card.component.html',
  styleUrl: './intro-module-card.component.scss',
})
export class IntroModuleCardComponent {
  @Input() public moduleDetailsList!: ModuleDetails[];
  @Input() public flowType: FLOW = FLOW.REGISTRATION;
}
