<app-form-card
  titleIcon="electric_bolt"
  cardTitle="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
  data-testid="commissioning-electricity-simple-meter-details-heading">
  <h2 class="tooltip-heading-label">
    {{
      'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.SUBHEADING'
        | translate
    }}
    <app-info-tooltip
      tooltipText="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.TOOLTIP">
    </app-info-tooltip>
  </h2>
  <form [formGroup]="form">
    <div class="single-column-layout half">
      <div class="input-container">
        <ng-container
          formArrayName="meterNumbers"
          *ngFor="
            let _ of meterNumbers.value;
            let j = index;
            trackBy: trackByMeterNumbers
          ">
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>{{
                'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.METER_NUMBER'
                  | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                [name]="'meterNumber_' + j"
                [formControl]="getMeterNumbersFormControl(j)"
                [attr.data-testid]="'meter-number-' + j" />
              <mat-error [appErrorMessages]="'' + j"></mat-error>
            </mat-form-field>
            <button
              type="button"
              mat-icon-button
              class="input-field-postfix"
              [disabled]="j === 0"
              (click)="deleteMeterNumber(j)"
              [attr.data-testid]="'delete-meter-number-' + j + '-button'">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
      <button
        type="button"
        mat-stroked-button
        (click)="addMeterNumber()"
        [disabled]="meterNumbers.length | exceedsLimit"
        data-testid="add-meter-button">
        <mat-icon>add</mat-icon>
        {{
          'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.DEVICE_INFORMATION.ADD_METER'
            | translate
        }}
      </button>
    </div>
  </form>
  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
