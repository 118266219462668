<div class="card-container">
  <div class="card">
    <mat-icon
      aria-hidden="false"
      aria-label="Card icon"
      [fontIcon]="titleIcon || ''"
      [svgIcon]="svgIcon || ''"
      class="card-icon material-icons-outlined"
      *ngIf="titleIcon || svgIcon"></mat-icon>
    <h1 *ngIf="cardTitle" [attr.data-testid]="dataTestId">
      {{ cardTitle | translate }}
    </h1>
    <p class="description" *ngIf="description">
      {{ description | translate }}
    </p>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="navigation">
      <ng-content select="[slot=navigation]"></ng-content>
    </div>
  </div>
</div>
