import { Component, inject, Input } from '@angular/core';
import { CommissioningElectricityContact } from '@app/models/form-data.interface';
import { ROUTES } from '@app/models/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { getLanguageLocaleCulture } from '@app/constants/language';

@Component({
  selector: 'app-commissioning-contact-data',
  templateUrl: './commissioning-contact-data.component.html',
})
export class CommissioningContactDataComponent {
  @Input({ required: true }) public contact!: CommissioningElectricityContact;
  @Input() public contactRoute: ROUTES =
    ROUTES.COMMISSIONING_ELECTRICITY_CONTACT;
  readonly #translateService = inject(TranslateService);
  public readonly currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );
}
