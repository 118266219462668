import { Component, inject, Input } from '@angular/core';
import { ConstructionGridConnectionDetails } from '@app/models/registration-form';
import { TranslateService } from '@ngx-translate/core';
import { getLanguageLocaleCulture } from '@app/constants/language';

@Component({
  selector: 'app-construction-grid-connection-details-summary',
  templateUrl: './construction-grid-connection-details-summary.component.html',
})
export class ConstructionGridConnectionDetailsSummaryComponent {
  @Input({ required: true })
  public constructionGridConnectionDetails!: ConstructionGridConnectionDetails;
  readonly #translateService = inject(TranslateService);
  public readonly currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );
}
