<app-form-card
  titleIcon="category"
  cardTitle="WATER.WATER_AREA.HEADING"
  description="WATER.WATER_AREA.DESCRIPTION">
  <form>
    <app-selection-group>
      <ng-container *ngFor="let waterArea of waterAreaDetailsList">
        <app-selection-card
          *ngIf="waterArea.type | isModuleOptionEnabled: waterModule | async"
          [svgIcon]="waterArea.svgIcon"
          [label]="waterArea.label"
          [selected]="selectedWaterArea === waterArea.type"
          (click)="selectArea(waterArea.type)"
          class="selection-card">
        </app-selection-card>
      </ng-container>
      <mat-error *ngIf="allTouched && waterAreaControl.invalid">{{
        'VALIDATION.REQUIRED_SELECTION' | translate
      }}</mat-error>
    </app-selection-group>

    <ng-container *ngIf="selectedWaterArea === formType.CONSTRUCTION_WATER">
      <div class="space"></div>
      <app-hint>
        <span>{{
          'WATER.WATER_AREA.CONSTRUCTION_WATER_HINT' | translate
        }}</span>
      </app-hint>
    </ng-container>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: waterAreaControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
