import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanTranslateKey',
  standalone: true,
})
export class BooleanTranslateKeyPipe implements PipeTransform {
  transform(
    value: boolean,
    keyTrue: string = 'COMMON.YES',
    keyFalse: string = 'COMMON.NO'
  ): string {
    return value ? keyTrue : keyFalse;
  }
}
