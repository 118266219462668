<app-form-card titleIcon="waving_hand" cardTitle="DOMAIN.HEADING">
  <div class="domain-content" slot="content">
    <p class="domain-text">{{ 'DOMAIN.WELCOME_TEXT' | translate }}</p>
    <p class="domain-text">{{ 'DOMAIN.DESCRIPTION' | translate }}</p>
    <p class="domain-text">
      {{ 'DOMAIN.ELECTRICITY_COMMISSIONING_TEXT' | translate }}
    </p>
    <ul>
      <li>
        {{ 'INTRO.COMMISSIONING.ELECTRICITY.MAIN_POWER_SUPPLY' | translate }}
      </li>
      <li>
        {{ 'INTRO.COMMISSIONING.ELECTRICITY.CUSTOMER_FACILITY' | translate }}
      </li>
    </ul>

    <div class="space"></div>

    <h2>{{ 'DOMAIN.QUESTION_1' | translate }}</h2>
    <p class="domain-text">
      {{ 'DOMAIN.ANSWER_1' | translate }}
    </p>
    <a
      [href]="'DOMAIN.BUTTON_1_LINK' | translate"
      target="_blank"
      rel="noreferrer"
      class="domain-link">
      {{ 'DOMAIN.BUTTON_1_LABEL' | translate }}
      <mat-icon>open_in_new</mat-icon>
    </a>
    <div class="space"></div>

    <h2>{{ 'DOMAIN.QUESTION_2' | translate }}</h2>
    <p class="domain-text">{{ 'DOMAIN.ANSWER_2' | translate }}</p>
    <a
      [href]="'DOMAIN.BUTTON_2_LINK' | translate"
      target="_blank"
      rel="noreferrer"
      class="domain-link">
      {{ 'DOMAIN.BUTTON_2_LABEL' | translate }}
      <mat-icon>open_in_new</mat-icon>
    </a>
  </div>
</app-form-card>
