<app-form-card
  titleIcon="check"
  cardTitle="SUCCESS.HEADING"
  dataTestId="success-heading">
  <p [innerHTML]="'SUCCESS.DESCRIPTION' | translate"></p>
  <div class="space"></div>
  <div class="space"></div>

  <div class="next-step">
    <mat-icon
      fontIcon="fact_check"
      class="material-icons-outlined next-step-icon">
    </mat-icon>
    <p>{{ 'SUCCESS.NEXT_STEP_1' | translate }}</p>
  </div>

  <div class="next-step">
    <mat-icon
      fontIcon="perm_phone_msg"
      class="material-icons-outlined next-step-icon">
    </mat-icon>
    <p>{{ 'SUCCESS.NEXT_STEP_2' | translate }}</p>
  </div>

  <app-form-navigation
    slot="navigation"
    [previousConfig]="{ visible: false }"
    [nextConfig]="{ label: 'SUCCESS.NEW_REQUEST_BTN' }"
    (next)="startNewRequest()">
  </app-form-navigation>
</app-form-card>
