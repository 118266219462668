import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-multi-selection-card',
  templateUrl: './multi-selection-card.component.html',
  styleUrls: ['./multi-selection-card.component.scss'],
})
export class MultiSelectionCardComponent {
  @Input() public icon!: string;
  @Input() public label!: string;
  @Input() public formGroupControl!: FormGroup;
  @Input() public controlName!: string;
  @Output() public selectionChanged = new EventEmitter();

  public cardClicked(): void {
    this.selectionChanged.emit();
  }
}
