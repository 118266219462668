<app-form-card
  titleIcon="electric_bolt"
  cardTitle="BALCONY_PV.HEADING"
  description="BALCONY_PV.DESCRIPTION"
  data-testid="balcony-pv-heading">
  <form [formGroup]="form">
    <div class="space"></div>
    <h2 class="tooltip-heading-label">
      {{ 'BALCONY_PV.SYSTEM_BASIC_INFORMATION' | translate }}
      <app-info-tooltip
        tooltipText="BALCONY_PV.SYSTEM_BASIC_INFORMATION_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'BALCONY_PV.SYSTEM' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="system"
          formControlName="system"
          data-testid="system" />
        <mat-error appErrorMessages="system"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="nested-input-field">
        <mat-label>{{
          'BALCONY_PV.SYSTEM_MANUFACTURER' | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="systemManufacturer"
          formControlName="systemManufacturer"
          data-testid="system-manufacturer" />
        <mat-error appErrorMessages="systemManufacturer"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="nested-input-field">
        <mat-label>{{ 'BALCONY_PV.SYSTEM_TYPE' | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="systemType"
          formControlName="systemType"
          data-testid="system-type" />
        <mat-error appErrorMessages="systemType"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          {{ 'BALCONY_PV.SYSTEM_POWER' | translate }}
          <app-info-tooltip tooltipText="BALCONY_PV.SYSTEM_POWER_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-form-field appearance="outline" class="input-container">
          <mat-label>{{
            'BALCONY_PV.SYSTEM_POWER_LABEL' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            name="systemPower"
            formControlName="systemPower"
            min="0"
            data-testid="system-power" />
          <mat-error appErrorMessages="systemPower"></mat-error>
        </mat-form-field>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          {{ 'BALCONY_PV.INVERTER_POWER' | translate }}
          <app-info-tooltip tooltipText="BALCONY_PV.INVERTER_POWER_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-form-field appearance="outline" class="input-container">
          <mat-label>{{
            'BALCONY_PV.INVERTER_POWER_LABEL' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            name="inverterPower"
            formControlName="inverterPower"
            min="0"
            data-testid="inverter-power" />
          <mat-error appErrorMessages="inverterPower"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      <span appRequiredSuffix>
        {{ 'BALCONY_PV.FREE_FEED_IN_DESIRED' | translate }}
      </span>
      <app-info-tooltip tooltipText="BALCONY_PV.FREE_FEED_IN_DESIRED_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="freeFeedIn"
      aria-labelledby="radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="free-feed-in">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-free-feed-in">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="freeFeedIn" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <h2 class="tooltip-heading-label">
      {{ 'BALCONY_PV.METER_NUMBER' | translate }}
      <app-info-tooltip tooltipText="BALCONY_PV.METER_NUMBER_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'BALCONY_PV.METER_NUMBER' | translate }}</mat-label>
        <input
          matInput
          type="string"
          name="meterNumber"
          formControlName="meterNumber"
          data-testid="meter-number" />
        <mat-error appErrorMessages="meterNumber"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>
    <h2>
      {{ 'BALCONY_PV.ANYTHING_ELSE' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <mat-label>{{ 'BALCONY_PV.NOTES' | translate }}</mat-label>
        <textarea
          #notes
          matInput
          cols="50"
          rows="6"
          [maxlength]="maxLength"
          formControlName="notes"
          data-testid="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
