<app-form-card
  titleIcon="home"
  cardTitle="CHARGING_DEVICE_PROPERTY_TYPE.HEADING"
  dataTestId="charging-device-property-type-heading">
  <h2 class="full-width">
    {{ 'CHARGING_DEVICE_PROPERTY_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let propertyType of propertyTypeList"
      [icon]="propertyType.icon"
      [label]="propertyType.label"
      [selected]="selectedPropertyType === propertyType.type"
      (click)="selectPropertyType(propertyType.type)"
      [attr.data-testid]="propertyType.type">
    </app-selection-card>

    <mat-error
      *ngIf="allTouched | nextButtonDisabled: propertyTypeFormControl.invalid"
      >{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error
    >
  </app-selection-group>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched | nextButtonDisabled: propertyTypeFormControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
