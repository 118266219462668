import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { MainPowerSupplyType } from '@app/models/commissioning-mapped-data.interface';

@Component({
  selector: 'app-power-increase-details',
  templateUrl: './power-increase-details.component.html',
  styleUrls: ['./power-increase-details.component.scss'],
})
export class PowerIncreaseDetailsComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public allTouched = false;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      currentPowerDemand: [null, [Validators.required, Validators.min(0)]],
      plannedPowerDemand: [null, [Validators.required, Validators.min(0)]],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.mainPowerSupplyPowerIncreaseDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(mainPowerSupplyPowerIncreaseDetails =>
        this.form.patchValue(mainPowerSupplyPowerIncreaseDetails)
      );
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    this.updateState();
    this.allTouched = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.routeService.navigateToNextStep();
    }
  }

  private updateState() {
    const mainPowerSupplyPowerIncreaseDetails = this.form.value;
    this.appStateService.updateFormData({
      mainPowerSupplyPowerIncreaseDetails: {
        type: MainPowerSupplyType.POWER_INCREASE,
        ...mainPowerSupplyPowerIncreaseDetails,
      },
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
