<div class="multi-selection-card-container" [formGroup]="formGroupControl">
  <app-selection-card
    [icon]="icon"
    [label]="label"
    [selected]="formGroupControl | formLookup: controlName"
    class="selection-card"
    (click)="cardClicked()">
  </app-selection-card>
  <mat-checkbox color="primary" [formControlName]="controlName"> </mat-checkbox>
</div>
