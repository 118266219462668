<button
  type="button"
  color="primary"
  mat-raised-button
  (click)="previous.emit()"
  [disabled]="_previousConfig.disabled"
  *ngIf="_previousConfig.visible"
  data-testid="previous-button">
  <mat-icon *ngIf="_previousConfig.arrow" fontIcon="arrow_back"></mat-icon>
  <span *ngIf="_previousConfig.label">
    {{ _previousConfig.label | translate }}</span
  >
</button>
<div class="space"></div>
<button
  type="button"
  color="primary"
  mat-raised-button
  (click)="next.emit()"
  [disabled]="_nextConfig.disabled"
  *ngIf="_nextConfig.visible"
  data-testid="next-button">
  <mat-icon *ngIf="_nextConfig.arrow" fontIcon="arrow_forward"></mat-icon>
  <span *ngIf="_nextConfig.label"> {{ _nextConfig.label | translate }}</span>
</button>
