<app-form-card titleIcon="home" cardTitle="HEAT.HEAT_PROPERTY_TYPE.HEADING">
  <h2 class="full-width">
    {{ 'HEAT.HEAT_PROPERTY_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let property of propertyDetailsList"
      [icon]="property.icon"
      [label]="property.label"
      [selected]="selectedPropertyType === property.type"
      (click)="selectPropertyType(property.type)">
    </app-selection-card>

    <mat-error *ngIf="allTouched && !selectedPropertyType">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <div class="space"></div>

  <form [formGroup]="propertyDetailsForm">
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'SHARED.BUILDING_STRUCTURE.LABEL' | translate
        }}</mat-label>
        <mat-select formControlName="buildingStructure">
          <mat-option
            *ngFor="let structureType of buildingStructures"
            [value]="structureType">
            {{ 'SHARED.BUILDING_STRUCTURE.' + structureType | translate }}
          </mat-option>
        </mat-select>
        <mat-error appErrorMessages="buildingStructure"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyDetailsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
