import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMISSIONING_FLOW_TYPE } from '@app/models/commissioning-mapped-data.interface';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

interface Option {
  svgIcon: string;
  label: string;
  type: COMMISSIONING_FLOW_TYPE;
}

@Component({
  selector: 'app-commissioning-electricity-options',
  templateUrl: './commissioning-electricity-options.component.html',
})
export class CommissioningElectricityOptionsComponent
  implements OnInit, OnDestroy
{
  public readonly optionsList: Option[] = [
    {
      svgIcon: 'offline_bolt',
      label: 'COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY',
      type: COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY,
    },
    {
      svgIcon: 'solar_power',
      label: 'COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY',
      type: COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY,
    },
  ];

  public selectedOption?: COMMISSIONING_FLOW_TYPE;
  public optionsForm!: FormGroup;
  public allTouched = false;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createOptionsForm();
    this.updateForm();
    this.watchForm();
  }

  private createOptionsForm(): void {
    this.optionsForm = this.formBuilder.group({
      option: [null, Validators.required],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.commissioningFlowType),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(commissioningElectricityOption => {
        this.optionsForm.patchValue({ option: commissioningElectricityOption });
        this.selectedOption = commissioningElectricityOption;
      });
  }

  private watchForm(): void {
    this.optionsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(commissioningElectricityOption =>
        this.appStateService.updateFormData({
          commissioningFlowType: commissioningElectricityOption.option,
        })
      );
  }

  public selectOption(selectedOption: COMMISSIONING_FLOW_TYPE): void {
    if (this.optionsForm.get('option')?.value !== selectedOption) {
      this.optionsForm.get('option')?.setValue(selectedOption);
      this.selectedOption = selectedOption;
      this.optionsForm.markAsUntouched();
      this.allTouched = false;
    }
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.optionsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.optionsForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
