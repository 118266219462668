import {
  Address,
  BUILDING_TYPE,
  ContactDetails,
  DocumentDetails,
  Location,
  Metadata,
} from './registration-form';
import { FLOW_MODULE } from '@app/models/form-data.interface';

export enum COMMISSIONING_FLOW_TYPE {
  MAIN_POWER_SUPPLY = 'MAIN_POWER_SUPPLY',
  CUSTOMER_FACILITY = 'CUSTOMER_FACILITY',
}

export enum ELECTRICITY_SYSTEM_TYPE {
  PV = 'PV',
  STORAGE = 'STORAGE',
  CHARGING_DEVICE = 'CHARGING_DEVICE',
  HEAT_PUMP = 'HEAT_PUMP',
  OTHER = 'OTHER',
}

export enum MEASUREMENT_TYPE {
  AC_METER = 'AC_METER',
  THREE_PHASE_METER = 'THREE_PHASE_METER',
  MULTI_TARIFF_METER = 'MULTI_TARIFF_METER',
  LOAD_PROFILE_METER = 'LOAD_PROFILE_METER',
  BIDIRECTIONAL_METER = 'BIDIRECTIONAL_METER',
  CONVERTER_METER = 'CONVERTER_METER',
  MAXIMUM_METER = 'MAXIMUM_METER',
  RADIO_RIPPLE_RECEIVER_CONTROL = 'RADIO_RIPPLE_RECEIVER_CONTROL',
}

export enum FASTENING_TYPE {
  THREE_POINT = 'THREE_POINT',
  PLUG = 'PLUG',
}

export enum MEASUREMENT_DEVICE_CHANGE_TYPE {
  INSTALLATION = 'INSTALLATION',
  CHANGE = 'CHANGE',
  DISASSEMBLY = 'DISASSEMBLY',
}

export enum METER_LOCATION {
  BASEMENT = 'BASEMENT',
  HALLWAY_STAIRWELL = 'HALLWAY_STAIRWELL',
  HOUSE_CONNECTION_ROOM = 'HOUSE_CONNECTION_ROOM',
  FLAT = 'FLAT',
  METER_CONNECTION_PILLAR = 'METER_CONNECTION_PILLAR',
  OTHER = 'OTHER',
}

export interface ElectricitySystem {
  type: ELECTRICITY_SYSTEM_TYPE;
  systemPower?: number;
  systemCount: number;
  systemType?: string;
  notes?: string;
}

export interface MeasurementDetails {
  changeType: MEASUREMENT_DEVICE_CHANGE_TYPE;
  meterNumber?: string; // Required, if changeType is one of [CHANGE, DISASSEMBLY]
  measurementTypes: MEASUREMENT_TYPE[];
  meterLocation: MeterLocation;
  fasteningType: FASTENING_TYPE;
  converterSize: number;
  measuringPointOperator: MeasuringPointOperator;
}

export interface MeterLocation {
  locationType: METER_LOCATION;
  otherLocation?: string; // Required, if locationType = OTHER
}
export interface MeasuringPointOperator {
  basicResponsible: boolean;
  otherOperator?: string; // Required, if basicResponsible = false
}

export type ElectricityCommissioningFlowDetails =
  ElectricityCommissioningFlowBase & ElectricityCommissioningFlowDetailsTypes;
export type ElectricityCommissioningFlowDetailsTypes =
  | MainPowerSupply
  | CustomerFacility;

export type ElectricityCommissioningFlowBase = {
  documentDetails: DocumentDetails;
};
export enum MainPowerSupplyType {
  NEW_GRID_CONNECTION = 'NEW_GRID_CONNECTION',
  POWER_INCREASE = 'POWER_INCREASE',
  DECOMMISSIONING = 'DECOMMISSIONING',
}

export type MainPowerSupply = {
  type: COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY;
  details:
    | MainPowerSupplyNewGridConnection
    | MainPowerSupplyPowerIncrease
    | MainPowerSupplyDecommissioning;
};

export type MainPowerSupplyNewGridConnection = {
  buildingType: BUILDING_TYPE;
  flatCount?: number;
  area?: number;
  systemPower: number;
  connectionFuse: number;
  constructionElectricity: boolean;
  type: MainPowerSupplyType.NEW_GRID_CONNECTION;
};

export type MainPowerSupplyPowerIncrease = {
  currentPowerDemand: number;
  plannedPowerDemand: number;
  type: MainPowerSupplyType.POWER_INCREASE;
};

export type MainPowerSupplyDecommissioning = {
  type: MainPowerSupplyType.DECOMMISSIONING;
};

export type CustomerFacility = (
  | CustomerFacilityWithoutMeterChanges
  | CustomerFacilityWithMeterChanges
) &
  CustomerFacilityBase;

export type CustomerFacilityBase = {
  type: COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY;
};

export type CustomerFacilityWithoutMeterChanges = {
  meterChanges: false;
  electricitySystems: ElectricitySystem[];
  meterNumbers: string[];
};

export type CustomerFacilityWithMeterChanges = {
  meterChanges: true;
  electricitySystems?: ElectricitySystem[];
  measurementDetails: MeasurementDetails[];
};

export interface ElectricityInstallerContact {
  module: string;
  gridOperator: string;
  idNumber: string;
  name: string;
  location: string;
  email: string;
  phone: string;
}

export interface ElectricityCommissioning {
  flowDetails: ElectricityCommissioningFlowDetails;
  installerContact: ElectricityInstallerContact;
  module: FLOW_MODULE.ELECTRICITY;
}

export interface CommissioningContact {
  requesterContact: ContactDetails;
  differentAddress: boolean;
  differentRequesterAddress?: Address;
}

export interface CommissioningMappedData {
  id?: string; // set by backend
  requestId?: string; // set by backend
  flow: ElectricityCommissioning;
  location: Location;
  contact: CommissioningContact;
  metadata: Metadata;
  requestType: 'COMMISSIONING';
}
