import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
})
export class FormCardComponent {
  @Input() public titleIcon?: string;
  @Input() public svgIcon?: string;
  @Input() public cardTitle?: string;
  @Input() public description?: string;
  @Input() public dataTestId?: string;
}
