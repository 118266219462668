<app-form-card
  titleIcon="category"
  cardTitle="PV_TYPE.HEADING"
  dataTestId="pv-type-heading">
  <h2 class="full-width">
    {{ 'PV_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let connection of connectionDetailsList"
      class="selection-card"
      [icon]="connection.icon"
      [svgIcon]="connection.svgIcon"
      [label]="connection.label"
      [selected]="selectedType === connection.type"
      (click)="selectType(connection.type)"
      [attr.data-testid]="connection.type">
    </app-selection-card>
    <mat-error
      *ngIf="allTouched | nextButtonDisabled: pvTypeFormControl.invalid"
      >{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error
    >
  </app-selection-group>

  <ng-container *ngIf="selectedType">
    <div class="space"></div>
    <app-hint>
      {{ 'PV_TYPE.INFO.' + selectedType | translate }}
    </app-hint>
    <div class="space"></div>
  </ng-container>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: pvTypeFormControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
