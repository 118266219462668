import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapWithPrefix',
  standalone: true,
})
export class MapWithPrefixPipe implements PipeTransform {
  transform(value: string[], prefix?: string): string[] {
    if (!prefix) {
      return value;
    }
    return value.map(element => prefix + element);
  }
}
