import { Component, inject, Input } from '@angular/core';
import { ConstructionWaterRequirements } from '@app/models/form-data.interface';
import { TranslateService } from '@ngx-translate/core';
import { getLanguageLocaleCulture } from '@app/constants/language';

@Component({
  selector: 'app-construction-water-requirements-summary',
  templateUrl: './construction-water-requirements-summary.component.html',
})
export class ConstructionWaterRequirementsSummaryComponent {
  @Input({ required: true })
  public constructionWaterRequirements!: ConstructionWaterRequirements;
  readonly #translateService = inject(TranslateService);
  public readonly currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );
}
