<app-summary-section
  heading="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.POWER_INCREASE.HEADING"
  [stepRoute]="powerIncreaseDetailsRoute"
  *ngIf="powerIncreaseDetails">
  <app-summary-section-field
    label="POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND"
    [value]="powerIncreaseDetails.currentPowerDemand"
    *ngIf="powerIncreaseDetails.currentPowerDemand">
  </app-summary-section-field>
  <app-summary-section-field
    label="POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND"
    [value]="powerIncreaseDetails.plannedPowerDemand"
    *ngIf="powerIncreaseDetails.plannedPowerDemand">
  </app-summary-section-field>
</app-summary-section>
