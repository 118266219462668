<app-summary-section
  heading="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.HEADING"
  [stepRoute]="routes.COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE">
  <app-chip>
    {{
      'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.TYPE.' + mainPowerSupplyType
        | translate
    }}
  </app-chip>
</app-summary-section>

<ng-container [ngSwitch]="mainPowerSupplyType">
  <app-new-grid-connection-summary
    *ngSwitchCase="type.NEW_GRID_CONNECTION"
    [buildingDetails]="buildingDetails!"
    [electricityInformation]="
      electricityInformation!
    "></app-new-grid-connection-summary>

  <app-power-increase-summary
    *ngSwitchCase="type.POWER_INCREASE"
    [powerIncreaseDetails]="powerIncreaseDetails!"></app-power-increase-summary>
</ng-container>
