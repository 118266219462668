<app-form-card
  titleIcon="electric_bolt"
  cardTitle="STORAGE_DETAILS.HEADING"
  description="STORAGE_DETAILS.DESCRIPTION"
  dataTestId="storage-information-heading">
  <div class="space"></div>

  <form [formGroup]="storageInformationForm">
    <h2>
      {{ 'STORAGE_DETAILS.STORAGE_UNIT_DATA.SUBHEADING' | translate }}
    </h2>
    <div class="input-container">
      <div class="small-input-field-container">
        <mat-form-field appearance="outline" class="small-input-field">
          <mat-label>{{
            'STORAGE_DETAILS.STORAGE_UNIT_DATA.MANUFACTURER' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            name="manufacturer"
            formControlName="manufacturer"
            data-testid="manufacturer" />
          <mat-error appErrorMessages="manufacturer"></mat-error>
        </mat-form-field>
      </div>

      <div class="small-input-field-container">
        <mat-form-field appearance="outline" class="small-input-field">
          <mat-label>{{
            'STORAGE_DETAILS.STORAGE_UNIT_DATA.TYPE' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            name="type"
            formControlName="type"
            data-testid="storage-type" />
          <mat-error appErrorMessages="type"></mat-error>
        </mat-form-field>
      </div>

      <div class="small-input-field-container">
        <mat-form-field appearance="outline" class="small-input-field">
          <mat-label>{{
            'STORAGE_DETAILS.STORAGE_UNIT_DATA.STORAGE_UNIT_COUNT' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            name="storageUnitCount"
            formControlName="storageUnitCount"
            min="0"
            data-testid="storage-unit-count" />
          <mat-error appErrorMessages="storageUnitCount"></mat-error>
        </mat-form-field>
      </div>

      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label
            [innerHTML]="
              'STORAGE_DETAILS.STORAGE_UNIT_DATA.MAX_CONTROLLABLE_POWER'
                | translate
            "></mat-label>
          <input
            matInput
            type="number"
            name="maxControllablePower"
            formControlName="maxControllablePower"
            min="0"
            data-testid="max-controllable-power" />
          <mat-error appErrorMessages="maxControllablePower"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="STORAGE_DETAILS.STORAGE_UNIT_DATA.MAX_CONTROLLABLE_POWER_TOOLTIP">
        </app-info-tooltip>
      </div>

      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label
            [innerHTML]="
              'STORAGE_DETAILS.STORAGE_UNIT_DATA.MAX_APPARENT_POWER_SUM'
                | translate
            "></mat-label>
          <input
            matInput
            type="number"
            name="maxApparentPowerSum"
            formControlName="maxApparentPowerSum"
            min="0"
            data-testid="max-apparent-power-sum" />
          <mat-error appErrorMessages="maxApparentPowerSum"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="STORAGE_DETAILS.STORAGE_UNIT_DATA.MAX_APPARENT_POWER_SUM_TOOLTIP">
        </app-info-tooltip>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="unit-coupling-radio-group-label">{{
            ('STORAGE_DETAILS.UNIT_COUPLING.LABEL' | translate) + ' *'
          }}</label>
          <app-info-tooltip tooltipText="STORAGE_DETAILS.UNIT_COUPLING.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="unitCoupling"
          aria-labelledby="unit-coupling-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of unitCouplingOptions"
            [value]="option"
            [attr.data-testid]="option">
            {{ 'STORAGE_DETAILS.UNIT_COUPLING.OPTION.' + option | translate }}
          </mat-radio-button>
          <mat-error appErrorMessages="unitCoupling" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="operating-mode-radio-group-label">{{
            ('STORAGE_DETAILS.OPERATING_MODE.LABEL' | translate) + ' *'
          }}</label>
          <app-info-tooltip
            tooltipText="STORAGE_DETAILS.OPERATING_MODE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="operatingMode"
          aria-labelledby="operating-mode-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of operatingModeOptions"
            [value]="option"
            [attr.data-testid]="option">
            {{ 'STORAGE_DETAILS.OPERATING_MODE.OPTION.' + option | translate }}
          </mat-radio-button>
          <mat-error appErrorMessages="operatingMode" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <h2 class="tooltip-heading-label">
      <label id="measurement-concept-radio-group-label">{{
        'STORAGE_DETAILS.MEASUREMENT_CONCEPT.LABEL' | translate
      }}</label>
      <app-info-tooltip
        tooltipText="STORAGE_DETAILS.MEASUREMENT_CONCEPT.TOOLTIP">
      </app-info-tooltip>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="measurementConcept"
      aria-labelledby="measurement-concept-radio-group-label"
      class="input-container">
      <mat-radio-button
        *ngFor="let option of measurementConceptOptions"
        [value]="option">
        {{ 'STORAGE_DETAILS.MEASUREMENT_CONCEPT.OPTION.' + option | translate }}
      </mat-radio-button>
      <mat-error
        appErrorMessages="measurementConcept"
        [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: storageInformationForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
