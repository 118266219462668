import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MEASUREMENT_CONCEPT,
  STORAGE_OPERATING_MODE,
  UNIT_COUPLING,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { Subject, filter, map, take, takeUntil } from 'rxjs';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  templateUrl: './storage-information.component.html',
})
export class StorageInformationComponent implements OnInit, OnDestroy {
  public storageInformationForm!: FormGroup;
  public allTouched = false;
  public unitCouplingOptions = Object.keys(UNIT_COUPLING);
  public operatingModeOptions = Object.keys(STORAGE_OPERATING_MODE);
  public measurementConceptOptions = Object.keys(MEASUREMENT_CONCEPT);
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createStorageInformationForm();
    this.updateForm();
    this.watchForm();
  }

  private createStorageInformationForm(): void {
    this.storageInformationForm = this.formBuilder.group({
      manufacturer: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      type: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      storageUnitCount: [null, [Validators.required, Validators.min(0)]],
      maxControllablePower: [null, [Validators.required, Validators.min(0)]],
      maxApparentPowerSum: [null, [Validators.required, Validators.min(0)]],
      unitCoupling: [null, Validators.required],
      operatingMode: [null, Validators.required],
      measurementConcept: [null],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.storageDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(storageDetails =>
        this.storageInformationForm.patchValue(storageDetails)
      );
  }

  private watchForm(): void {
    this.storageInformationForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(storageDetails =>
        this.appStateService.updateFormData({
          storageDetails,
        })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.storageInformationForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.storageInformationForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
