import { Component, OnDestroy } from '@angular/core';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { WhiteLabelService } from '@app/services/white-label.service';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private routeService: RouteService,
    private whiteLabelService: WhiteLabelService,
    private appStateService: AppStateService
  ) {}

  public startNewRequest(): void {
    this.whiteLabelService.whiteLabelConfig$
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe(customerConfigurationDto => {
        const flow = this.appStateService.initialiseFlow(
          customerConfigurationDto
        );
        this.appStateService.initialiseModule(customerConfigurationDto);
        if (
          this.appStateService.areMultipleFlowsEnabled(customerConfigurationDto)
        ) {
          this.routeService.navigateToFlowSelectionPage();
        } else if (
          flow &&
          this.appStateService.getAvailableModuleCount(
            flow,
            customerConfigurationDto
          ) === 1
        ) {
          this.routeService.navigateToAddressPage();
        } else {
          this.routeService.navigateToModuleSelectionPage();
        }
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
