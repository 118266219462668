import { NgModule } from '@angular/core';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { CommissioningElectricityOptionsComponent } from './commissioning-electricity-options/commissioning-electricity-options.component';
import { CommissioningElectricityContactComponent } from './commissioning-electricity-contact/commissioning-electricity-contact.component';
import { CommonStepsModule } from '../common-steps/common-steps.module';
import { CommissioningElectricityPropertyTypeComponent } from '@app/modules/commissioning-electricity/main-power-supply/commissioning-electricity-property-type/commissioning-electricity-property-type.component';
import { CommissioningElectricityInformationComponent } from '@app/modules/commissioning-electricity/main-power-supply/commissioning-electricity-information/commissioning-electricity-information.component';
import { CommissioningElectricitySystemsComponent } from '@app/modules/commissioning-electricity/customer-facility/commissioning-electricity-systems/commissioning-electricity-systems.component';
import { CommissioningElectricitySimpleMeasurementDetailsComponent } from './customer-facility/commissioning-electricity-simple-measurement-details/commissioning-electricity-simple-measurement-details.component';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { CommissioningElectricityMeasurementDetailsComponent } from '@app/modules/commissioning-electricity/commissioning-electricity-measurement-details/commissioning-electricity-measurement-details.component';
import { ExceedsLimitPipe } from '@app/shared/pipes/exceeds-limit.pipe';
import { MainPowerSupplyTypeComponent } from '@app/modules/commissioning-electricity/main-power-supply/main-power-supply-type/main-power-supply-type.component';
import { PowerIncreaseDetailsComponent } from '@app/modules/commissioning-electricity/main-power-supply/power-increase-details/power-increase-details.component';

@NgModule({
  declarations: [
    CommissioningElectricityOptionsComponent,
    CommissioningElectricityPropertyTypeComponent,
    CommissioningElectricityInformationComponent,
    CommissioningElectricityContactComponent,
    CommissioningElectricitySystemsComponent,
    CommissioningElectricityMeasurementDetailsComponent,
    CommissioningElectricitySimpleMeasurementDetailsComponent,
    MainPowerSupplyTypeComponent,
    PowerIncreaseDetailsComponent,
  ],
  imports: [
    SharedModule,
    CommonStepsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    RequiredSuffixDirective,
    ExceedsLimitPipe,
  ],
})
export class CommissioningElectricityModule {}
