import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-heat-requirements',
  templateUrl: './heat-requirements.component.html',
})
export class HeatRequirementsComponent implements OnInit, OnDestroy {
  public heatRequirementsForm!: FormGroup;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private routeService: RouteService,
    private formBuilder: FormBuilder,
    private appStateService: AppStateService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    this.heatRequirementsForm = this.formBuilder.group({
      heatOutputDemand: [null, [Validators.required, Validators.min(0)]],
      otherConsumers: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      otherConsumersPower: [null, [Validators.min(0)]],
      notes: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.heatRequirementDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(heatRequirementDetails =>
        this.heatRequirementsForm.patchValue(heatRequirementDetails)
      );
  }

  private watchForm(): void {
    this.heatRequirementsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(heatRequirementDetails =>
        this.appStateService.updateFormData({
          heatRequirementDetails,
        })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.heatRequirementsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.heatRequirementsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
