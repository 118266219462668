import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'hasError',
  standalone: true,
  pure: false,
})
export class HasErrorPipe implements PipeTransform {
  transform(
    formGroup: FormGroup,
    controlName: string,
    errorName: string
  ): boolean {
    return !!formGroup.get(controlName)?.hasError(errorName);
  }
}
