import { BUILDING_TYPE } from '@app/models/registration-form';

export interface PropertyDetails {
  icon: string;
  label: string;
  type: BUILDING_TYPE;
}

export const propertyDetailsList: PropertyDetails[] = [
  {
    icon: 'home',
    label: 'SHARED.BUILDING_TYPES.HOUSE',
    type: BUILDING_TYPE.HOUSE,
  },
  {
    icon: 'other_houses',
    label: 'SHARED.BUILDING_TYPES.MULTI_FAMILY_HOUSE',
    type: BUILDING_TYPE.MULTI_FAMILY_HOUSE,
  },
  {
    icon: 'apartment',
    label: 'SHARED.BUILDING_TYPES.INDUSTRY',
    type: BUILDING_TYPE.INDUSTRY,
  },
  {
    icon: 'garage_home',
    label: 'SHARED.BUILDING_TYPES.MIXED',
    type: BUILDING_TYPE.MIXED,
  },
];
