import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@app/models/registration-form';
import { filter, map, Observable, switchMap } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { WhiteLabelService } from './white-label.service';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { modulesWithResponsibilityCheck } from '@app/constants/modules-with-responsibility-check';
import { HeaderUtils } from '@app/services/header-utils';

export interface ResponsibilityCheckResponse {
  address: Address;
  customerConfigurationId: string;
  responsible: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ResponsibilityCheckService {
  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService,
    private whiteLabelService: WhiteLabelService
  ) {}

  public hasResponsibilityCheck(module: FLOW_MODULE | null): boolean {
    return module ? modulesWithResponsibilityCheck.includes(module) : false;
  }

  public verifyAddress(
    address: Address,
    module: FLOW_MODULE | null,
    captchaToken: string | null
  ): Observable<ResponsibilityCheckResponse> {
    return this.whiteLabelService.whiteLabelConfig$.pipe(
      map(whiteLabelConfig => whiteLabelConfig?.id),
      filter(Boolean),
      switchMap(customerConfigurationId => {
        const headers = HeaderUtils.toHttpHeaders(
          'Captcha-Token',
          captchaToken
        );

        return this.httpClient.put<ResponsibilityCheckResponse>(
          `${this.environment.apiUrl}/responsibility-check`,
          { address, customerConfigurationId, module },
          { headers }
        );
      })
    );
  }
}
