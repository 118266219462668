<app-form-card
  svgIcon="heat"
  cardTitle="HEAT.HEAT_REQUIREMENTS.HEADING"
  description="HEAT.HEAT_REQUIREMENTS.DESCRIPTION">
  <div class="space"></div>
  <form [formGroup]="heatRequirementsForm">
    <h2 class="full-width">
      {{ 'HEAT.HEAT_REQUIREMENTS.OUTPUT_DEMAND' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'HEAT.HEAT_REQUIREMENTS.OUTPUT_DEMAND' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="heatOutputDemand"
          formControlName="heatOutputDemand"
          min="0" />
        <mat-error appErrorMessages="heatOutputDemand"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="full-width">
      {{ 'HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <textarea
          #otherConsumers
          matInput
          [placeholder]="
            'HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS_PLACEHOLDER' | translate
          "
          cols="50"
          rows="2"
          [maxlength]="maxLength"
          formControlName="otherConsumers"></textarea>
        <mat-hint align="end"
          >{{ otherConsumers.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="otherConsumers"></mat-error>
      </mat-form-field>
    </div>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'HEAT.HEAT_REQUIREMENTS.OTHER_CONSUMERS_POWER' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="otherConsumersPower"
          formControlName="otherConsumersPower"
          min="0" />
        <mat-error appErrorMessages="otherConsumersPower"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="full-width">
      {{ 'HEAT.HEAT_REQUIREMENTS.NOTES' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <textarea
          #notes
          matInput
          cols="50"
          rows="2"
          [maxlength]="maxLength"
          formControlName="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: heatRequirementsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
