<app-form-card
  titleIcon="category"
  cardTitle="COMMISSIONING_ELECTRICITY.OPTIONS.HEADING"
  dataTestId="commissioning-electricity-options-heading">
  <h2 class="full-width">
    {{ 'COMMISSIONING_ELECTRICITY.OPTIONS.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let option of optionsList"
      class="selection-card"
      [svgIcon]="option.svgIcon"
      [label]="option.label"
      [selected]="selectedOption === option.type"
      (click)="selectOption(option.type)"
      [attr.data-testid]="option.type">
    </app-selection-card>
    <mat-error *ngIf="!selectedOption && allTouched">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <ng-container *ngIf="selectedOption">
    <div class="space"></div>
    <app-hint>
      <span>{{
        'COMMISSIONING_ELECTRICITY.OPTIONS.INFO.' + selectedOption | translate
      }}</span>
    </app-hint>
  </ng-container>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: optionsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
