import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FORM_TYPE, GAS_OPTION } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { Subject, filter, map, take, takeUntil } from 'rxjs';
import { CustomValidators } from '@app/shared/validators/custom-validators';

interface GasOption {
  icon: string;
  label: string;
  type: GAS_OPTION;
}

@Component({
  selector: 'app-gas-options',
  templateUrl: './gas-options.component.html',
  styleUrls: ['./gas-options.component.scss'],
})
export class GasOptionsComponent implements OnInit, OnDestroy {
  public readonly gasOptionsList: GasOption[] = [
    {
      icon: 'add_circle',
      label: 'GAS.GAS_OPTIONS.NEW.LABEL',
      type: GAS_OPTION.NEW,
    },
    {
      icon: 'settings',
      label: 'GAS.GAS_OPTIONS.CHANGE.LABEL',
      type: GAS_OPTION.CHANGE,
    },
    {
      icon: 'cancel',
      label: 'GAS.GAS_OPTIONS.DETACH.LABEL',
      type: GAS_OPTION.DETACH,
    },
  ];

  public gasOptions = GAS_OPTION;
  public selectedOption?: GAS_OPTION;
  private onDestroy$: Subject<void> = new Subject();
  public gasOptionsForm!: FormGroup;
  public gasOptionControl!: FormControl;
  public changePowerControl?: FormControl;
  public relocateControl?: FormControl;
  public allTouched = false;

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.setFormTypeAsGas();
    this.createGasOptionsForm();
    this.updateForm();
    this.watchForm();
  }

  private setFormTypeAsGas(): void {
    // required for backend mapping
    this.appStateService.updateFormData({
      formType: FORM_TYPE.GAS,
    });
  }

  private createGasOptionsForm(): void {
    this.gasOptionsForm = this.formBuilder.group({
      gasOption: [null, Validators.required],
    });
    this.gasOptionControl = this.gasOptionsForm.get('gasOption') as FormControl;
    this.watchGasOption();
  }

  private watchGasOption(): void {
    this.gasOptionControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(gasOption => {
        this.removeControls();
        if (gasOption === GAS_OPTION.CHANGE) {
          this.gasOptionsForm.addControl('changePower', new FormControl(null));
          this.gasOptionsForm.addControl('relocate', new FormControl(null));
          this.watchRelocateAndPowerChange();
        } else if (gasOption === GAS_OPTION.DETACH) {
          this.gasOptionsForm.addControl(
            'partialDeconstructionDesired',
            new FormControl(null, Validators.required)
          );
          this.gasOptionsForm.addControl(
            'meterNumber',
            new FormControl(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
        }
        this.gasOptionsForm.updateValueAndValidity();
      });
  }

  private removeControls(): void {
    this.gasOptionsForm.removeControl('relocate');
    this.gasOptionsForm.removeControl('partialDeconstructionDesired');
    this.gasOptionsForm.removeControl('ditchLength');
    this.gasOptionsForm.removeControl('meterNumber');
    this.gasOptionsForm.removeControl('changePower');
    this.gasOptionsForm.removeControl('plannedPowerDemand');
    this.gasOptionsForm.removeControl('currentPowerDemand');
  }

  private watchRelocateAndPowerChange(): void {
    this.changePowerControl = this.gasOptionsForm.get(
      'changePower'
    ) as FormControl;
    this.relocateControl = this.gasOptionsForm.get('relocate') as FormControl;

    this.changePowerControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(changePower => {
        if (changePower) {
          this.gasOptionsForm.addControl(
            'currentPowerDemand',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
          this.gasOptionsForm.addControl(
            'plannedPowerDemand',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
        } else {
          this.gasOptionsForm.removeControl('currentPowerDemand');
          this.gasOptionsForm.removeControl('plannedPowerDemand');
        }
      });

    this.relocateControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(relocate => {
        if (relocate) {
          this.gasOptionsForm.addControl(
            'ditchLength',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
        } else {
          this.gasOptionsForm.removeControl('ditchLength');
        }
      });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.gasOptions),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(gasOptions => {
        this.gasOptionsForm.patchValue(gasOptions);
        this.selectedOption = gasOptions.gasOption;
      });
  }

  private watchForm(): void {
    this.gasOptionsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(gasOptions => {
        this.appStateService.updateFormData({
          gasOptions,
        });
      });
  }

  public selectType(selectedOption: GAS_OPTION): void {
    if (this.gasOptionsForm.get('gasOption')?.value !== selectedOption) {
      this.gasOptionsForm.get('gasOption')?.setValue(selectedOption);
      this.selectedOption = selectedOption;
      this.gasOptionsForm.markAsUntouched();
      this.allTouched = false;
    }
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.gasOptionsForm.valid) {
      // in change flow, if relocate is true then gasPropertyDetails and gasPowerRequirement data, if any, should be deleted
      if (
        this.selectedOption === GAS_OPTION.CHANGE &&
        !this.relocateControl?.value
      ) {
        this.appStateService.updateFormData({
          gasBuildingDetails: undefined,
          gasPowerRequirement: undefined,
        });
      }
      this.routeService.navigateToNextStep();
    } else {
      this.gasOptionsForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
