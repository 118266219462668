import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BUILDING_STRUCTURE,
  BUILDING_TYPE,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { PropertyDetails, propertyDetailsList } from '@app/shared/shared-data';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-water-property-type',
  templateUrl: './water-property-type.component.html',
})
export class WaterPropertyTypeComponent implements OnInit, OnDestroy {
  public readonly propertyDetailsList: PropertyDetails[] = propertyDetailsList;
  public buildingStructures = Object.keys(BUILDING_STRUCTURE);
  public selectedPropertyType: BUILDING_TYPE | undefined;
  public allTouched = false;
  public propertyDetailsForm!: FormGroup;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createPropertyTypeForm();
    this.updateForm();
    this.watchForm();
  }

  private createPropertyTypeForm(): void {
    this.propertyDetailsForm = this.formBuilder.group({
      buildingType: [null, Validators.required],
      buildingStructure: [null, Validators.required],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.waterBuildingDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(waterBuildingDetails => {
        this.propertyDetailsForm.patchValue(waterBuildingDetails);
        this.selectedPropertyType = waterBuildingDetails.buildingType;
      });
  }

  private watchForm(): void {
    this.propertyDetailsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(waterBuildingDetails =>
        this.appStateService.updateFormData({
          waterBuildingDetails,
        })
      );
  }

  public selectPropertyType(selectedOption: BUILDING_TYPE): void {
    this.propertyDetailsForm.get('buildingType')?.setValue(selectedOption);
    this.selectedPropertyType = selectedOption;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.propertyDetailsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.propertyDetailsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
