import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { DateHelperService } from '@app/services/date-helper.service';
import {
  Subject,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
} from 'rxjs';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { GRID_CONNECTION_TYPE } from '@app/models/registration-form';

@Component({
  templateUrl: './construction-grid-connection-details.component.html',
  styleUrls: ['./construction-grid-connection-details.component.scss'],
})
export class ConstructionGridConnectionDetailsComponent
  implements OnInit, OnDestroy
{
  public constructionGridConnectionForm!: FormGroup;
  public allTouched = false;
  public errorMessageForInvalidDateFormat =
    this.dateHelperService.getErrorMessageForInvalidDateFormat();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService,
    private dateHelperService: DateHelperService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    this.constructionGridConnectionForm = this.formBuilder.group({
      systemPower: [null, [Validators.required, Validators.min(0)]],
      devicesRequireApproval: [null, Validators.required],
      desiredInstallationDate: [null, Validators.required],
      desiredDeconstructionDate: null,
    });
    this.watchDevicesRequireApproval();
  }

  private watchDevicesRequireApproval(): void {
    this.constructionGridConnectionForm
      .get('devicesRequireApproval')
      ?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((devicesRequireApproval: boolean) => {
        if (devicesRequireApproval) {
          this.constructionGridConnectionForm.addControl(
            'requiredDevices',
            new FormArray([this.buildRequiredDeviceFormControl()])
          );
        } else {
          this.constructionGridConnectionForm.removeControl('requiredDevices');
        }
      });
  }

  get requiredDevices(): FormArray {
    return this.constructionGridConnectionForm.get(
      'requiredDevices'
    ) as FormArray;
  }

  private buildRequiredDeviceFormControl() {
    return new FormControl(null, {
      updateOn: 'blur',
      validators: [
        CustomValidators.trimValidator,
        CustomValidators.shortText,
        Validators.required,
      ],
    });
  }

  public addMoreDevice(): void {
    this.requiredDevices.push(this.buildRequiredDeviceFormControl());
  }

  public removeDevice(index: number): void {
    this.requiredDevices.removeAt(index);
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.constructionGridConnectionDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(constructionElectricityInformation => {
        this.constructionGridConnectionForm.patchValue(
          constructionElectricityInformation
        );
        if (constructionElectricityInformation.requiredDevices?.length) {
          constructionElectricityInformation.requiredDevices.forEach(
            requiredDevice => {
              if (!this.requiredDevices.value?.includes(requiredDevice)) {
                this.requiredDevices.push(
                  new FormControl(requiredDevice, Validators.required)
                );
              }
            }
          );
        }
      });
  }

  private watchForm(): void {
    this.constructionGridConnectionForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(constructionElectricityInfo =>
        this.appStateService.updateFormData({
          constructionGridConnectionDetails: {
            ...constructionElectricityInfo,
            type: GRID_CONNECTION_TYPE.CONSTRUCTION,
          },
        })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.constructionGridConnectionForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.constructionGridConnectionForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
