import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LOAD_MANAGEMENT } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-charging-device-more-options',
  templateUrl: './charging-device-more-options.component.html',
})
export class ChargingDeviceMoreOptionsComponent implements OnInit, OnDestroy {
  public moreOptionsForm!: FormGroup;
  public allTouched = false;
  public loadManagement = LOAD_MANAGEMENT;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createMoreOptionsForm();
    this.updateForm();
    this.watchForm();
  }

  private createMoreOptionsForm(): void {
    this.moreOptionsForm = this.formBuilder.group({
      powerPerChargingDevice: [null, [Validators.required, Validators.min(0)]],
      maximumGridPower: [null, [Validators.required, Validators.min(0)]],
      loadManagement: [null, [Validators.required]],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.chargingDeviceMoreOptions),
        filter(Boolean),
        take(1)
      )
      .subscribe(chargingDeviceMoreOptions =>
        this.moreOptionsForm.patchValue(chargingDeviceMoreOptions)
      );
  }

  private watchForm(): void {
    this.moreOptionsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(chargingDeviceMoreOptions =>
        this.appStateService.updateFormData({
          chargingDeviceMoreOptions,
        })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.moreOptionsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.moreOptionsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
