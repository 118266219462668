<app-form-card titleIcon="home" cardTitle="GAS.GAS_PROPERTY_TYPE.HEADING">
  <h2 class="full-width">
    {{ 'GAS.GAS_PROPERTY_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let property of propertyDetailsList"
      [icon]="property.icon"
      [label]="property.label"
      [selected]="selectedPropertyType === property.type"
      (click)="selectPropertyType(property.type)">
    </app-selection-card>

    <mat-error *ngIf="allTouched && !selectedPropertyType">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <div class="space"></div>

  <form [formGroup]="propertyDetailsForm">
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'SHARED.BUILDING_STRUCTURE.LABEL' | translate
        }}</mat-label>
        <mat-select formControlName="buildingStructure">
          <mat-option
            *ngFor="let structureType of buildingStructures"
            [value]="structureType">
            {{ 'SHARED.BUILDING_STRUCTURE.' + structureType | translate }}
          </mat-option>
        </mat-select>
        <mat-error appErrorMessages="buildingStructure"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.LABEL' | translate
        }}</mat-label>
        <mat-select formControlName="currentEnergySource">
          <mat-option
            *ngFor="let energySource of currentEnergySources"
            [value]="energySource">
            {{
              'GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.' + energySource
                | translate
            }}
          </mat-option>
        </mat-select>
        <mat-error appErrorMessages="currentEnergySource"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'SHARED.HOUSE_INLET.LABEL' | translate }}</mat-label>
        <mat-select formControlName="houseInlet">
          <mat-option *ngFor="let inlet of houseInlets" [value]="inlet">
            {{ 'SHARED.HOUSE_INLET.' + inlet | translate }}
          </mat-option>
        </mat-select>
        <mat-error appErrorMessages="houseInlet"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyDetailsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
