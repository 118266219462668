<app-summary-section
  heading="CONTACT.CUSTOMER_DETAILS"
  [stepRoute]="contactRoute">
  <p class="section-heading">
    {{ 'CONTACT.CONTACT_DETAILS' | translate }}
  </p>

  <p class="section-fields-container">
    <app-contact-details
      [contactDetails]="contact.contactDetails"></app-contact-details>
  </p>

  <ng-container>
    <p class="section-heading">
      {{ 'CONTACT.REQUESTER_ADDRESS' | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        label="CONTACT.CONNECTION_ADDRESS_DESCRIPTION"
        [value]="
          (contact.differentAddress ? 'COMMON.NO' : 'COMMON.YES') | translate
        ">
      </app-summary-section-field>
      <ng-container
        *ngIf="contact.differentAddress && contact.differentRequesterAddress">
        <app-address
          [address]="contact.differentRequesterAddress"></app-address>
      </ng-container>
    </div>
  </ng-container>

  <ng-container>
    <p class="section-heading">
      {{ 'CONTACT.CONTACT_DETAILS' | translate }}
    </p>
    <div class="section-fields-container">
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.GRID_OPERATOR"
        [value]="contact.gridOperator">
      </app-summary-section-field>
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.ID_NUMBER"
        [value]="contact.idNumber">
      </app-summary-section-field>
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.ELECTRICIAN_NAME"
        [value]="contact.electricianName">
      </app-summary-section-field>
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.ELECTRICIAN_EMAIL"
        [value]="contact.electricianEmail">
      </app-summary-section-field>
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.PHONE"
        [value]="contact.phone">
      </app-summary-section-field>
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.LOCATION"
        [value]="contact.location">
      </app-summary-section-field>
      <app-summary-section-field
        label="COMMISSIONING_ELECTRICITY.CONTACT.DATE"
        id="commissioning-electricity-contact-date"
        [value]="
          (contact.date | date: 'shortDate' : '' : currentLanguageCulture) ||
          '-'
        ">
      </app-summary-section-field>
    </div>
  </ng-container>
</app-summary-section>
