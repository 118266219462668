<app-form-card
  titleIcon="electric_bolt"
  cardTitle="OTHER.HEADING"
  description="OTHER.DESCRIPTION"
  dataTestId="other-system-information-heading">
  <form [formGroup]="otherProductForm">
    <div class="space"></div>

    <h2>{{ ('OTHER.TYPE_OF_REQUEST' | translate) + ' *' }}</h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <input
          matInput
          type="text"
          name="typeOfRequest"
          formControlName="typeOfRequest"
          [placeholder]="'OTHER.TYPE_OF_REQUEST_PLACEHOLDER' | translate"
          data-testid="type-of-request" />
        <mat-error appErrorMessages="typeOfRequest"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2>
      {{ 'OTHER.SYSTEM_POWER' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'OTHER.SYSTEM_POWER_LABEL' | translate }}</mat-label>
        <input
          matInput
          type="number"
          name="systemPower"
          formControlName="systemPower"
          min="0" />
        <mat-error appErrorMessages="systemPower"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      {{ 'OTHER.METER_NUMBER' | translate }}
      <app-info-tooltip tooltipText="OTHER.METER_NUMBER_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'OTHER.METER_NUMBER' | translate }}</mat-label>
        <input
          matInput
          name="meterNumber"
          type="text"
          formControlName="meterNumber" />
        <mat-error appErrorMessages="meterNumber"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2>
      {{ 'OTHER.ANYTHING_ELSE' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <mat-label>{{ 'OTHER.NOTES' | translate }}</mat-label>
        <textarea
          #notes
          matInput
          cols="50"
          rows="6"
          [maxlength]="maxLength"
          formControlName="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: otherProductForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
