import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss'],
})
export class SelectionCardComponent {
  @Input() public icon?: string;
  @Input() public svgIcon?: string;
  @Input() public symbolOutlinedIcon? = true;
  @Input() public label!: string;
  @Input() public selected = false;
  @Input() public showCheckbox = false;
  @Input() public vertical = true;
  public currentLang: string;
  constructor(private translateService: TranslateService) {
    this.currentLang = this.translateService.currentLang;
  }
}
