import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-summary-building-type',
  templateUrl: './summary-building-type.component.html',
  styleUrls: ['./summary-building-type.component.scss'],
})
export class SummaryBuildingTypeComponent {
  @Input({ required: true }) buildingType?: string;
}
