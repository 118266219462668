<mat-icon
  aria-hidden="false"
  aria-label="Info icon"
  fontIcon="info"
  [matTooltip]="tooltipText | translate"
  (mouseenter)="$event.stopImmediatePropagation()"
  (mouseleave)="$event.stopImmediatePropagation()"
  #tooltip="matTooltip"
  (click)="tooltip.toggle()"
  class="tooltip-info-icon material-icons-outlined">
</mat-icon>
