<app-form-card
  titleIcon="electric_bolt"
  cardTitle="PV_FACILITY_INFORMATION.HEADING"
  description="PV_FACILITY_INFORMATION.DESCRIPTION"
  dataTestId="pv-facility-information-heading">
  <div class="space"></div>

  <form [formGroup]="pvFacilityInformationForm">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="operating-mode-radio-group-label">{{
            ('PV_FACILITY_INFORMATION.OPERATING_MODE.LABEL' | translate) + ' *'
          }}</label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.OPERATING_MODE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="operatingMode"
          aria-labelledby="operating-mode-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of operatingModeOptions; index as i"
            [value]="option"
            [attr.data-testid]="option">
            {{ 'SHARED.PV.OPERATING_MODE.' + option | translate }}
          </mat-radio-button>
          <mat-error appErrorMessages="operatingMode" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label id="network-and-system-protection-type-radio-group-label">{{
            ('PV_FACILITY_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_TYPE.LABEL'
              | translate) + ' *'
          }}</label>
          <app-info-tooltip
            tooltipText="PV_FACILITY_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_TYPE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="networkAndSystemProtectionType"
          aria-labelledby="network-and-system-protection-type-radio-group-label"
          class="input-container">
          <mat-radio-button
            *ngFor="let option of networkAndSystemProtectionTypeOptions"
            [value]="option"
            [attr.data-testid]="option">
            {{
              'PV_FACILITY_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_TYPE.OPTION.' +
                option | translate
            }}
          </mat-radio-button>
          <mat-error
            appErrorMessages="networkAndSystemProtectionType"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <h2>
      {{
        'PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.SUBHEADING'
          | translate
      }}
    </h2>
    <div class="input-container">
      <div class="small-input-field-container">
        <mat-form-field appearance="outline" class="small-input-field">
          <mat-label>{{
            'PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.MANUFACTURER'
              | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            name="manufacturer"
            formControlName="manufacturer"
            data-testid="manufacturer" />
          <mat-error appErrorMessages="manufacturer"></mat-error>
        </mat-form-field>
      </div>

      <div class="small-input-field-container">
        <mat-form-field appearance="outline" class="small-input-field">
          <mat-label>{{
            'PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.TYPE' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            name="type"
            formControlName="type"
            data-testid="type" />
          <mat-error appErrorMessages="type"></mat-error>
        </mat-form-field>
      </div>

      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label
            [innerHTML]="
              'PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.MAX_APPARENT_POWER'
                | translate
            "></mat-label>
          <input
            matInput
            type="number"
            name="maxApparentPower"
            formControlName="maxApparentPower"
            min="0"
            data-testid="max-apparent-power" />
          <mat-error appErrorMessages="maxApparentPower"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.MAX_APPARENT_POWER_TOOLTIP">
        </app-info-tooltip>
      </div>
    </div>

    <div class="space"></div>

    <h2>
      {{
        'PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.SUBHEADING' | translate
      }}
    </h2>
    <div class="input-container">
      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label>{{
            'PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.INVERTER_COUNT'
              | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            name="inverterCount"
            formControlName="inverterCount"
            min="0"
            data-testid="inverter-count" />
          <mat-error appErrorMessages="inverterCount"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.INVERTER_COUNT_TOOLTIP">
        </app-info-tooltip>
      </div>

      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label
            [innerHTML]="
              'PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.MAX_APPARENT_POWER_SUM'
                | translate
            "></mat-label>
          <input
            matInput
            type="number"
            name="maxApparentPowerSum"
            formControlName="maxApparentPowerSum"
            min="0"
            data-testid="max-apparent-power-sum" />
          <mat-error appErrorMessages="maxApparentPowerSum"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.MAX_APPARENT_POWER_SUM_TOOLTIP">
        </app-info-tooltip>
      </div>

      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label>{{
            'PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.PV_MODULE_COUNT'
              | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            name="pvModuleCount"
            formControlName="pvModuleCount"
            min="0"
            data-testid="pv-module-count" />
          <mat-error appErrorMessages="pvModuleCount"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.PV_MODULE_COUNT_TOOLTIP">
        </app-info-tooltip>
      </div>

      <div class="input-field-with-postfix-container">
        <mat-form-field appearance="outline" class="input-field-with-postfix">
          <mat-label>{{
            'PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.POWER_PER_PV_MODULE'
              | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            name="powerPerPVModule"
            formControlName="powerPerPVModule"
            min="0"
            data-testid="power-per-pv-module" />
          <mat-error appErrorMessages="powerPerPVModule"></mat-error>
        </mat-form-field>
        <app-info-tooltip
          class="input-field-postfix"
          tooltipText="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.POWER_PER_PV_MODULE_TOOLTIP">
        </app-info-tooltip>
      </div>
    </div>

    <div class="space"></div>

    <h2>
      <label id="meter-required-radio-group-label">
        {{
          ('PV_FACILITY_INFORMATION.METER_REQUIRED' | translate) + ' *'
        }}</label
      >
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="meterRequired"
      aria-labelledby="meter-required-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="meter-required">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-meter-required">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="meterRequired" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <div
      *ngIf="
        (pvFacilityInformationForm | formLookup: 'meterRequired') === false
      ">
      <h2 class="tooltip-heading-label">
        {{ 'PV_FACILITY_INFORMATION.METER_NUMBER' | translate }}
        <app-info-tooltip
          tooltipText="PV_FACILITY_INFORMATION.METER_NUMBER_TOOLTIP">
        </app-info-tooltip>
      </h2>
      <div class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{
            'PV_FACILITY_INFORMATION.METER_NUMBER' | translate
          }}</mat-label>
          <input matInput name="meterNumber" formControlName="meterNumber" />
          <mat-error appErrorMessages="meterNumber"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <h2 class="tooltip-heading-label">
      <label id="form-of-feed-in-sale-radio-group-label">{{
        ('PV_FACILITY_INFORMATION.FORM_OF_FEED_IN_SALE.LABEL' | translate) +
          ' *'
      }}</label>
      <app-info-tooltip
        tooltipText="PV_FACILITY_INFORMATION.FORM_OF_FEED_IN_SALE.TOOLTIP">
      </app-info-tooltip>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="formOfFeedInSale"
      aria-labelledby="form-of-feed-in-sale-radio-group-label"
      class="input-container">
      <mat-radio-button
        *ngFor="let option of formOfFeedInSaleOptions"
        [value]="option"
        [attr.data-testid]="option">
        {{ 'SHARED.PV.FORM_OF_FEED_IN_SALE.' + option | translate }}
      </mat-radio-button>
      <mat-error appErrorMessages="formOfFeedInSale" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <h2>
      <label id="storage-planned-radio-group-label">
        {{
          ('PV_FACILITY_INFORMATION.STORAGE_PLANNED_LABEL' | translate) + ' *'
        }}
      </label>
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="storagePlanned"
      aria-labelledby="storage-planned-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="storage-planned">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-storage-planned">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        appErrorMessages="storagePlanned"
        [hideError]="!allTouched"></mat-error>
    </mat-radio-group>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched | nextButtonDisabled: pvFacilityInformationForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
