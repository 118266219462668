import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { BUILDING_TYPE } from '@app/models/registration-form';

interface PropertyTypeDetails {
  icon: string;
  label: string;
  type: BUILDING_TYPE;
}

@Component({
  selector: 'app-charging-device-property-type',
  templateUrl: './charging-device-property-type.component.html',
})
export class ChargingDevicePropertyTypeComponent implements OnInit, OnDestroy {
  public selectedPropertyType: BUILDING_TYPE | undefined;
  public allTouched = false;
  public propertyTypeFormControl!: FormControl;
  public readonly propertyTypeList: PropertyTypeDetails[] = [
    {
      icon: 'home',
      label: 'SHARED.BUILDING_TYPES.HOUSE',
      type: BUILDING_TYPE.HOUSE,
    },
    {
      icon: 'other_houses',
      label: 'SHARED.BUILDING_TYPES.MULTI_FAMILY_HOUSE',
      type: BUILDING_TYPE.MULTI_FAMILY_HOUSE,
    },
    {
      icon: 'apartment',
      label: 'SHARED.BUILDING_TYPES.INDUSTRY',
      type: BUILDING_TYPE.INDUSTRY,
    },
    {
      icon: 'more_horiz',
      label: 'SHARED.BUILDING_TYPES.MISCELLANEOUS',
      type: BUILDING_TYPE.MIXED,
    },
  ];
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.propertyTypeFormControl = new FormControl('', Validators.required);
    this.updateForm();
    this.watchForm();
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.chargingDevicePropertyType),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(chargingDevicePropertyType => {
        this.propertyTypeFormControl.setValue(chargingDevicePropertyType);
        this.selectedPropertyType = chargingDevicePropertyType;
      });
  }

  private watchForm(): void {
    this.propertyTypeFormControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(chargingDevicePropertyType =>
        this.appStateService.updateFormData({
          chargingDevicePropertyType,
        })
      );
  }

  public selectPropertyType(selectedOption: BUILDING_TYPE): void {
    this.propertyTypeFormControl.setValue(selectedOption);
    this.selectedPropertyType = selectedOption;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.propertyTypeFormControl.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.propertyTypeFormControl.markAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
