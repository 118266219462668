<app-form-card
  titleIcon="category"
  cardTitle="CHARGING_DEVICE_MORE_OPTIONS.HEADING"
  dataTestId="charging-device-more-options-heading">
  <form [formGroup]="moreOptionsForm">
    <h2 class="tooltip-heading-label">
      {{
        ('CHARGING_DEVICE_MORE_OPTIONS.POWER_PER_CHARGING_DEVICE' | translate) +
          ' *'
      }}
      <app-info-tooltip
        tooltipText="CHARGING_DEVICE_MORE_OPTIONS.POWER_PER_CHARGING_DEVICE_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <div class="input-container">
      <mat-form-field
        appearance="outline"
        class="input-field"
        hideRequiredMarker="true">
        <input
          matInput
          type="number"
          name="powerPerChargingDevice"
          formControlName="powerPerChargingDevice"
          min="0"
          data-testid="power-per-charging-device" />
        <mat-error appErrorMessages="powerPerChargingDevice"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      {{
        ('CHARGING_DEVICE_MORE_OPTIONS.MAXIMUM_GRID_POWER' | translate) + ' *'
      }}
      <app-info-tooltip
        tooltipText="CHARGING_DEVICE_MORE_OPTIONS.MAXIMUM_GRID_POWER_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <div class="input-container">
      <mat-form-field
        appearance="outline"
        class="input-field"
        hideRequiredMarker="true">
        <input
          matInput
          type="number"
          name="maximumGridPower"
          formControlName="maximumGridPower"
          min="0"
          data-testid="maximum-grid-power" />
        <mat-error appErrorMessages="maximumGridPower"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      {{
        ('CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.LABEL' | translate) +
          ' *'
      }}
      <app-info-tooltip
        tooltipText="CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.TOOLTIP">
      </app-info-tooltip>
    </h2>

    <mat-radio-group
      color="primary"
      formControlName="loadManagement"
      class="input-container">
      <div>
        <mat-radio-button
          [value]="loadManagement.DYNAMIC"
          data-testid="dynamic-load-management">
          {{
            'CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.OPTION.DYNAMIC'
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button
          [value]="loadManagement.STATIC"
          data-testid="static-load-management">
          {{
            'CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.OPTION.STATIC'
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button
          [value]="loadManagement.NO"
          data-testid="no-load-management">
          {{
            'CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.OPTION.NO' | translate
          }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="loadManagement" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <div class="space"></div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: moreOptionsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
