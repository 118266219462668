import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'formLookup',
  standalone: true,
  pure: false,
})
export class FormLookupPipe implements PipeTransform {
  transform(
    value: AbstractControl,
    ...selectors: string[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    return value.get(selectors)?.value;
  }
}
