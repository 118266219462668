import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  FASTENING_TYPE,
  MEASUREMENT_DEVICE_CHANGE_TYPE,
  MEASUREMENT_TYPE,
  MeasurementDetails,
  METER_LOCATION,
} from '@app/models/commissioning-mapped-data.interface';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { BehaviorSubject, filter, map, Subject, take, takeUntil } from 'rxjs';
import { CustomValidators } from '@app/shared/validators/custom-validators';

interface MeasurementType {
  label: string;
  value: MEASUREMENT_TYPE;
}

interface FormMeasurementDetails {
  meterNumber?: string;
  changeType: MEASUREMENT_DEVICE_CHANGE_TYPE;
  measurementTypes: boolean[];
  locationType: METER_LOCATION;
  otherLocation?: string;
  fasteningType: FASTENING_TYPE;
  converterSize: number;
  basicResponsible: boolean;
  otherOperator?: string;
}

@Component({
  selector: 'app-commissioning-electricity-measurement-details',
  templateUrl: './commissioning-electricity-measurement-details.component.html',
  styleUrls: ['./commissioning-electricity-measurement-details.component.scss'],
})
export class CommissioningElectricityMeasurementDetailsComponent
  implements OnInit, OnDestroy
{
  public measurementTypes: MeasurementType[] = Object.values(
    MEASUREMENT_TYPE
  ).map(type => {
    return {
      label: `COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.MEASUREMENT_TYPE.TYPES.${type}`,
      value: type,
    };
  });
  public meterLocation = METER_LOCATION;
  public measurementDeviceChangeType = MEASUREMENT_DEVICE_CHANGE_TYPE;
  public fasteningType = FASTENING_TYPE;
  public changeTypes = Object.keys(MEASUREMENT_DEVICE_CHANGE_TYPE);
  public locationTypes = Object.keys(METER_LOCATION);
  public measurementForm!: FormGroup;
  public measurementDetails?: FormArray;
  public allTouched = false;
  private measurementDetails$: BehaviorSubject<
    MeasurementDetails[] | undefined
  > = new BehaviorSubject<MeasurementDetails[] | undefined>(undefined);
  private onDestroy$: Subject<void> = new Subject();
  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.initFormCreation();
    this.updateForm();
  }

  private initFormCreation() {
    this.measurementDetails$.subscribe(details => {
      this.measurementDetails = this.formBuilder.array([]);
      this.measurementForm = this.formBuilder.group({
        measurementDetails: this.measurementDetails,
      });
      if (details) {
        details.forEach(detail =>
          this.measurementDetails?.push(this.createMeasurementFormItem(detail))
        );
      } else {
        this.measurementDetails?.push(this.createMeasurementFormItem());
      }
    });
  }
  public addDetails(): void {
    this.measurementDetails?.push(this.createMeasurementFormItem(), {
      emitEvent: true,
    });
  }

  public removeDetails(index: number): void {
    if (index > 0) {
      this.measurementDetails?.removeAt(index, { emitEvent: true });
    }
  }

  private createMeasurementFormItem(details?: MeasurementDetails): FormGroup {
    const element = this.formBuilder.group({
      meterNumber: [
        details?.meterNumber,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ], // cond. required =>changeType is one of [CHANGE, DISASSEMBLY]
      changeType: [details?.changeType, Validators.required],
      measurementTypes: this.buildMeasurementTypes(details),
      fasteningType: [details?.fasteningType, Validators.required],
      converterSize: [details?.converterSize, [Validators.min(0)]],
      locationType: [details?.meterLocation.locationType, Validators.required],
      otherLocation: [
        details?.meterLocation.otherLocation,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ], // cond. required => locationType = OTHER
      basicResponsible: [
        details?.measuringPointOperator.basicResponsible !== false,
        Validators.required,
      ],
      otherOperator: [
        details?.measuringPointOperator.otherOperator,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ], // cond. required basicResponsible = false
    });
    this.updateValidators(element);
    this.watchConditionalRequiredFields(element);
    return element;
  }

  private watchConditionalRequiredFields(detail: FormGroup) {
    detail.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.updateValidators(detail));
  }

  private updateValidators(measurementDetailsFormElem: FormGroup) {
    const changeType: MEASUREMENT_DEVICE_CHANGE_TYPE | undefined =
      measurementDetailsFormElem.get('changeType')
        ?.value as MEASUREMENT_DEVICE_CHANGE_TYPE;
    const locationType: METER_LOCATION | undefined =
      measurementDetailsFormElem.get('locationType')?.value as METER_LOCATION;
    const basicResponsible: boolean = measurementDetailsFormElem.get(
      'basicResponsible'
    )?.value as boolean;
    const enableControls: FormControl[] = [];
    const disableControls: FormControl[] = [];

    const meterNumberControl = measurementDetailsFormElem.get(
      'meterNumber'
    )! as FormControl;
    const meterLocationOtherLocationControl = measurementDetailsFormElem.get(
      'otherLocation'
    )! as FormControl;
    const measuringPointOperatorOtherOperatorControl =
      measurementDetailsFormElem.get('otherOperator')! as FormControl;

    switch (changeType) {
      case MEASUREMENT_DEVICE_CHANGE_TYPE.CHANGE:
      case MEASUREMENT_DEVICE_CHANGE_TYPE.DISASSEMBLY:
        enableControls.push(meterNumberControl);
        break;
      default:
        disableControls.push(meterNumberControl);
        break;
    }

    if (locationType === METER_LOCATION.OTHER) {
      enableControls.push(meterLocationOtherLocationControl);
    } else {
      disableControls.push(meterLocationOtherLocationControl);
    }

    if (basicResponsible) {
      disableControls.push(measuringPointOperatorOtherOperatorControl);
    } else {
      enableControls.push(measuringPointOperatorOtherOperatorControl);
    }
    disableControls.forEach(control => {
      if (control.enabled) {
        control.disable();
      }
    });
    enableControls.forEach(control => {
      if (!control.enabled) {
        control.enable();
      }
    });
  }

  private buildMeasurementTypes(
    details?: MeasurementDetails
  ): FormArray<FormControl> {
    const measurementTypesControls = this.measurementTypes.map(
      measurementType => {
        const selected = !!details?.measurementTypes.find(
          type => measurementType.value === type
        );
        return this.formBuilder.control(selected);
      }
    );
    return this.formBuilder.array(
      measurementTypesControls,
      CustomValidators.atLeastOneChecked()
    );
  }

  public getMeasurementTypesControl(i: number): FormArray | undefined {
    return this.measurementDetails?.controls[i]?.get(
      'measurementTypes'
    ) as FormArray;
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(
          ({ formData }) => formData.commissioningElectricityMeasurementDetails
        ),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(commissioningElectricityMeasurementDetails => {
        this.measurementDetails$.next(
          commissioningElectricityMeasurementDetails
        );
      });
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.measurementForm.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.measurementForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  private updateState() {
    this.appStateService.updateFormData({
      commissioningElectricityMeasurementDetails: (
        (this.measurementDetails?.getRawValue() ??
          []) as FormMeasurementDetails[]
      ).map(elem => this.mapToMeasurementDetails(elem)),
    });
  }

  private mapToMeasurementDetails(
    details: FormMeasurementDetails
  ): MeasurementDetails {
    return {
      meterNumber: details.meterNumber,
      changeType: details.changeType,
      measurementTypes: details.measurementTypes
        .map((selected, index) =>
          selected ? this.measurementTypes[index].value : null
        )
        .filter(elem => !!elem) as MEASUREMENT_TYPE[],
      meterLocation: {
        locationType: details.locationType,
        otherLocation: details.otherLocation,
      },
      fasteningType: details.fasteningType,
      converterSize: details.converterSize,
      measuringPointOperator: {
        basicResponsible: details.basicResponsible,
        otherOperator: details.otherOperator,
      },
    };
  }

  public ngOnDestroy(): void {
    this.measurementDetails$.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
