<app-form-card
  titleIcon="category"
  cardTitle="CHARGING_DEVICE_OPTIONS.HEADING"
  dataTestId="charging-device-options-heading">
  <form [formGroup]="optionsForm" class="charging-device-options-form">
    <h2 class="tooltip-heading-label">
      {{ 'CHARGING_DEVICE_OPTIONS.FORM.TYPE_OF_CHARGING_DEVICE' | translate }}
      <app-info-tooltip
        tooltipText="CHARGING_DEVICE_OPTIONS.FORM.TYPE_OF_CHARGING_DEVICE_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <app-selection-group>
      <app-selection-card
        *ngFor="let chargingDevice of chargingDeviceList"
        [icon]="chargingDevice.icon"
        [label]="chargingDevice.label"
        [selected]="selectedOption === chargingDevice.type"
        (click)="selectOption(chargingDevice.type)"
        class="selection-card"
        [attr.data-testid]="chargingDevice.type">
      </app-selection-card>

      <mat-error
        *ngIf="allTouched && optionsForm.get('chargingDeviceType')?.invalid"
        >{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error
      >
    </app-selection-group>
    <div class="space"></div>

    <h2>
      <label id="meter-required-radio-group-label">
        {{
          ('CHARGING_DEVICE_OPTIONS.FORM.METER_REQUIRED' | translate) + ' *'
        }}</label
      >
    </h2>
    <mat-radio-group
      color="primary"
      formControlName="meterRequired"
      aria-labelledby="meter-required-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="meter-required">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-meter-required">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="meterRequired" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <div *ngIf="(optionsForm | formLookup: 'meterRequired') === false">
      <h2 class="tooltip-heading-label">
        {{ 'CHARGING_DEVICE_OPTIONS.FORM.METER_NUMBER' | translate }}
        <app-info-tooltip
          tooltipText="CHARGING_DEVICE_OPTIONS.FORM.METER_NUMBER_TOOLTIP">
        </app-info-tooltip>
      </h2>
      <div class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{
            'CHARGING_DEVICE_OPTIONS.FORM.METER_NUMBER' | translate
          }}</mat-label>
          <input matInput name="meterNumber" formControlName="meterNumber" />
          <mat-error appErrorMessages="meterNumber"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <h2 class="tooltip-heading-label">
      {{ ('SHARED.CONTROLLABLE_POWER.LABEL' | translate) + ' *' }}
      <app-info-tooltip tooltipText="SHARED.CONTROLLABLE_POWER.TOOLTIP">
      </app-info-tooltip>
    </h2>

    <mat-radio-group
      color="primary"
      formControlName="controllablePower"
      aria-labelledby="power-increase-radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button
          [value]="controllablePower.CONTROLLABLE_CONSUMPTION_DEVICES"
          data-testid="controllable-consumption-devices">
          {{
            'SHARED.CONTROLLABLE_POWER.OPTION.CONTROLLABLE_CONSUMPTION_DEVICES'
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button
          [value]="controllablePower.ENERGY_MANAGEMENT_SYSTEM"
          data-testid="energy-management-system">
          {{
            'SHARED.CONTROLLABLE_POWER.OPTION.ENERGY_MANAGEMENT_SYSTEM'
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button
          [value]="controllablePower.NO"
          data-testid="power-not-controllable">
          {{ 'SHARED.CONTROLLABLE_POWER.OPTION.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error appErrorMessages="controllablePower" [hideError]="!allTouched">
      </mat-error>
    </mat-radio-group>

    <h2 class="tooltip-heading-label">
      {{
        ('CHARGING_DEVICE_OPTIONS.FORM.NUMBER_OF_CHARGING_DEVICES'
          | translate) + ' *'
      }}
      <app-info-tooltip
        tooltipText="CHARGING_DEVICE_OPTIONS.FORM.NUMBER_OF_CHARGING_DEVICES_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <div class="input-container">
      <mat-form-field
        appearance="outline"
        class="input-field"
        hideRequiredMarker="true">
        <mat-label>{{
          'CHARGING_DEVICE_OPTIONS.FORM.NUMBER_PLACEHOLDER' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="count"
          formControlName="count"
          min="0"
          data-testid="number-of-charging-devices" />
        <mat-error appErrorMessages="count"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>

    <h2 class="tooltip-heading-label">
      {{
        ('CHARGING_DEVICE_OPTIONS.FORM.NUMBER_OF_CHARGING_POINTS' | translate) +
          ' *'
      }}
      <app-info-tooltip
        tooltipText="CHARGING_DEVICE_OPTIONS.FORM.NUMBER_OF_CHARGING_POINTS_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <div class="input-container">
      <mat-form-field
        appearance="outline"
        class="input-field"
        hideRequiredMarker="true">
        <mat-label>{{
          'CHARGING_DEVICE_OPTIONS.FORM.NUMBER_PLACEHOLDER' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="chargingPointsPerChargingDeviceCount"
          formControlName="chargingPointsPerChargingDeviceCount"
          min="0"
          data-testid="charging-points-per-charging-device-count" />
        <mat-error
          appErrorMessages="chargingPointsPerChargingDeviceCount"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: optionsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
