<app-form-card
  titleIcon="electric_bolt"
  cardTitle="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.POWER_INCREASE.HEADING"
  data-testid="commissioning-electricity-main-power-supply-power-increase-heading">
  <h2 class="full-width">
    {{
      'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.POWER_INCREASE.DESCRIPTION'
        | translate
    }}
  </h2>
  <form [formGroup]="form">
    <h2 class="tooltip-heading-label">
      {{ 'POWER_CHANGE_DETAILS.POWER_DEMAND' | translate }}
      <app-info-tooltip tooltipText="POWER_CHANGE_DETAILS.POWER_DEMAND_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="currentPowerDemand"
          formControlName="currentPowerDemand"
          min="0"
          data-testid="current-power-demand" />
        <mat-error appErrorMessages="currentPowerDemand"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="plannedPowerDemand"
          formControlName="plannedPowerDemand"
          min="0"
          data-testid="planned-power-demand" />
        <mat-error appErrorMessages="plannedPowerDemand"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
