import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nextButtonDisabled',
  standalone: true,
})
export class NextButtonDisabledPipe implements PipeTransform {
  transform(allTouched: boolean, formInvalid: boolean): boolean {
    return allTouched && formInvalid;
  }
}
