import { Pipe, PipeTransform } from '@angular/core';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { ResponsibilityCheckService } from '@app/services/responsibility-check.service';

@Pipe({
  name: 'hasResponsibilityCheck',
  standalone: true,
})
export class HasResponsibilityCheckPipe implements PipeTransform {
  constructor(
    private readonly responsibilityCheckService: ResponsibilityCheckService
  ) {}

  transform(module: FLOW_MODULE | null): boolean {
    return this.responsibilityCheckService.hasResponsibilityCheck(module);
  }
}
