import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BUILDING_STRUCTURE,
  BUILDING_TYPE,
  CURRENT_ENERGY_SOURCE,
  HOUSE_INLET,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { PropertyDetails, propertyDetailsList } from '@app/shared/shared-data';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-gas-property-type',
  templateUrl: './gas-property-type.component.html',
})
export class GasPropertyTypeComponent implements OnInit, OnDestroy {
  public readonly propertyDetailsList: PropertyDetails[] = propertyDetailsList;
  public buildingStructures = Object.keys(BUILDING_STRUCTURE);
  public currentEnergySources = Object.keys(CURRENT_ENERGY_SOURCE);
  public houseInlets = Object.keys(HOUSE_INLET);
  public gasPropertyType = BUILDING_TYPE;
  public selectedPropertyType: BUILDING_TYPE | undefined;
  public allTouched = false;
  public propertyDetailsForm!: FormGroup;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createPropertyTypeForm();
    this.updateForm();
    this.watchForm();
  }

  private createPropertyTypeForm(): void {
    this.propertyDetailsForm = this.formBuilder.group({
      buildingType: [null, Validators.required],
      buildingStructure: [null, Validators.required],
      currentEnergySource: [null],
      houseInlet: [null],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.gasBuildingDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(gasBuildingDetails => {
        this.propertyDetailsForm.patchValue(gasBuildingDetails);
        this.selectedPropertyType = gasBuildingDetails.buildingType;
      });
  }

  private watchForm(): void {
    this.propertyDetailsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(gasBuildingDetails =>
        this.appStateService.updateFormData({
          gasBuildingDetails,
        })
      );
  }

  public selectPropertyType(selectedOption: BUILDING_TYPE): void {
    this.propertyDetailsForm.get('buildingType')?.setValue(selectedOption);
    this.selectedPropertyType = selectedOption;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.propertyDetailsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.propertyDetailsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
