import { Component, OnInit } from '@angular/core';
import { ROUTES } from '@app/models/routes.enum';
import { Router } from '@angular/router';
import { WhiteLabelService } from '@app/services/white-label.service';
import { ButtonConfig } from '@app/modules/shared/form-navigation/form-navigation.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public nextConfig!: Partial<ButtonConfig>;
  public hasWhitelabel = true;

  constructor(
    private router: Router,
    private whiteLabelService: WhiteLabelService
  ) {}

  public ngOnInit(): void {
    this.hasWhitelabel = this.whiteLabelService.hasWhitelabel();
    if (!this.hasWhitelabel) {
      this.nextConfig = { visible: false };
    } else {
      this.nextConfig = {
        label: 'ERROR.TRY_AGAIN_BTN',
      };
    }
  }

  public tryAgain(): void {
    this.router.navigate([ROUTES.SUMMARY], { skipLocationChange: true });
  }
}
