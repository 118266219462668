import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_LANGUAGE } from '@app/constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(private translateService: TranslateService) {}

  public getErrorMessageForInvalidDateFormat(): string {
    let validDateFormat: string;
    switch (this.translateService.currentLang) {
      case APP_LANGUAGE.de.locale:
        validDateFormat = 'DD.MM.YYYY';
        break;
      case APP_LANGUAGE.en.locale:
        validDateFormat = 'DD/MM/YYYY';
        break;
      default:
        return this.translateService.instant('VALIDATION.DATE_PICKER_REQUIRED');
    }

    return this.translateService.instant(
      'VALIDATION.DATE_PICKER_REQUIRED_FORMAT',
      {
        validDateFormat,
      }
    );
  }

  public getFutureDate(daysFromToday: number, givenStartingDate?: Date): Date {
    // use current date as startingDate if it's not provided
    const startingDate = givenStartingDate
      ? new Date(givenStartingDate)
      : new Date();

    startingDate.setDate(startingDate.getDate() + daysFromToday);
    return startingDate;
  }
}
