import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import {
  FORM_OF_FEED_IN_SALE,
  NETWORK_AND_SYSTEM_PROTECTION_TYPE,
  OPERATING_MODE,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-pv-facility-information',
  templateUrl: './pv-facility-information.component.html',
})
export class PvFacilityInformationComponent implements OnInit, OnDestroy {
  public pvFacilityInformationForm!: FormGroup;
  public allTouched = false;
  public operatingModeOptions = Object.keys(OPERATING_MODE);
  public networkAndSystemProtectionTypeOptions = Object.keys(
    NETWORK_AND_SYSTEM_PROTECTION_TYPE
  );
  public formOfFeedInSaleOptions = Object.keys(FORM_OF_FEED_IN_SALE);
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createPvFacilityInformationForm();
    this.updateForm();
    this.watchForm();
  }

  private createPvFacilityInformationForm(): void {
    this.pvFacilityInformationForm = this.formBuilder.group({
      operatingMode: [null, Validators.required],
      networkAndSystemProtectionType: [null, Validators.required],
      manufacturer: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      type: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      meterRequired: [null, Validators.required],
      maxApparentPower: [null, Validators.min(0)],
      inverterCount: [null, Validators.min(0)],
      maxApparentPowerSum: [null, Validators.min(0)],
      pvModuleCount: [null, [Validators.required, Validators.min(0)]],
      powerPerPVModule: [null, [Validators.required, Validators.min(0)]],
      formOfFeedInSale: [null, Validators.required],
      storagePlanned: [null, Validators.required],
    });
    this.watchMeterRequired();
  }

  private watchMeterRequired(): void {
    this.pvFacilityInformationForm
      .get('meterRequired')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((meterRequired: boolean) => {
        if (meterRequired) {
          this.pvFacilityInformationForm.removeControl('meterNumber');
        } else {
          this.pvFacilityInformationForm.addControl(
            'meterNumber',
            this.formBuilder.control(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
        }
      });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.pvFacilityInformation),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(pvFacilityInformation =>
        this.pvFacilityInformationForm.patchValue(pvFacilityInformation)
      );
  }

  private watchForm(): void {
    this.pvFacilityInformationForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(pvFacilityInformation =>
        this.appStateService.updateFormData({
          pvFacilityInformation,
        })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.pvFacilityInformationForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.pvFacilityInformationForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
