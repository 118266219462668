import { Component } from '@angular/core';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouteService } from '@app/services/route.service';

@Component({
  selector: 'app-balcony-pv-exit',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    RequiredSuffixDirective,
    SharedModule,
    TranslateModule,
  ],
  templateUrl: './balcony-pv-exit.component.html',
})
export class BalconyPvExitComponent {
  constructor(private routeService: RouteService) {}
  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    window
      .open('https://www.marktstammdatenregister.de/MaStR', '_blank')
      ?.focus();
  }
}
