import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-commissioning-electricity-contact',
  templateUrl: './commissioning-electricity-contact.component.html',
})
export class CommissioningElectricityContactComponent
  implements OnInit, OnDestroy
{
  public contactDetailsForm!: FormGroup;
  public contactForm!: FormGroup;
  public allTouched = false;
  public today: Date = new Date();
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.initFormData();
    this.watchForm();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private createForm(): void {
    this.contactDetailsForm = this.formBuilder.group(
      {
        firstName: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        lastName: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        email: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.email,
          ],
        ],
        phone: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.phoneNumberValidator(),
          ],
        ],
      },
      {
        updateOn: 'blur',
        validators: [Validators.required],
      }
    );
    // cannot set updateOn blur setting on form group due to conditional field: differentAddress
    this.contactForm = this.formBuilder.group({
      contactDetails: this.contactDetailsForm,
      differentAddress: [null, [Validators.required]],
      gridOperator: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      idNumber: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      electricianName: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      electricianEmail: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.email,
          ],
        },
      ],
      phone: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
            CustomValidators.phoneNumberValidator(),
          ],
        },
      ],
      location: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      date: [new Date(), []],
    });
    this.contactForm.get('date')?.disable();
    this.watchDifferentAddress();
  }

  private watchDifferentAddress(): void {
    this.contactForm
      .get('differentAddress')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(differentAddress => {
        if (differentAddress) {
          this.contactForm.addControl(
            'differentRequesterAddress',
            this.formBuilder.group(
              {
                zipCode: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                    CustomValidators.germanZip,
                  ],
                ],
                city: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                  ],
                ],
                street: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                  ],
                ],
                streetNumber: [
                  null,
                  [
                    CustomValidators.trimValidator,
                    CustomValidators.shortText,
                    Validators.required,
                  ],
                ],
              },
              {
                updateOn: 'blur',
              }
            )
          );
        } else {
          this.contactForm.removeControl('differentRequesterAddress');
        }
      });
  }

  private initFormData(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.commissioningElectricityContact),
        filter(Boolean),
        take(1)
      )
      .subscribe(contactData => this.contactForm.patchValue(contactData));
  }

  private watchForm(): void {
    this.contactForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(contact =>
        this.appStateService.updateFormData({
          commissioningElectricityContact: contact,
        })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.contactForm.valid) {
      this.contactForm.get('date')?.enable();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.contactForm.markAllAsTouched();
    }
  }
}
