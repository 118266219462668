<app-form-card
  titleIcon="electric_bolt"
  cardTitle="BALCONY_PV_EXIT.HEADING"
  data-testid="balcony-pv-exit-heading">
  <p class="bold">{{ 'BALCONY_PV_EXIT.DESCRIPTION' | translate }}</p>
  <p>{{ 'BALCONY_PV_EXIT.DESCRIPTION_2' | translate }}</p>
  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      label: 'BALCONY_PV_EXIT.NEXT_BUTTON',
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
