import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getLanguageLocaleCulture } from '@app/constants/language';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(private readonly translateService: TranslateService) {
    super(translateService.currentLang);
    this.setLocale(getLanguageLocaleCulture(translateService.currentLang));
    this.translateService.onLangChange.subscribe(({ lang }) => {
      this.setLocale(getLanguageLocaleCulture(lang));
    });
  }
}
