import { NgModule } from '@angular/core';
import { AddressFormComponent } from './address-form/address-form.component';
import { ContactComponent } from './contact/contact.component';
import { DocumentUploadSectionComponent } from './documents-upload/document-upload-section/document-upload-section.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { ErrorComponent } from './error/error.component';
import { IntroComponent } from './intro/intro.component';
import { MainDomainPageComponent } from './main-domain-page/main-domain-page.component';
import { ResponsibilityCheckFailComponent } from './responsibility-check-fail/responsibility-check-fail.component';
import { SuccessComponent } from './success/success.component';
import { SummaryComponent } from './summary/summary.component';
import { SharedModule } from '../shared/shared.module';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModuleSelectionComponent } from './module-selection/module-selection.component';
import { HeatModule } from '../heat/heat.module';
import { IsModuleOptionEnabledPipe } from '@app/shared/pipes/is-module-option-enabled.pipe';
import { FlowSelectionComponent } from './flow-selection/flow-selection.component';
import { MainPowerSupplySummaryComponent } from './summary/commissioning/main-power-supply-summary/main-power-supply-summary.component';
import { RegistrationContactDataComponent } from './summary/registration/registration-contact-data/registration-contact-data.component';
import { CommissioningContactDataComponent } from './summary/commissioning/commissioning-contact-data/commissioning-contact-data.component';
import { ContactDetailsComponent } from './summary/common/contact-details/contact-details.component';
import { AddressComponent } from './summary/common/address/address.component';
import { CustomerFacilitySummaryComponent } from './summary/commissioning/customer-facility-summary/customer-facility-summary.component';
import { SimpleMeasurementDetailsSummaryComponent } from '@app/modules/common-steps/summary/commissioning/customer-facility-summary/simple-measurement-details-summary/simple-measurement-details-summary.component';
import { ComplexMeasurementDetailsSummaryComponent } from '@app/modules/common-steps/summary/commissioning/customer-facility-summary/complex-measurement-details-summary/complex-measurement-details-summary.component';
import { ComplexMeasurementDetailSummaryComponent } from './summary/commissioning/customer-facility-summary/complex-measurement-details-summary/complex-measurement-detail-summary/complex-measurement-detail-summary.component';
import { MapWithPrefixPipe } from '@app/shared/pipes/map-with-prefix.pipe';
import { MapTranslatePipe } from '@app/shared/pipes/map-translate.pipe';
import { BooleanTranslateKeyPipe } from '@app/shared/pipes/boolean-translate-key.pipe';
import { HasResponsibilityCheckPipe } from './pipes/has-responsibility-check.pipe';
import { ObjectLookupPipe } from '@app/shared/pipes/object-lookup.pipe';
import { ConstructionGridConnectionDetailsSummaryComponent } from './summary/registration/construction-grid-connection-details-summary/construction-grid-connection-details-summary.component';
import { NextButtonDisabledAddressPipe } from './address-form/next-button-disabled-address.pipe';
import { ConstructionWaterRequirementsSummaryComponent } from './summary/registration/construction-water-requirements-summary/construction-water-requirements-summary.component';
import { NgxTurnstileComponent } from '@app/modules/shared/ngx-turnstile/ngx-turnstile.component';
import { NewGridConnectionSummaryComponent } from '@app/modules/common-steps/summary/commissioning/main-power-supply-summary/new-grid-connection-summary/new-grid-connection-summary.component';
import { PowerIncreaseSummaryComponent } from '@app/modules/common-steps/summary/commissioning/main-power-supply-summary/power-increase-summary/power-increase-summary.component';
import { ChipComponent } from '@app/modules/common-steps/summary/chip/chip.component';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { FileSizePipe } from '@app/modules/common-steps/pipes/filesize.pipe';
import { DecimalPipe } from '@angular/common';

@NgModule({
  declarations: [
    AddressFormComponent,
    ContactComponent,
    DocumentsUploadComponent,
    DocumentUploadSectionComponent,
    ErrorComponent,
    IntroComponent,
    MainDomainPageComponent,
    ResponsibilityCheckFailComponent,
    SuccessComponent,
    SummaryComponent,
    ModuleSelectionComponent,
    FlowSelectionComponent,
    MainPowerSupplySummaryComponent,
    RegistrationContactDataComponent,
    CommissioningContactDataComponent,
    ContactDetailsComponent,
    AddressComponent,
    CustomerFacilitySummaryComponent,
    SimpleMeasurementDetailsSummaryComponent,
    ComplexMeasurementDetailsSummaryComponent,
    ComplexMeasurementDetailSummaryComponent,
    ConstructionGridConnectionDetailsSummaryComponent,
    NextButtonDisabledAddressPipe,
    ConstructionWaterRequirementsSummaryComponent,
    NewGridConnectionSummaryComponent,
    PowerIncreaseSummaryComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    HeatModule,
    IsModuleOptionEnabledPipe,
    MapWithPrefixPipe,
    MapTranslatePipe,
    BooleanTranslateKeyPipe,
    HasResponsibilityCheckPipe,
    ObjectLookupPipe,
    NgxTurnstileComponent,
    ChipComponent,
    RequiredSuffixDirective,
    FileSizePipe,
  ],
  exports: [DocumentUploadSectionComponent],
  providers: [DecimalPipe],
})
export class CommonStepsModule {}
