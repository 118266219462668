<app-form-card
  titleIcon="electric_bolt"
  cardTitle="POWER_CHANGE_DETAILS.HEADING"
  data-testid="grid-connection-power-increase-heading">
  <form [formGroup]="form">
    <div class="space"></div>
    <label id="radio-group-label">
      <h2 class="consumer-question">
        {{ ('POWER_CHANGE_DETAILS.ADDITIONAL_CONSUMERS' | translate) + ' *' }}
        <app-info-tooltip
          tooltipText="SHARED.CONSUMER.OTHER_CONSUMER_QUESTION_TOOLTIP">
        </app-info-tooltip>
      </h2>
    </label>
    <mat-radio-group
      color="primary"
      formControlName="additionalConsumers"
      aria-labelledby="radio-group-label"
      class="input-container">
      <div>
        <mat-radio-button [value]="true" data-testid="additional-consumers">
          {{ 'COMMON.YES' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false" data-testid="no-additional-consumers">
          {{ 'COMMON.NO' | translate }}
        </mat-radio-button>
      </div>
      <mat-error
        appErrorMessages="additionalConsumers"
        [hideError]="!allTouched"></mat-error>
    </mat-radio-group>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'SHARED.CONSUMER.OTHER_CONSUMER_LABEL' | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          name="additionalConsumerInfo"
          formControlName="additionalConsumerInfo" />
        <mat-error appErrorMessages="additionalConsumerInfo"></mat-error>
      </mat-form-field>
    </div>

    <h2>
      {{ 'POWER_CHANGE_DETAILS.NOTES_TITLE' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <mat-label>{{ 'POWER_CHANGE_DETAILS.NOTES' | translate }}</mat-label>
        <textarea
          #notes
          matInput
          cols="50"
          rows="6"
          [maxlength]="maxLength"
          formControlName="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>

    <h2 class="tooltip-heading-label">
      {{ 'POWER_CHANGE_DETAILS.POWER_DEMAND' | translate }}
      <app-info-tooltip tooltipText="POWER_CHANGE_DETAILS.POWER_DEMAND_TOOLTIP">
      </app-info-tooltip>
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="currentPowerDemand"
          formControlName="currentPowerDemand"
          min="0"
          data-testid="current-power-demand" />
        <mat-error appErrorMessages="currentPowerDemand"></mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="plannedPowerDemand"
          formControlName="plannedPowerDemand"
          min="0"
          data-testid="planned-power-demand" />
        <mat-error appErrorMessages="plannedPowerDemand"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()"></app-form-navigation>
</app-form-card>
