import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import {
  CONTROLLABLE_POWER,
  CHARGING_DEVICE_TYPE,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

interface ChargingDeviceDetails {
  icon: string;
  label: string;
  type: CHARGING_DEVICE_TYPE;
}

@Component({
  selector: 'app-charging-device-options',
  templateUrl: './charging-device-options.component.html',
  styleUrls: ['./charging-device-options.component.scss'],
})
export class ChargingDeviceOptionsComponent implements OnInit, OnDestroy {
  public selectedOption: CHARGING_DEVICE_TYPE | undefined;
  public controllablePower = CONTROLLABLE_POWER;
  public optionsForm!: FormGroup;
  public allTouched = false;
  public readonly chargingDeviceList: ChargingDeviceDetails[] = [
    {
      icon: 'electric_meter',
      label: 'CHARGING_DEVICE_OPTIONS.FORM.WALLBOX',
      type: CHARGING_DEVICE_TYPE.WALLBOX,
    },
    {
      icon: 'ev_charger',
      label: 'CHARGING_DEVICE_OPTIONS.FORM.CHARGING_STATION',
      type: CHARGING_DEVICE_TYPE.CHARGING_STATION,
    },
    {
      icon: 'more_horiz',
      label: 'CHARGING_DEVICE_OPTIONS.FORM.OTHER',
      type: CHARGING_DEVICE_TYPE.OTHER,
    },
  ];
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createOptionsForm();
    this.updateForm();
    this.watchForm();
  }

  private createOptionsForm(): void {
    this.optionsForm = this.formBuilder.group({
      chargingDeviceType: [null, Validators.required],
      meterRequired: [null, [Validators.required]],
      controllablePower: [null, [Validators.required]],
      count: [1, [Validators.required, Validators.min(0)]],
      chargingPointsPerChargingDeviceCount: [
        null,
        [Validators.required, Validators.min(0)],
      ],
    });
    this.watchMeterRequired();
  }

  private watchMeterRequired(): void {
    this.optionsForm
      .get('meterRequired')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((meterRequired: boolean) => {
        if (meterRequired) {
          this.optionsForm.removeControl('meterNumber');
        } else {
          this.optionsForm.addControl(
            'meterNumber',
            this.formBuilder.control(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
        }
      });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.chargingDeviceOptions),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(chargingDeviceOptions => {
        this.optionsForm.patchValue(chargingDeviceOptions);
        this.selectedOption = chargingDeviceOptions.chargingDeviceType;
      });
  }

  private watchForm(): void {
    this.optionsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(chargingDeviceOptions =>
        this.appStateService.updateFormData({
          chargingDeviceOptions,
        })
      );
  }

  public selectOption(selectedOption: CHARGING_DEVICE_TYPE): void {
    this.optionsForm.get('chargingDeviceType')?.setValue(selectedOption);
    this.selectedOption = selectedOption;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.optionsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.optionsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
