import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { EmailValidator } from 'commons-validator-js';

export class CustomValidators {
  public static phoneRegEx = new RegExp(/^[+0-9]?\d?[1-9](?:\d ?){6,12}\d$/);

  public static readonly SHORT_TEXT_MAX_LENGTH = 250;
  public static readonly LONG_TEXT_MAX_LENGTH = 2000;
  public static readonly shortText = Validators.maxLength(
    CustomValidators.SHORT_TEXT_MAX_LENGTH
  );
  public static readonly longText = Validators.maxLength(
    CustomValidators.LONG_TEXT_MAX_LENGTH
  );
  public static readonly germanZip = Validators.pattern('^\\d{5}$');
  private static readonly emailValidator = new EmailValidator();

  public static email: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const email: string = control.value?.toString();
    if (
      !email ||
      // allow for e2e tests to prevent sending actual emails
      email.endsWith('@eon.local') ||
      this.emailValidator.isValid(email)
    ) {
      return null;
    }
    return {
      email: {
        actualValue: email,
      },
    };
  };

  public static phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneNumber: string = control.value?.toString();

      if (!phoneNumber || this.phoneRegEx.test(phoneNumber)) {
        return null;
      } else {
        return {
          phone: {
            requiredPattern: this.phoneRegEx,
            actualValue: phoneNumber,
          },
        };
      }
    };
  }

  public static atLeastOneChecked(): ValidatorFn {
    return ((
      formArray?: FormArray<AbstractControl>
    ): ValidationErrors | null => {
      const noneChecked = !formArray?.controls.some(
        (control: AbstractControl) => control.value === true
      );
      if (noneChecked) {
        return {
          noneChecked: true,
        };
      } else {
        return null;
      }
    }) as ValidatorFn;
  }

  public static readonly trimValidator = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const value = control.value;
    if (value?.startsWith(' ') || value?.endsWith(' ')) {
      control.setValue(control.value?.trim(), {
        emitEvent: false,
        onlySelf: true,
      });
    }
    return null;
  };
}
