import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'mapTranslate',
  standalone: true,
  pure: false,
})
export class MapTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: string[]): string[] {
    return value.map(key => this.translateService.instant(key));
  }
}
