<app-form-card
  titleIcon="electric_bolt"
  cardTitle="GRID_CONNECTION_CONSUMER.HEADING"
  dataTestId="grid-connection-consumer-heading">
  <h2 class="full-width">
    {{ 'GRID_CONNECTION_CONSUMER.DESCRIPTION' | translate }}
  </h2>
  <form [formGroup]="consumerForm">
    <div formGroupName="type" class="multi-selection-cards-section">
      <app-multi-selection-card
        icon="heat_pump"
        label="SHARED.CONSUMER.HEAT_PUMP"
        [formGroupControl]="consumerTypeControl"
        (selectionChanged)="toggleSelectionCardValue(consumerType.HEAT_PUMP)"
        [controlName]="consumerType.HEAT_PUMP">
      </app-multi-selection-card>

      <app-multi-selection-card
        icon="water_heater"
        label="SHARED.CONSUMER.FLOW_HEATER"
        [formGroupControl]="consumerTypeControl"
        (selectionChanged)="toggleSelectionCardValue(consumerType.FLOW_HEATER)"
        [controlName]="consumerType.FLOW_HEATER">
      </app-multi-selection-card>

      <app-multi-selection-card
        icon="electric_car"
        label="SHARED.CONSUMER.WALLBOX"
        [formGroupControl]="consumerTypeControl"
        (selectionChanged)="toggleSelectionCardValue(consumerType.WALLBOX)"
        [controlName]="consumerType.WALLBOX">
      </app-multi-selection-card>
    </div>

    <h2 class="tooltip-heading">
      <span appRequiredSuffix>{{
        'SHARED.CONSUMER.SYSTEM_POWER' | translate
      }}</span>
      <app-info-tooltip tooltipText="SHARED.CONSUMER.SYSTEM_POWER_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{ 'SHARED.CONSUMER.SYSTEM_POWER' | translate }}</mat-label>
        <input
          matInput
          type="number"
          name="systemPower"
          formControlName="systemPower"
          min="0"
          data-testid="system-power" />
        <mat-error appErrorMessages="systemPower"></mat-error>
      </mat-form-field>
    </div>

    <h2 appRequiredSuffix>
      {{ 'SHARED.CONSUMER.CONNECTION_FUSE' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'SHARED.CONSUMER.CONNECTION_FUSE' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="connectionFuse"
          formControlName="connectionFuse"
          min="0"
          data-testid="connection-fuse" />
        <mat-error appErrorMessages="connectionFuse"></mat-error>
      </mat-form-field>
    </div>

    <h2 appRequiredSuffix>
      {{ 'GRID_CONNECTION_CONSUMER.MAX_CONCURRENT_POWER' | translate }}
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'GRID_CONNECTION_CONSUMER.MAX_CONCURRENT_POWER' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="maxConcurrentPower"
          formControlName="maxConcurrentPower"
          min="0"
          data-testid="max-concurrent-power" />
        <mat-error appErrorMessages="maxConcurrentPower"></mat-error>
      </mat-form-field>
    </div>

    <h2 class="tooltip-heading">
      {{ 'SHARED.CONSUMER.OTHER_CONSUMER_QUESTION' | translate }}
      <app-info-tooltip
        tooltipText="SHARED.CONSUMER.OTHER_CONSUMER_QUESTION_TOOLTIP">
      </app-info-tooltip>
    </h2>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'SHARED.CONSUMER.OTHER_CONSUMER_LABEL' | translate
        }}</mat-label>
        <input
          matInput
          name="additionalConsumers"
          formControlName="additionalConsumers"
          [placeholder]="
            'SHARED.CONSUMER.OTHER_CONSUMER_PLACEHOLDER' | translate
          " />
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: consumerForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
