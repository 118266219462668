<app-form-card
  titleIcon="home"
  cardTitle="COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.HEADING"
  data-testid="commissioning-electricity-main-power-supply-heading">
  <h2 class="full-width">
    {{ 'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let detail of details"
      [icon]="detail.icon"
      [label]="
        'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.TYPE.' + detail.type
      "
      [selected]="selectedType === detail.type"
      (click)="selectType(detail.type)"
      [attr.data-testid]="detail.type">
    </app-selection-card>

    <mat-error *ngIf="allTouched && !selectedType">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <ng-container *ngIf="selectedType">
    <div class="space"></div>
    <app-hint>
      <span>{{
        'COMMISSIONING_ELECTRICITY.MAIN_POWER_SUPPLY.HINT.' + selectedType
          | translate
      }}</span>
    </app-hint>
  </ng-container>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: !selectedType,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
