import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CONTROLLABLE_POWER } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-heat-pump',
  templateUrl: './heat-pump.component.html',
})
export class HeatPumpComponent implements OnInit, OnDestroy {
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public heatPumpForm!: FormGroup;
  public allTouched = false;
  public controllablePower = CONTROLLABLE_POWER;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.initFormData();
    this.watchForm();
  }

  private createForm(): void {
    this.heatPumpForm = this.formBuilder.group({
      systemManufacturer: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemType: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemPower: [null, [Validators.required, Validators.min(0)]],
      meterRequired: [null, [Validators.required]],
      connectionPowerIncrease: [null, [Validators.required]],
      controllablePower: [null, [Validators.required]],
      notes: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
    });
    this.watchConnectionPowerIncrease();
    this.watchMeterRequired();
  }

  private watchMeterRequired(): void {
    this.heatPumpForm
      .get('meterRequired')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((meterRequired: boolean) => {
        if (meterRequired) {
          this.heatPumpForm.removeControl('meterNumber');
        } else {
          this.heatPumpForm.addControl(
            'meterNumber',
            this.formBuilder.control(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
        }
      });
  }

  private watchConnectionPowerIncrease(): void {
    this.heatPumpForm
      .get('connectionPowerIncrease')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(connectionPowerIncrease => {
        if (connectionPowerIncrease) {
          this.heatPumpForm.addControl(
            'currentPowerDemand',
            this.formBuilder.control(null, [
              Validators.required,
              Validators.min(0),
            ])
          );
          this.heatPumpForm.addControl(
            'plannedPowerDemand',
            this.formBuilder.control(null, [
              Validators.required,
              Validators.min(0),
            ])
          );
        } else {
          this.heatPumpForm.removeControl('currentPowerDemand');
          this.heatPumpForm.removeControl('plannedPowerDemand');
        }
      });
  }

  private initFormData(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.heatPump),
        filter(Boolean),
        take(1)
      )
      .subscribe(heatPump => this.heatPumpForm.patchValue(heatPump));
  }

  private watchForm(): void {
    this.heatPumpForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(heatPump => this.appStateService.updateFormData({ heatPump }));
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.heatPumpForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.heatPumpForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
