<div class="document-container">
  <div class="document-details">
    <h2 class="document-name">
      <ng-container *ngIf="documentName; else typeName">
        {{ documentName | translate }}
      </ng-container>

      <ng-template #typeName
        >{{
          'DOCUMENTS_UPLOAD.DOCUMENT_TYPE.' + documentDetails.typeName
            | translate
        }}
      </ng-template>

      <span *ngIf="documentDetails.required">*</span>
    </h2>

    <a
      class="document-download bold"
      [href]="documentDetails.downloadLink"
      target="_blank"
      download
      *ngIf="documentDetails.downloadLink">
      <mat-icon class="material-icons-outlined">file_download</mat-icon>
      {{ 'DOCUMENTS_UPLOAD.DOWNLOAD' | translate }}
    </a>

    <span class="document-description">
      <ng-container *ngIf="documentDescription; else typeDescription">
        {{ documentDescription | translate }}
      </ng-container>

      <ng-template #typeDescription>
        {{
          'DOCUMENTS_UPLOAD.DOCUMENT_TYPE_DESCRIPTION.' +
            documentDetails.description | translate
        }}</ng-template
      >
    </span>
  </div>

  <div class="upload-section">
    <input
      hidden
      type="file"
      [accept]="acceptedFileTypes"
      (change)="onFileSelected($event)"
      onclick="value=null;"
      #fileUpload />
    <button mat-stroked-button (click)="selectFile(fileUpload)">
      <mat-icon>add</mat-icon>
      {{ 'DOCUMENTS_UPLOAD.SELECT' | translate }}
    </button>
  </div>
</div>

<mat-error
  *ngIf="allTouched || documentTouched"
  [appErrorMessages]="documentControlName">
</mat-error>

<ng-container *ngIf="multipleDocumentUpload; else singleDocumentUpload">
  <div
    *ngIf="documentControl.value?.length"
    class="uploaded-document-container">
    <div
      *ngFor="let selectedDocument of documentControl.value"
      class="uploaded-document">
      <span class="document-name">
        <mat-icon>file_download_done</mat-icon>
        <span>{{ selectedDocument.name }}</span>
      </span>
      <span>{{ selectedDocument.file.size | filesize: locale }}</span>
      <button
        mat-icon-button
        (click)="removeSelectedDocument(selectedDocument)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #singleDocumentUpload>
  <div *ngIf="documentControl.value" class="uploaded-document-container">
    <div class="uploaded-document">
      <span class="document-name">
        <mat-icon>file_download_done</mat-icon>
        <span>{{ documentControl.value.name }}</span>
      </span>
      <span>{{ documentControl.value.file.size | filesize: locale }}</span>
      <button mat-icon-button (click)="removeSelectedDocument()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
