<app-form-card
  titleIcon="category"
  cardTitle="GRID_CONNECTION_TYPE.HEADING"
  dataTestId="grid-connection-type-heading">
  <h2 class="full-width">
    {{ 'GRID_CONNECTION_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let connection of connectionDetailsList"
      class="selection-card"
      [icon]="connection.icon"
      [svgIcon]="connection.svgIcon"
      [label]="connection.label"
      [selected]="selectedType === connection.type"
      (click)="selectType(connection.type)"
      [attr.data-testid]="connection.type">
    </app-selection-card>
    <mat-error
      *ngIf="
        allTouched | nextButtonDisabled: gridConnectionTypeFormControl.invalid
      "
      >{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error
    >
  </app-selection-group>

  <ng-container *ngIf="selectedType">
    <div class="space"></div>
    <app-hint [ngSwitch]="selectedType">
      <span *ngSwitchCase="gridConnectionTypes.NEW">
        {{ 'SHARED.GRID_CONNECTION.MESSAGE' | translate }}
        <ul class="list-container">
          <li>
            {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
          </li>
          <li>
            {{ 'SHARED.DOCUMENT_TYPE.FLOOR_PLAN' | translate }}
          </li>
        </ul>
      </span>
      <span *ngSwitchDefault>
        {{ 'GRID_CONNECTION_TYPE.INFO.' + selectedType | translate }}
      </span>
    </app-hint>
  </ng-container>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled:
        allTouched | nextButtonDisabled: gridConnectionTypeFormControl.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
