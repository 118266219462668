import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { WATER_OPTION } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { DateHelperService } from '@app/services/date-helper.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

interface WaterOption {
  icon: string;
  label: string;
  type: WATER_OPTION;
}

@Component({
  selector: 'app-water-options',
  templateUrl: './water-options.component.html',
  styleUrls: ['./water-options.component.scss'],
})
export class WaterOptionsComponent implements OnInit, OnDestroy {
  public readonly waterOptionsList: WaterOption[] = [
    {
      icon: 'add_circle',
      label: 'WATER.WATER_OPTIONS.NEW.LABEL',
      type: WATER_OPTION.NEW,
    },
    {
      icon: 'settings',
      label: 'WATER.WATER_OPTIONS.CHANGE.LABEL',
      type: WATER_OPTION.CHANGE,
    },
    {
      icon: 'cancel',
      label: 'WATER.WATER_OPTIONS.DETACH.LABEL',
      type: WATER_OPTION.DETACH,
    },
  ];

  public waterOptions = WATER_OPTION;
  public selectedOption?: WATER_OPTION;
  public waterOptionsForm!: FormGroup;
  public waterOptionControl!: FormControl;
  public changeFlowRateControl?: FormControl;
  public relocateControl?: FormControl;
  public allTouched = false;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public dateForTomorrow = this.dateHelperService.getFutureDate(1);
  public errorMessageForInvalidDateFormat =
    this.dateHelperService.getErrorMessageForInvalidDateFormat();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private routeService: RouteService,
    private dateHelperService: DateHelperService
  ) {}

  public ngOnInit(): void {
    this.createWaterOptionsForm();
    this.updateForm();
    this.watchForm();
  }

  private createWaterOptionsForm(): void {
    this.waterOptionsForm = this.formBuilder.group({
      waterOption: [null, Validators.required],
    });
    this.waterOptionControl = this.waterOptionsForm.get(
      'waterOption'
    ) as FormControl;
    this.watchWaterOption();
  }

  private watchWaterOption(): void {
    this.waterOptionControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(waterOption => {
        this.removeControls();
        if (waterOption === WATER_OPTION.CHANGE) {
          this.waterOptionsForm.addControl(
            'changeFlowRate',
            new FormControl(null)
          );
          this.waterOptionsForm.addControl('relocate', new FormControl(null));
          this.watchFlowRateAndRelocate();
        } else if (waterOption === WATER_OPTION.DETACH) {
          this.waterOptionsForm.addControl(
            'permanentDeconstruction',
            new FormControl(null, Validators.required)
          );
          this.waterOptionsForm.addControl(
            'meterNumber',
            new FormControl(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
                Validators.required,
              ],
            })
          );
          this.watchPermanentDeconstruction();
        }
        this.waterOptionsForm.updateValueAndValidity();
      });
  }

  private removeControls(): void {
    this.waterOptionsForm.removeControl('changeFlowRate');
    this.waterOptionsForm.removeControl('currentFlowRate');
    this.waterOptionsForm.removeControl('plannedFlowRate');
    this.waterOptionsForm.removeControl('additionalDemand');
    this.waterOptionsForm.removeControl('relocate');
    this.waterOptionsForm.removeControl('ditchLength');
    this.waterOptionsForm.removeControl('permanentDeconstruction');
    this.waterOptionsForm.removeControl('meterNumber');
    this.waterOptionsForm.removeControl('deconstructionUntil');
  }

  private watchPermanentDeconstruction(): void {
    this.waterOptionsForm
      .get('permanentDeconstruction')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(permanentDeconstruction => {
        if (permanentDeconstruction) {
          this.waterOptionsForm.removeControl('deconstructionUntil');
        } else {
          this.waterOptionsForm.addControl(
            'deconstructionUntil',
            new FormControl(null, [Validators.required])
          );
        }
      });
  }

  private watchFlowRateAndRelocate(): void {
    this.changeFlowRateControl = this.waterOptionsForm.get(
      'changeFlowRate'
    ) as FormControl;
    this.relocateControl = this.waterOptionsForm.get('relocate') as FormControl;

    this.changeFlowRateControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(changeFlowRate => {
        if (changeFlowRate) {
          this.waterOptionsForm.addControl(
            'currentFlowRate',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
          this.waterOptionsForm.addControl(
            'plannedFlowRate',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
          this.waterOptionsForm.addControl(
            'additionalDemand',
            new FormControl(null, {
              updateOn: 'blur',
              validators: [
                CustomValidators.trimValidator,
                CustomValidators.shortText,
              ],
            })
          );
        } else {
          this.waterOptionsForm.removeControl('currentFlowRate');
          this.waterOptionsForm.removeControl('plannedFlowRate');
          this.waterOptionsForm.removeControl('additionalDemand');
        }
      });

    this.relocateControl?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(relocate => {
        if (relocate) {
          this.waterOptionsForm.addControl(
            'ditchLength',
            new FormControl(null, [Validators.required, Validators.min(0)])
          );
        } else {
          this.waterOptionsForm.removeControl('ditchLength');
        }
      });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.waterOptions),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(waterOptions => {
        this.waterOptionsForm.patchValue(waterOptions);
        this.selectedOption = waterOptions.waterOption;
      });
  }

  private watchForm(): void {
    this.waterOptionsForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(waterOptions =>
        this.appStateService.updateFormData({
          waterOptions,
        })
      );
  }

  public selectType(selectedOption: WATER_OPTION): void {
    if (this.waterOptionsForm.get('waterOption')?.value !== selectedOption) {
      this.waterOptionsForm.get('waterOption')?.setValue(selectedOption);
      this.selectedOption = selectedOption;
      this.waterOptionsForm.markAsUntouched();
      this.allTouched = false;
    }
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.waterOptionsForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.waterOptionsForm.markAllAsTouched();
      this.allTouched = true;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
