import { Component, Input } from '@angular/core';
import {
  CommissioningElectricityBuildingDetails,
  CommissioningElectricityInformation,
} from '@app/models/form-data.interface';
import {
  MainPowerSupplyPowerIncrease,
  MainPowerSupplyType,
} from '@app/models/commissioning-mapped-data.interface';
import { ROUTES } from '@app/models/routes.enum';

@Component({
  selector: 'app-main-power-supply-summary',
  templateUrl: './main-power-supply-summary.component.html',
})
export class MainPowerSupplySummaryComponent {
  @Input({ required: true })
  public mainPowerSupplyType!: MainPowerSupplyType;
  @Input()
  public buildingDetails?: CommissioningElectricityBuildingDetails;
  @Input()
  public electricityInformation?: CommissioningElectricityInformation;
  @Input()
  public powerIncreaseDetails?: MainPowerSupplyPowerIncrease;
  public type = MainPowerSupplyType;
  protected readonly routes = ROUTES;
}
