import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  selector: 'app-grid-connection-power',
  templateUrl: './power-change-details.component.html',
  styleUrls: ['./power-change-details.component.scss'],
})
export class PowerChangeDetailsComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public allTouched = false;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      additionalConsumers: [null, [Validators.required]],
      additionalConsumerInfo: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      notes: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
      currentPowerDemand: [null, [Validators.required, Validators.min(0)]],
      plannedPowerDemand: [null, [Validators.required, Validators.min(0)]],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.powerChangeDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(powerChangeDetails =>
        this.form.patchValue(powerChangeDetails)
      );
  }

  private watchForm(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(powerChangeDetails =>
        this.appStateService.updateFormData({ powerChangeDetails })
      );
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    this.allTouched = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.routeService.navigateToNextStep();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
