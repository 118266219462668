import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  FORM_OF_FEED_IN_SALE,
  OPERATING_MODE,
  PV_TYPE,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

@Component({
  templateUrl: './change-pv-details.component.html',
})
export class ChangePVDetailsComponent implements OnInit, OnDestroy {
  public changePVDetailsForm!: FormGroup;
  public allTouched = false;
  public operatingModeOptions = Object.keys(OPERATING_MODE);
  public formOfFeedInSaleOptions = Object.keys(FORM_OF_FEED_IN_SALE);
  private onDestroy$: Subject<void> = new Subject();
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createChangePVDetailsForm();
    this.updateForm();
  }

  private createChangePVDetailsForm(): void {
    this.changePVDetailsForm = this.formBuilder.group({
      currentOperatingMode: [null, Validators.required],
      plannedOperatingMode: [null, Validators.required],
      currentFormOfFeedInSale: [null, Validators.required],
      plannedFormOfFeedInSale: [null, Validators.required],
      notes: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.changePVDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(changePVDetails =>
        this.changePVDetailsForm.patchValue(changePVDetails)
      );
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      changePVDetails: {
        ...this.changePVDetailsForm.value,
        type: PV_TYPE.CHANGE,
      },
    });
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.changePVDetailsForm.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.changePVDetailsForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
