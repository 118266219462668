<app-summary-section-field
  label="ADDRESS.FORM.ZIP_CODE"
  [value]="address.zipCode">
</app-summary-section-field>

<app-summary-section-field label="ADDRESS.FORM.CITY" [value]="address.city">
</app-summary-section-field>

<app-summary-section-field label="ADDRESS.FORM.STREET" [value]="address.street">
</app-summary-section-field>

<app-summary-section-field
  label="ADDRESS.FORM.STREET_NUMBER"
  [value]="address.streetNumber">
</app-summary-section-field>
