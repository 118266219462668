import { NgModule } from '@angular/core';
import { WaterOptionsComponent } from './water-options/water-options.component';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { WaterPropertyTypeComponent } from './water-property-type/water-property-type.component';
import { WaterRequirementsComponent } from './water-requirements/water-requirements.component';
import { WaterAreaComponent } from './water-area/water-area.component';
import { SewageOptionsComponent } from './sewage-options/sewage-options.component';
import { SewageTypeComponent } from './sewage-type/sewage-type.component';
import { IsModuleOptionEnabledPipe } from '@app/shared/pipes/is-module-option-enabled.pipe';
import { ConstructionWaterRequirementsComponent } from './construction-water-requirements/construction-water-requirements.component';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { NextDayDatePipe } from '@app/shared/pipes/next-day-date.pipe';

@NgModule({
  declarations: [
    WaterOptionsComponent,
    WaterPropertyTypeComponent,
    WaterRequirementsComponent,
    WaterAreaComponent,
    SewageOptionsComponent,
    SewageTypeComponent,
    ConstructionWaterRequirementsComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    IsModuleOptionEnabledPipe,
    NextDayDatePipe,
    RequiredSuffixDirective,
  ],
})
export class WaterModule {}
