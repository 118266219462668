<app-form-card titleIcon="category" cardTitle="HEAT.HEAT_OPTIONS.HEADING">
  <h2 class="full-width">
    {{ 'HEAT.HEAT_OPTIONS.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <ng-container *ngFor="let heatOption of heatOptionsList">
      <app-selection-card
        *ngIf="heatOption.formType | isModuleOptionEnabled: heatModule | async"
        class="selection-card"
        [icon]="heatOption.icon"
        [label]="heatOption.label"
        [selected]="selectedOption === heatOption.type"
        (click)="selectType(heatOption.type)">
      </app-selection-card>
    </ng-container>
    <mat-error *ngIf="!selectedOption && allTouched">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <ng-container *ngIf="selectedOption">
    <div class="space"></div>
    <form [formGroup]="heatOptionsForm">
      <ng-container [ngSwitch]="selectedOption">
        <app-hint *ngSwitchCase="heatOptions.NEW">
          <div>
            {{ 'HEAT.HEAT_OPTIONS.HEAT_NEW.INFO' | translate }}
            <ul class="list-container">
              <li>
                {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
              </li>
              <li>
                {{ 'SHARED.DOCUMENT_TYPE.FLOOR_PLAN' | translate }}
              </li>
            </ul>
          </div>
        </app-hint>
        <div *ngSwitchCase="heatOptions.CHANGE" class="heat-change-container">
          <app-hint>
            <div>
              {{ 'HEAT.HEAT_OPTIONS.HEAT_CHANGE.INFO' | translate }}
              <ul class="list-container">
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
                </li>
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.FLOOR_PLAN' | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
          <div class="space"></div>
          <h2>
            {{
              'HEAT.HEAT_OPTIONS.HEAT_CHANGE.CONNECTION_CHANGE_QUESTION'
                | translate
            }}
          </h2>
          <mat-checkbox formControlName="changeHeatPower" color="primary">{{
            'HEAT.HEAT_OPTIONS.HEAT_CHANGE.CHANGE_HEAT_POWER_LABEL' | translate
          }}</mat-checkbox>

          <mat-checkbox formControlName="changeOther" color="primary">{{
            'HEAT.HEAT_OPTIONS.HEAT_CHANGE.CHANGE_OTHER_LABEL' | translate
          }}</mat-checkbox>

          <div class="space"></div>
          <div *ngIf="changeHeatPowerControl?.value">
            <h2 class="tooltip-heading-label">
              {{
                'HEAT.HEAT_OPTIONS.HEAT_CHANGE.CHANGE_HEAT_POWER_BEFORE_AFTER_LABEL'
                  | translate
              }}
              <app-info-tooltip
                tooltipText="HEAT.HEAT_OPTIONS.HEAT_CHANGE.CHANGE_HEAT_POWER_TOOLTIP">
              </app-info-tooltip>
            </h2>

            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{
                  'HEAT.HEAT_OPTIONS.HEAT_CHANGE.CURRENT_HEAT_POWER_PLACEHOLDER'
                    | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  name="currentHeatPower"
                  formControlName="currentHeatPower"
                  min="0" />
                <mat-error appErrorMessages="currentHeatPower"></mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{
                  'HEAT.HEAT_OPTIONS.HEAT_CHANGE.PLANNED_HEAT_POWER_PLACEHOLDER'
                    | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  name="plannedHeatPower"
                  formControlName="plannedHeatPower"
                  min="0" />
                <mat-error appErrorMessages="plannedHeatPower"></mat-error>
              </mat-form-field>
            </div>
            <div class="space"></div>
            <h2>
              {{
                'HEAT.HEAT_OPTIONS.HEAT_CHANGE.ADDITIONAL_DEMAND_QUESTION'
                  | translate
              }}
            </h2>
            <div class="input-container">
              <mat-form-field appearance="outline" class="textarea-field">
                <mat-label>{{
                  'HEAT.HEAT_OPTIONS.HEAT_CHANGE.ADDITIONAL_DEMAND_PLACEHOLDER'
                    | translate
                }}</mat-label>
                <textarea
                  #additionalHeatDemand
                  matInput
                  cols="50"
                  rows="2"
                  [maxlength]="maxLength"
                  formControlName="additionalHeatDemand"></textarea>
                <mat-hint align="end"
                  >{{ additionalHeatDemand.value.length }} /
                  {{ maxLength }}</mat-hint
                >
                <mat-error appErrorMessages="additionalHeatDemand"></mat-error>
              </mat-form-field>
            </div>
            <div class="space"></div>
          </div>

          <div *ngIf="changeOtherControl?.value">
            <h2 class="tooltip-heading-label">
              {{
                'HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_LABEL' | translate
              }}
              <app-info-tooltip
                tooltipText="HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_TOOLTIP">
              </app-info-tooltip>
            </h2>

            <div class="input-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{
                  'HEAT.HEAT_OPTIONS.HEAT_CHANGE.OTHER_CHANGES_PLACEHOLDER'
                    | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  name="otherChanges"
                  formControlName="otherChanges" />
                <mat-error appErrorMessages="otherChanges"></mat-error>
              </mat-form-field>
            </div>
            <div class="space"></div>
          </div>
        </div>

        <div *ngSwitchCase="heatOptions.DETACH">
          <app-hint>
            <div>
              {{ 'HEAT.HEAT_OPTIONS.HEAT_DETACH.INFO' | translate }}
              <ul class="list-container">
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.PROOF_OF_OWNERSHIP' | translate }}
                </li>
                <li>
                  {{ 'SHARED.DOCUMENT_TYPE.SITE_PLAN' | translate }}
                </li>
              </ul>
            </div>
          </app-hint>
          <div class="space"></div>

          <h2>
            {{
              ('HEAT.HEAT_OPTIONS.HEAT_DETACH.DECONSTRUCTION_TYPE_QUESTION'
                | translate) + ' *'
            }}
          </h2>
          <mat-radio-group
            formControlName="permanentDeconstruction"
            class="input-container">
            <div class="deconstruction-type-container">
              <mat-radio-button color="primary" [value]="false"
                >{{
                  'HEAT.HEAT_OPTIONS.HEAT_DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
                    | translate
                }}
              </mat-radio-button>
              <app-info-tooltip
                tooltipText="HEAT.HEAT_OPTIONS.HEAT_DETACH.TEMPORARY_DECONSTRUCTION_TOOLTIP">
              </app-info-tooltip>
            </div>

            <div class="deconstruction-type-container">
              <mat-radio-button color="primary" [value]="true">
                {{
                  'HEAT.HEAT_OPTIONS.HEAT_DETACH.PERMANENT_DECONSTRUCTION_LABEL'
                    | translate
                }}
              </mat-radio-button>
              <app-info-tooltip
                tooltipText="HEAT.HEAT_OPTIONS.HEAT_DETACH.PERMANENT_DECONSTRUCTION_TOOLTIP">
              </app-info-tooltip>
            </div>
            <mat-error
              appErrorMessages="permanentDeconstruction"
              [hideError]="!allTouched"></mat-error>
          </mat-radio-group>

          <div
            *ngIf="
              (heatOptionsForm | formLookup: 'permanentDeconstruction') ===
              false
            "
            class="input-container">
            <mat-form-field appearance="outline" class="input-field">
              <mat-label>{{
                'HEAT.HEAT_OPTIONS.HEAT_DETACH.DECONSTRUCTION_UNTIL' | translate
              }}</mat-label>
              <input
                matInput
                [min]="dateForTomorrow"
                [matDatepicker]="picker"
                formControlName="deconstructionUntil" />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="
                  heatOptionsForm | hasError: 'deconstructionUntil' : 'required'
                "
                >{{ errorMessageForInvalidDateFormat }}
              </mat-error>

              <mat-error
                *ngIf="
                  heatOptionsForm
                    | hasError: 'deconstructionUntil' : 'matDatepickerMin'
                "
                >{{ 'VALIDATION.DATE_PICKER_MIN' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <h2 class="tooltip-heading-label">
            {{ 'HEAT.HEAT_OPTIONS.HEAT_DETACH.METER_NUMBER' | translate }}
            <app-info-tooltip
              tooltipText="HEAT.HEAT_OPTIONS.HEAT_DETACH.METER_NUMBER_TOOLTIP">
            </app-info-tooltip>
          </h2>

          <div class="input-container">
            <mat-form-field appearance="outline" class="input-field">
              <mat-label>
                {{ 'HEAT.HEAT_OPTIONS.HEAT_DETACH.METER_NUMBER' | translate }}
              </mat-label>
              <input
                matInput
                type="text"
                name="meterNumber"
                formControlName="meterNumber" />
              <mat-error appErrorMessages="meterNumber"></mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: heatOptionsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
