import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FLOW_MODULE, WATER_AREA } from '@app/models/form-data.interface';
import { FORM_TYPE } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

interface WaterAreaDetails {
  type: WATER_AREA;
  svgIcon: string;
  label: string;
}

@Component({
  templateUrl: './water-area.component.html',
})
export class WaterAreaComponent implements OnInit, OnDestroy {
  public selectedWaterArea?: WATER_AREA;
  public waterAreaControl!: FormControl;
  public allTouched = false;
  public waterModule = FLOW_MODULE.WATER;
  public formType = FORM_TYPE;
  public readonly waterAreaDetailsList: WaterAreaDetails[] = [
    {
      type: FORM_TYPE.WATER,
      label: 'WATER.WATER_AREA.WATER',
      svgIcon: 'drinking_water',
    },
    {
      type: FORM_TYPE.SEWAGE,
      label: 'WATER.WATER_AREA.SEWAGE',
      svgIcon: 'sewage',
    },
    {
      type: FORM_TYPE.CONSTRUCTION_WATER,
      label: 'WATER.WATER_AREA.CONSTRUCTION_WATER',
      svgIcon: 'construction_water',
    },
  ];

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createWaterAreaControl();
    this.updateControl();
  }

  private createWaterAreaControl(): void {
    this.waterAreaControl = new FormControl('', Validators.required);
  }

  private updateControl(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.waterArea),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(waterArea => {
        this.waterAreaControl.setValue(waterArea);
        this.selectedWaterArea = waterArea;
      });
  }

  public selectArea(selectedWaterArea: WATER_AREA): void {
    if (this.selectedWaterArea !== selectedWaterArea) {
      this.waterAreaControl.setValue(selectedWaterArea);
      this.selectedWaterArea = selectedWaterArea;
    }
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.waterAreaControl.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.waterAreaControl.markAllAsTouched();
    }
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      waterArea: this.waterAreaControl.value,
    });
    this.setFormType();
  }

  private setFormType(): void {
    // required for backend mapping
    this.appStateService.updateFormData({
      formType: this.waterAreaControl.value,
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
