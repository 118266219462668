import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PV_TYPE } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

interface PvDetails {
  icon?: string;
  svgIcon?: string;
  label: string;
  type: PV_TYPE;
}

@Component({
  templateUrl: './pv-type.component.html',
})
export class PvTypeComponent implements OnInit, OnDestroy {
  public readonly connectionDetailsList: PvDetails[] = [
    {
      icon: 'add_circle',
      label: 'PV_TYPE.TYPE.NEW',
      type: PV_TYPE.NEW,
    },

    {
      icon: 'settings',
      label: 'PV_TYPE.TYPE.CHANGE',
      type: PV_TYPE.CHANGE,
    },
  ];

  public pvTypes = PV_TYPE;
  public selectedType?: PV_TYPE;
  private onDestroy$: Subject<void> = new Subject();
  public pvTypeFormControl!: FormControl;
  public allTouched = false;

  constructor(
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createProductForm();
    this.updateForm();
  }

  private createProductForm(): void {
    this.pvTypeFormControl = new FormControl('', Validators.required);
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.pvType),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(pvType => {
        this.pvTypeFormControl.patchValue(pvType);
        this.selectedType = pvType;
      });
  }

  private updateState(): void {
    this.appStateService.updateFormData({
      pvType: this.pvTypeFormControl.value,
    });
  }

  public selectType(selectedType: PV_TYPE): void {
    this.pvTypeFormControl?.setValue(selectedType);
    this.selectedType = selectedType;
  }

  public previous(): void {
    this.updateState();
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.pvTypeFormControl.valid) {
      this.updateState();
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.pvTypeFormControl.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
