import { Component, OnDestroy, OnInit } from '@angular/core';
import { COMMISSIONING_FLOW_TYPE } from '@app/models/commissioning-mapped-data.interface';
import { FLOW, FLOW_MODULE } from '@app/models/form-data.interface';
import { FORM_TYPE, GAS_OPTION } from '@app/models/registration-form';
import { RouteService } from '@app/services/route.service';
import { WhiteLabelService } from '@app/services/white-label.service';
import { Subject, filter, takeUntil } from 'rxjs';

export interface ModuleDetails {
  moduleName: FLOW_MODULE;
  icon?: string;
  customIcon?: string;
  flows: Array<FORM_TYPE | GAS_OPTION | COMMISSIONING_FLOW_TYPE>;
}

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  public availableRegistrationModules: ModuleDetails[] = [];
  public availableCommissioningModules: ModuleDetails[] = [];
  public flowType = FLOW;
  private allRegistrationModules: ModuleDetails[] = [
    {
      moduleName: FLOW_MODULE.ELECTRICITY,
      icon: 'electric_bolt',
      flows: [
        FORM_TYPE.GRID_CONNECTION,
        FORM_TYPE.PV,
        FORM_TYPE.BALCONY_PV,
        FORM_TYPE.CHARGING_DEVICE,
        FORM_TYPE.HEAT_PUMP,
        FORM_TYPE.STORAGE,
      ],
    },
    {
      moduleName: FLOW_MODULE.GAS,
      customIcon: 'gas',
      flows: [GAS_OPTION.NEW, GAS_OPTION.CHANGE, GAS_OPTION.DETACH],
    },
    {
      moduleName: FLOW_MODULE.WATER,
      icon: 'water',
      flows: [FORM_TYPE.WATER, FORM_TYPE.SEWAGE, FORM_TYPE.CONSTRUCTION_WATER],
    },
    {
      moduleName: FLOW_MODULE.HEAT,
      customIcon: 'heat',
      flows: [FORM_TYPE.HEAT_NEW, FORM_TYPE.HEAT_CHANGE, FORM_TYPE.HEAT_DETACH],
    },
  ];
  private allCommissioningModules: ModuleDetails[] = [
    {
      moduleName: FLOW_MODULE.ELECTRICITY,
      icon: 'electric_bolt',
      flows: [
        COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY,
        COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY,
      ],
    },
  ];

  constructor(
    private routeService: RouteService,
    private whiteLabelService: WhiteLabelService
  ) {}

  public ngOnInit(): void {
    this.whiteLabelService.whiteLabelConfig$
      .pipe(takeUntil(this.onDestroy$), filter(Boolean))
      .subscribe(({ moduleSettings, commissioningModules }) => {
        const enabledRegistrationModules: FLOW_MODULE[] =
          moduleSettings?.map(module => module.module) || [];

        this.availableRegistrationModules = this.allRegistrationModules.filter(
          module => enabledRegistrationModules.includes(module.moduleName)
        );

        this.availableCommissioningModules =
          this.allCommissioningModules.filter(commissioningModule =>
            commissioningModules?.includes(commissioningModule.moduleName)
          );
      });
  }

  public next(): void {
    this.routeService.navigateToNextStep();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
