<app-form-card
  titleIcon="category"
  cardTitle="MODULE_SELECTION.HEADING"
  description="MODULE_SELECTION.DESCRIPTION"
  dataTestId="module-selection-heading">
  <form [formGroup]="moduleSelectionForm">
    <app-selection-group>
      <ng-container *ngFor="let moduleDetails of moduleDetailsList">
        <app-selection-card
          *ngIf="moduleDetails.enabled"
          [svgIcon]="moduleDetails.svgIcon"
          [icon]="moduleDetails.icon"
          [label]="moduleDetails.label"
          [symbolOutlinedIcon]="moduleDetails.symbolOutlinedIcon"
          [selected]="selectedModule === moduleDetails.name"
          (click)="selectModule(moduleDetails.name)"
          class="selection-card"
          [attr.data-testid]="moduleDetails.name">
        </app-selection-card>
      </ng-container>
      <mat-error
        *ngIf="allTouched && moduleSelectionForm.get('selectedModule')?.invalid"
        >{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error
      >
    </app-selection-group>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: moduleSelectionForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
