<app-form-card svgIcon="gas" cardTitle="GAS.GAS_POWER_REQUIREMENT.HEADING">
  <div class="space"></div>
  <form [formGroup]="powerRequirementForm">
    <h2 class="full-width">
      {{ 'GAS.GAS_POWER_REQUIREMENT.POWER_REQUIREMENT_QUESTION' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{
          'GAS.GAS_POWER_REQUIREMENT.POWER_REQUIREMENT_PLACEHOLDER' | translate
        }}</mat-label>
        <input
          matInput
          type="number"
          name="powerDemand"
          formControlName="powerDemand"
          min="0" />
        <mat-error appErrorMessages="powerDemand"></mat-error>
      </mat-form-field>
    </div>

    <div class="space"></div>
    <h2>
      {{ 'GAS.GAS_POWER_REQUIREMENT.ANYTHING_ELSE_QUESTION' | translate }}
    </h2>
    <div class="input-container">
      <mat-form-field appearance="outline" class="textarea-field">
        <mat-label>{{
          'GAS.GAS_POWER_REQUIREMENT.ANYTHING_ELSE_PLACEHOLDER' | translate
        }}</mat-label>
        <textarea
          #notes
          matInput
          cols="50"
          rows="2"
          [maxlength]="maxLength"
          formControlName="notes"></textarea>
        <mat-hint align="end"
          >{{ notes.value.length }} / {{ maxLength }}</mat-hint
        >
        <mat-error appErrorMessages="notes"></mat-error>
      </mat-form-field>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: powerRequirementForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
