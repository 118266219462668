import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CONTROLLABLE_POWER } from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
@Component({
  selector: 'app-other-product',
  templateUrl: './other-product.component.html',
})
export class OtherProductComponent implements OnInit, OnDestroy {
  public maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;
  public otherProductForm!: FormGroup;
  public allTouched = false;
  public controllablePower = CONTROLLABLE_POWER;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.initFormData();
    this.watchForm();
  }

  private createForm(): void {
    this.otherProductForm = this.formBuilder.group(
      {
        typeOfRequest: [
          null,
          [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        ],
        systemPower: [
          null,
          {
            updateOn: 'change',
            validators: [Validators.min(0)],
          },
        ],
        meterNumber: [
          null,
          [CustomValidators.trimValidator, CustomValidators.shortText],
        ],
        notes: [
          null,
          [CustomValidators.trimValidator, CustomValidators.longText],
        ],
      },
      { updateOn: 'blur' }
    );
  }

  private initFormData(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.other),
        filter(Boolean),
        take(1)
      )
      .subscribe(other => this.otherProductForm.patchValue(other));
  }

  private watchForm(): void {
    this.otherProductForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(other => this.appStateService.updateFormData({ other }));
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.otherProductForm.valid) {
      this.routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.otherProductForm.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
