/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/angular';

const errorsWithoutDialog: readonly RegExp[] = [
  new RegExp('turnstile'),
  new RegExp('assets'),
];

export function showErrorFeedbackDialog(
  event: Sentry.ErrorEvent,
  hint: Sentry.EventHint
): boolean {
  const errorMessage: string = (
    hint.originalException as any
  )?.message?.toLowerCase();

  const hideDialog = errorsWithoutDialog.some(
    error => !!error.exec(errorMessage)
  );
  return !!(event.exception && event.event_id && !hideDialog);
}
