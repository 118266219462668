import { NgModule } from '@angular/core';
import { HeatOptionsComponent } from './heat-options/heat-options.component';
import { HeatPropertyTypeComponent } from './heat-property-type/heat-property-type.component';
import { HeatRequirementsComponent } from './heat-requirements/heat-requirements.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { HeatSummaryComponent } from './heat-summary/heat-summary.component';
import { IsModuleOptionEnabledPipe } from '@app/shared/pipes/is-module-option-enabled.pipe';

@NgModule({
  declarations: [
    HeatOptionsComponent,
    HeatPropertyTypeComponent,
    HeatRequirementsComponent,
    HeatSummaryComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    IsModuleOptionEnabledPipe,
  ],
  exports: [HeatSummaryComponent],
})
export class HeatModule {}
