import { NgModule } from '@angular/core';
import { GasOptionsComponent } from './gas-options/gas-options.component';
import { SharedModule } from '../shared/shared.module';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { GasPropertyTypeComponent } from './gas-property-type/gas-property-type.component';
import { GasPowerRequirementComponent } from './gas-power-requirement/gas-power-requirement.component';

@NgModule({
  declarations: [
    GasOptionsComponent,
    GasPropertyTypeComponent,
    GasPowerRequirementComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
})
export class GasModule {}
