import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { CHANGE_TYPE } from '@app/models/registration-form';

interface ChangeTypeDetails {
  icon: string;
  label: string;
  type: CHANGE_TYPE;
}

@Component({
  selector: 'app-grid-connection-change',
  templateUrl: './grid-connection-change.component.html',
})
export class GridConnectionChangeComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public changeTypeSelection: ChangeTypeDetails[] = [
    {
      icon: 'foundation',
      label: 'GRID_CONNECTION_CHANGE.CHANGE_TYPE.CHANGE_LINE_ROUTING',
      type: CHANGE_TYPE.CHANGE_LINE_ROUTING,
    },
    {
      icon: 'add_home_work',
      label: 'GRID_CONNECTION_CHANGE.CHANGE_TYPE.RELOCATION',
      type: CHANGE_TYPE.RELOCATION,
    },
    {
      icon: 'add_home',
      label: 'GRID_CONNECTION_CHANGE.CHANGE_TYPE.NEW_LINE',
      type: CHANGE_TYPE.NEW_LINE,
    },
  ];

  public selectedType?: CHANGE_TYPE;
  public allTouched = false;

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private appStateService: AppStateService,
    private routeService: RouteService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      changeType: [null, [Validators.required]],
      totalPowerChanged: [null, [Validators.required]],
      ditchLength: [null, [Validators.required, Validators.min(0)]],
    });
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.changeGridConnectionDetails),
        filter(Boolean),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(changeGridConnectionDetails => {
        this.form.patchValue(changeGridConnectionDetails);
        this.selectedType = changeGridConnectionDetails.changeType;
      });
  }

  private watchForm(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(changeGridConnectionDetails =>
        this.appStateService.updateFormData({ changeGridConnectionDetails })
      );
  }

  public selectType(selectedType: CHANGE_TYPE): void {
    this.form.get('changeType')?.setValue(selectedType);
    this.selectedType = selectedType;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    this.allTouched = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.routeService.navigateToNextStep();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
