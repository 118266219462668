<app-summary-section
  heading="COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.HEADING"
  [stepRoute]="measurementDetailsRoute">
  <ng-container *ngFor="let detail of measurementDetails; let i = index">
    <p class="section-heading">
      {{ i + 1 }}.
      {{ 'COMMISSIONING_ELECTRICITY.MEASUREMENT_DETAILS.NAME' | translate }}
    </p>
    <app-complex-measurement-detail-summary
      class="section-fields-container"
      [measurementDetail]="detail"></app-complex-measurement-detail-summary>
  </ng-container>
</app-summary-section>
