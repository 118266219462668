<app-form-card
  titleIcon="waving_hand"
  cardTitle="INTRO.HEADING"
  dataTestId="intro-heading">
  <div class="intro-content" slot="content">
    <p>
      {{ 'INTRO.WELCOME' | translate }}
    </p>

    <div class="intro-space"></div>
    <ng-container *ngIf="availableRegistrationModules.length">
      <p class="bold">
        {{ 'INTRO.REGISTRATION_TEXT' | translate }}
      </p>

      <div class="intro-space"></div>

      <app-intro-module-card [moduleDetailsList]="availableRegistrationModules">
      </app-intro-module-card>
    </ng-container>

    <ng-container *ngIf="availableCommissioningModules.length">
      <div class="space"></div>

      <p class="bold">
        {{ 'INTRO.COMMISSIONING_TEXT' | translate }}
      </p>

      <div class="intro-space"></div>

      <app-intro-module-card
        [flowType]="flowType.COMMISSIONING"
        [moduleDetailsList]="availableCommissioningModules">
      </app-intro-module-card>
    </ng-container>
  </div>

  <app-form-navigation
    slot="navigation"
    [previousConfig]="{ visible: false }"
    [nextConfig]="{ label: 'INTRO.NEXT_BUTTON_TEXT', arrow: false }"
    (next)="next()">
  </app-form-navigation>
</app-form-card>

<span class="material-symbols-outlined hidden">home</span>
