<app-form-card
  titleIcon="home"
  cardTitle="COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.HEADING">
  <h2 class="full-width">
    {{ 'COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    <app-selection-card
      *ngFor="let property of propertyDetailsList"
      [icon]="property.icon"
      [label]="property.label"
      [selected]="selectedPropertyType === property.type"
      (click)="selectPropertyType(property.type)"
      [attr.data-testid]="property.type">
    </app-selection-card>

    <mat-error *ngIf="allTouched && !selectedPropertyType">{{
      'VALIDATION.REQUIRED_SELECTION' | translate
    }}</mat-error>
  </app-selection-group>

  <div class="space"></div>
  <form [formGroup]="propertyDetailsForm">
    <section
      *ngIf="
        selectedPropertyType === propertyType.INDUSTRY ||
        selectedPropertyType === propertyType.MIXED
      ">
      <div class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>
            {{ 'COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.AREA' | translate }}
          </mat-label>
          <input
            matInput
            type="number"
            name="area"
            formControlName="area"
            min="0"
            data-testid="area" />
          <mat-error appErrorMessages="area"></mat-error>
        </mat-form-field>
      </div>
    </section>

    <section *ngIf="selectedPropertyType === propertyType.MULTI_FAMILY_HOUSE">
      <div class="input-container">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>
            {{
              'COMMISSIONING_ELECTRICITY.PROPERTY_TYPE.FLAT_COUNT' | translate
            }}
          </mat-label>
          <input
            matInput
            type="number"
            name="flatCount"
            formControlName="flatCount"
            min="0"
            data-testid="flat-count" />
          <mat-error appErrorMessages="flatCount"></mat-error>
        </mat-form-field>
      </div>
    </section>
  </form>

  <div class="space"></div>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: propertyDetailsForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
